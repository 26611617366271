import { all, fork } from 'redux-saga/effects';
import watchSessionPageSagas from './sessionPage';
import watchSessionResultsSagas from './sessionResults';
import watchAccrualCalculatorSagas from './accrualCalculator';

export default function* watchGroupSequentialDesignSagas() {
    yield all([
        fork(watchSessionPageSagas),
        fork(watchSessionResultsSagas),
        fork(watchAccrualCalculatorSagas),
    ]);
}