import React from 'react';
import { FormikCustomAutocomplete } from '../../../../common/components/formik-controls/FormikComponents';
import { CONSTANTS } from '../../../../common/constants';
import { useSelector } from 'react-redux';
import { getProtocolsSelector } from '../../selectors/CreateProjectSelectors';
import './style.scss';

export default function Protocol() {
  const protocols = useSelector(getProtocolsSelector);

  return (
    <FormikCustomAutocomplete
      name='protocols'
      className='spacer'
      label={CONSTANTS.LABELS.PROTOCOLS}
      options={protocols}
      labelKey='name'
      helperText=''
    />
  );
}
