// Utils
export const isSystemError = (type: string = ""): boolean => type && type === "System Error" ? true : false;
export const isUserActive = (status: string) => status === "Active";
export const getUserById = (list: any, id: number) => list.filter((item: any) => item.userId === id);
export const getSelectedUser = (list: any, userId: number) => {
    const user = getUserById(list, userId);
    return user && user.length ? user[0] : null;
};
// Constants
export const ACTION_USER_ACTIVATION = "Tenant Activation";
export const ACTION_EDIT_TENANT = "Edit Tenant";