import {createSlice, PayloadAction} from '@reduxjs/toolkit';

type BannerType = {
  openBanner: boolean;
  message: string;
  bannerType: string;
  autoClose?:boolean;
};

export const initialState: BannerType = {
  openBanner: false,
  message: '',
  bannerType: 'success',
  autoClose:false
};

export const BannerSlice = createSlice({
  name: 'banner',
  initialState: initialState,
  reducers: {
    openBanner: (state: BannerType, action: PayloadAction<{message: string, bannerType?: string,autoClose?:boolean}>) => {
      const { message, bannerType,autoClose } = action.payload;
      state.openBanner = true;
      state.message = message;
      state.bannerType = bannerType ? bannerType : 'success';
      state.autoClose = autoClose ? true : false
    },
    closeBanner: (state: any) => {
      state.openBanner = false;
      state.autoClose = false;
    },
  },
});

export const {openBanner, closeBanner} = BannerSlice.actions;
export default BannerSlice.reducer;
