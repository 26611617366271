import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {FlowChartDecisionTableState} from '../types/FlowChartTypes';

export const initialState : FlowChartDecisionTableState = {
    loading : false,
    data:{},
    error:{},
    flowChartDesignData:{}
}

export const FlowChartDecisionTableSlice = createSlice({
    name:'FlowChartDecisionTableSlice',
    initialState : initialState as FlowChartDecisionTableState,
    reducers : {
        fetchFlowChart:(state,action:PayloadAction<any>) => {
            state.loading = true;
            state.flowChartDesignData= action.payload;
        },
        fetchFlowChartSuccess:(state,action:PayloadAction<any>) => {
            state.loading = false;
            state.data = action.payload;
        },
        handleError:(state,action:PayloadAction<any>) => {
            state.error = action.payload;
            state.loading = false;
        },
        resetFlowChartData:(state)=> {
            state.data ={};
            state.loading = false;
            state.flowChartDesignData ={};
        },
        resetFlowchartError:(state) => {
            state.error = {};
            state.loading = false;
        },
    }
})

export const {
    fetchFlowChart,
    fetchFlowChartSuccess,
    handleError,
    resetFlowChartData,
    resetFlowchartError
} = FlowChartDecisionTableSlice.actions;
export default FlowChartDecisionTableSlice.reducer;