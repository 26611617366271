import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../../app/store';

export const getuserDetails = (state: RootState) => {
  return state.user?.details;
};

export const getActivetenant = createSelector(getuserDetails, (details) => {
  return details?.activeTenant || {};
});

export const getActiveuser = createSelector(getuserDetails, (data) => {
  return {
    userid: data.userID,
    tenantid: data?.activeTenant?.id,
    userName: data.userName,
  };
});

export const getDesignerRole = createSelector(getuserDetails, (data) => {
  return data?.activeTenant?.role?.type === 'Designer';
});

export const isTenantAdmin = createSelector(getuserDetails, (data) => {
  return data?.activeTenant?.role?.type === 'Tenant Admin';
});

export const getActiveUserRole = createSelector(getuserDetails, (data) => {
  if(data.isSystemAdmin === "1") {
    return "System Admin"
  }
  return data?.activeTenant?.role?.type;
});
