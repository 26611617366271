// Utils
export const isUserActive = (status: string) => status === "Active";
export const isSystemError = (type: string = ""): boolean => type && type === "System Error" ? true : false;
export const getUserByID = (list: any, id: number) => list.filter((item: any) => item.userId === id);
export const getSelectedUser = (list: any, userId: number) => {
    const user = getUserByID(list, userId);
    return user && user.length ? user[0] : null;
};
// Constants
export const ACTION_USER_ACTIVATION = "User Activation";
export const ACTION_USER_ROLE_CHANGE_ROLE = "Change Role";