import { getGtmID } from './endpoint';
import TagManager from 'react-gtm-module'
import history from '../common/utils/history';

interface Window {
  dataLayer?: any;
  location?: any;
}
declare var window: Window;

interface GAEventDataType {
  category: string;
  event: string;
  action?: string,
  label?: any,
}

const gtmID = getGtmID();

export const initializeGoogleTagManager = () => {
  if(gtmID) {
    const tagManagerArgs = {
      gtmId: gtmID,
      dataLayer: {
        event: 'pageview',
        pagePath: window.location.pathname,
      }
    };
  
    TagManager.initialize(tagManagerArgs)
    history.listen((location, action) => {
      window.dataLayer.push({
        event: 'pageview'
      });
    });
  }
};

export const addGAEvent = (eventData: GAEventDataType) => {
  if(gtmID) {
    // @ts-ignore
    window.dataLayer.push(eventData);
  }
};

// Old Google ANalytics implementation:
// import ReactGA from 'react-ga';
// @ts-ignore
// ReactGA.initialize(getGoogleAnaylictsID());
// history.listen((location, action) => {
//   ReactGA.pageview(location.pathname + location.search);
// });
