import { createSelector } from "@reduxjs/toolkit";

export const getProjectDetailSelector = (state: any) => {
    return state.projectDetail;
}

export const getProjectDataSelector = createSelector(getProjectDetailSelector, (items) => {
    return items.data;
})

export const getProjectDataLoadingSelector = createSelector(getProjectDetailSelector, (items) => {
    return items.loading;
})

export const getUsersSelector = createSelector(getProjectDetailSelector, (items) => {
    return items.allUsers;
})

export const getProjectMemberSelector = createSelector(getProjectDetailSelector, (items) => {
    return items.projectMembers;
})

export const getFetchSelector = createSelector(getProjectDetailSelector, (items) => {
    return items.error;
})

export const getProjectResults = createSelector(getProjectDetailSelector, (items) => {
    return items.projectResults;
})

export const projectResultsExists = createSelector(getProjectDetailSelector, (items) => {
    return (items.projectResults?.testSessions?.length > 0 || items.projectResults?.draftSessions?.length > 0 || items.projectResults?.completedSessions?.length > 0 || items.projectResults?.topRatedResults?.length > 0);
})

export const getSideNavigationBladeState = createSelector(getProjectDetailSelector, (items) => {
    return items.isLeftNavigationBladeOpened;
})

// export const getCurrentObjective = createSelector(getProjectDetailSelector, (items) => {
//     const objid: number = items.data.objID
//     return objectiveSlugs['objid']
    
// });


// const objectiveSlugs = {
//     1: 'dose',
//     2: 'sequence',
//     3: 'custom',
//     4: 'master'
// }

