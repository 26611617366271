import React from 'react';
import MenuItem from 'apollo-react/components/MenuItem';
import { FormikSelect } from '../../../../common/components/formik-controls/FormikComponents';
import { CONSTANTS } from '../../../../common/constants';
import { useSelector } from 'react-redux';
import { getPhasesSelector } from '../../selectors/CreateProjectSelectors';

export default function Phases() {
  const phases = useSelector(getPhasesSelector);

  return (
    <FormikSelect
      name='phases'
      label='Phase'
      placeholder={CONSTANTS.LABELS.SELECT}
      fullWidth
      multiple
      helperText=''
    >
      {phases.map((phase: any) => {
        return (
          <MenuItem key={phase.id} value={phase.id}>
            {phase.name}
          </MenuItem>
        );
      })}
    </FormikSelect>
  );
}
