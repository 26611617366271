import {put, takeEvery, call, delay} from 'redux-saga/effects';
import {sessionRequest, originalSession} from '../slices/saveSessionSlice';
import {ENDPOINTS} from '../../../services/endpoint';
import {callApi} from '../../../services/api';
import {savedTrialDurationparams, savedTrialparams, updateIncludeTrialDuration} from '../slices/TrialParamSlice';
import {initialzeDesignOptions} from '../slices/DesignOptionSlice';
import {PayloadAction} from '@reduxjs/toolkit';
import {initialzeCurves, updateCurveDoseLevel} from '../slices/ToxicityCurveSlice';

export default function* watchGetSessionData() {
  yield takeEvery(sessionRequest, getSessionData);
}

export function* getSessionData(
  action: PayloadAction<{
    projectid: string;
    userid: string;
    tenantid: string;
    sid: string;
    actionType?: string;
  }>,
) {
  try {
    yield delay(1000); //Wait for ProjectLevelCurves data to be recieved
    const {userid, projectid, tenantid, sid, actionType = ''} = action.payload;
    let url = `${ENDPOINTS.PROJECT_SESSION_DATA}?userid=${userid}&tenantid=${tenantid}&projectid=${projectid}&sessionid=${sid}`;
    if(actionType) { 
      // Add action query param in case of clone action as Clone & normal fetch use the same API.
      url += `&action=${actionType}`;
    }
    const result = yield call(callApi, url);
    const {data} = result;

    if (data) {
      let projLevelDoseAmounts: any = [];
      data.curveData.map((el: any) => projLevelDoseAmounts.push(el?.meta?.crv_dose_amounts?.length || 5));
      yield put(savedTrialparams({data: data.trialData[0].trialParams}));
      yield put(initialzeDesignOptions(data.designData));
      const trialDurationParams = data.trialData[0].trialDurationParams;
      const includeTrialDuration = data.trialData[0].includeTrialDuration;
      yield put(updateIncludeTrialDuration(includeTrialDuration))
      if(trialDurationParams){
        yield put(savedTrialDurationparams({data: trialDurationParams }));
      }
      yield put(
        initialzeCurves({
          data: data.curveData,
          trialParams: {
            maxDoseLevels: data.trialData[0].trialParams.find((tp: any) => tp?.name === 'maxDoseLevels')
              .value,
            doseAmounts: data.trialData[0].trialParams.find((tp: any) => tp?.name === 'doseAmounts').value,
            endPoint:data.trialData[0].trialParams.find((tp: any) => tp?.name === 'endpoint')?.value
          },
        }),
      );
      yield put(
        updateCurveDoseLevel(projLevelDoseAmounts.length > 0 ? Math.max(...projLevelDoseAmounts) : 5),
      );
      yield put(originalSession(data));
    }
  } catch (error) {
    console.warn(error);
  }
}
