import {createSlice, PayloadAction} from '@reduxjs/toolkit';

interface IDependencyLib {
  data: Array<{libraryId: number; libraryName: string}>;
  error: string;
  isLoading: boolean;
}

export const initialState: IDependencyLib = {
  isLoading: false,
  error: '',
  data: [] as any,
};

export const dependencyLibrarySlice = createSlice({
  name: 'dependencyLibrary',
  initialState: initialState as any,
  reducers: {
    fetchRequest: (state: any) => {
      state.isLoading = true;
    },
    fetchSuccess: (
      state: any,
      action: PayloadAction<{data: Array<{libraryId: number; libraryName: string}>}>,
    ) => {
      const {data} = action.payload;
      
      state.data =
        data.sort((a, b) => {
          if (a.libraryName.toLowerCase() < b.libraryName.toLowerCase()) {
            return -1;
          }
          if (a.libraryName.toLowerCase() > b.libraryName.toLowerCase()) {
            return 1;
          }
          return 0;
        }) || [];
      state.isLoading = false;
    },
    fetchFailure: (state: any, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const {fetchRequest, fetchFailure, fetchSuccess} = dependencyLibrarySlice.actions;
export default dependencyLibrarySlice.reducer;
