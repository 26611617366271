import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IntegrationManagementState } from "../types/IntegrationManagementType";
import { UPDATE_APP_INTEG_STATUS, REGENERATE_TOKEN_EVENT } from '../utils/appIntegManagmentTableCommon';

export const initialState: IntegrationManagementState = {
  data: [],
  isLoading: true,
  error: "",
  totalRecords: 0,
  currentPage: 1,
  workspacesList: [],
  addApplicationSuccess: '',
  addApplicationError: '',
  fetchAddApplication:false,
  showBanner: {
    isActive: false,
    message: "",
  },
  showErrorModal: {
    isActive: false,
    message: "",
  },
  isDataNeedToReload: false,
  appIntegForm: {
    type: 'Add',
    editDetail: {}
  },
  showForm: false,
};

export const integrationManagementSlice = createSlice({
  name: "integrationManagement",
  initialState: initialState as IntegrationManagementState,
  reducers: {
    fetchRequest: (state: IntegrationManagementState, action) => {
      state.isLoading = true;
    },
    fetchSuccess: (
      state: IntegrationManagementState,
      action: PayloadAction<any>
    ) => {
      const {
        payload: { data, totalRecords, pageIndex, workspacesList },
      } = action;
      state.data = data;
      state.totalRecords = totalRecords.count;
      state.currentPage = pageIndex;
      state.workspacesList = workspacesList;
      state.isLoading = false;
    },
    fetchFailure: (state: IntegrationManagementState, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    getWorkspaceFieldData: (state, action) => {
      state.fetchAddApplication = true;
    },
    updateWorkspaceFieldData: (state, action) => {
      state.fetchAddApplication = false;
      state.workspacesList = action.payload;
    },
    addApplication: (state, action) => { 
      state.fetchAddApplication = true;
    },
    addAppSuccess: (state, action) => {
        state.fetchAddApplication = false;
        state.addApplicationSuccess = action.payload;
        state.showForm = false;
        state.appIntegForm = {
          type: 'Add',
          editDetail: {}
        }
    },
    addAppError: (state, action) =>{
      state.fetchAddApplication = false;
      state.addApplicationError = action.payload;
      state.showForm = false;
      state.appIntegForm = {
        type: 'Add',
        editDetail: {}
      }
    },
    updateApplication: (state, action) => { 
      state.fetchAddApplication = true;
    },
    updateAppIntegSuccess: (state, action) => {
        state.fetchAddApplication = false;
        state.addApplicationSuccess = action.payload;
        state.showForm = false;
        state.appIntegForm = {
          type: 'Edit',
          editDetail: {}
        }
    },
    updateAppIntegError: (state, action) => {
      state.fetchAddApplication = false;
      state.addApplicationError = action.payload;
    },
    resetAddApp: (state) => {
        state.addApplicationSuccess = '';
        state.addApplicationError = '';
        state.isLoading = false;
        state.fetchAddApplication = false;
    },
    updateAppIntegStatus: (state, action) => {
      state.isLoading = true;
    },
    updateAppIntegStatusSuccess: (state, action) => {
      state.isLoading = false;
      state.showBanner.message = `Success${UPDATE_APP_INTEG_STATUS}`;
      state.showBanner.isActive = true;
      state.isDataNeedToReload = true;
    },
    updateAppIntegStatusError: (state, action) => {
      state.isLoading = false;
      state.addApplicationError = action.payload;
    },
    closeBanner: (state) => {
      state.showBanner.isActive = false;
      state.showBanner.message = "";
    },
    setDataReload: (state, action) => {
      state.isDataNeedToReload = action.payload;
    },
    closeErrorModal: (state) => {
      state.showErrorModal.isActive = false;
      state.showErrorModal.message = "";
    },
    regenerateAppToken: (state, action) => {
      state.isLoading = true;
      state.fetchAddApplication = true;
    },
    regenerateAppTokenSuccess: (state, action) => {
      state.isLoading = false;
      state.fetchAddApplication = false;
      state.showBanner.message = `Success${REGENERATE_TOKEN_EVENT}`;
      state.showBanner.isActive = true;
    },
    regenerateAppTokenError: (state, action) => {
      state.isLoading = false;
      state.fetchAddApplication = false;
      state.addApplicationError = action.payload;
    },
    openAppIntegForm: (state, action) => {
      const { type, editDetail } = action.payload;
      state.showForm = true;
      state.appIntegForm.type = type;
      state.appIntegForm.editDetail = editDetail;
    },
    closeAppIntegForm: (state) => {
      state.showForm = false;
      state.appIntegForm.editDetail = {};
    }
  }
});

export const {
  fetchRequest,
  fetchFailure,
  fetchSuccess,
  getWorkspaceFieldData,
  updateWorkspaceFieldData,
  addApplication,
  addAppSuccess,
  addAppError,
  resetAddApp,
  updateApplication,
  updateAppIntegSuccess,
  updateAppIntegError,
  updateAppIntegStatus,
  updateAppIntegStatusSuccess,
  updateAppIntegStatusError,
  closeBanner,
  setDataReload,
  closeErrorModal,
  regenerateAppToken,
  regenerateAppTokenSuccess,
  regenerateAppTokenError,
  openAppIntegForm,
  closeAppIntegForm
} = integrationManagementSlice.actions;
export default integrationManagementSlice.reducer;