import React, { useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Banner from 'apollo-react/components/Banner';
import { closeBanner } from '../../../features/projects/slices/BannerSlice';
import { getBannerData } from '../../../features/projects/selectors/bannerSelectors';

const UserMessageBanner = (props: any) => {
  const dispatch = useDispatch();
  const { openBanner, message, bannerType, autoClose } = useSelector(getBannerData);
  const { closeBannerDependency } = props;

  /** Use Effect used to close the banner after 2 seconds */
  useEffect(() => {
    if(autoClose) {
      setTimeout(()=>{
        dispatch(closeBanner());
      },15000)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openBanner,autoClose])
  
  return (
    <Banner
      data-testid='UserMessageBanner'
      variant={bannerType}
      open={openBanner}
      onClose={() => {
        dispatch(closeBanner());
        closeBannerDependency && closeBannerDependency();
      }}
      message={message}
      style={{ top: '57px', zIndex: '1298' }}
    />
  );
};

export default UserMessageBanner;
