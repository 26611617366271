import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import { pick } from 'lodash';
type INTIAL_STATE = {
  isLoading: boolean;
  error: string;
  formFields: any;
  formInitialValues: any;
  sessionId: number | string | null;
  sessionName: string;
  showModal?: boolean;
  isSavedSession: boolean;
  schema: Array<any>;
  IsNewSession: boolean;
  isComputed: boolean;
  projSessionId?: number | string | null;
  defaultValues?: any;
  formTrialParamInitialValue: any;
  enableCompute: boolean;
  ssrUpdateFields: Array<{ "orgInterimSamplSize": number,"orgInterimSmpldev":number,"orgblindPreservingInternalPilotPropotion": number,"orgblindPreservingInternalPilotSuccess":number,"orgblindPreservingInternalPilotSampleSize":number}>;
};

export const initialState: any = {
  isLoading: false,
  error: '',
  formFields: {},
  formInitialValues: {},
  formTrialParamInitialValue: {},
  schema: [],
  sessionId: null,
  sessionName: '',
  showModal: false,
  isSavedSession: false,
  IsNewSession: false,
  isComputed: false,
  projSessionId: '',
  enableCompute: false,
  defaultValues: {},
  ssrUpdateFields:[]
};

export const sessionPageSlice = createSlice({
  name: 'sessionPage',
  initialState: initialState as INTIAL_STATE,
  reducers: {
    fetchRequest: (state: INTIAL_STATE, action) => {
      state.isLoading = true;
    },
    fetchSuccess: (state: INTIAL_STATE, action: PayloadAction<any>) => {
      const {intialValues, fieldInfo, schema,defaultValues,enableLock=false,coefficientLock=false} = action.payload;
      state.formFields = fieldInfo;
      state.formInitialValues = intialValues;
      state.formInitialValues['sessionName'] = '';
      state.formInitialValues['trialParameters'] = {...state.formInitialValues['trialParameters'], enableLock:enableLock,coefficientLock:coefficientLock};
      state.formInitialValues['ssr'] = {...state.formInitialValues['ssr'],"orgInterimSamplSize":0,"orgInterimSmpldev":0,"orgblindPreservingInternalPilotPropotion":0,"orgblindPreservingInternalPilotSampleSize":0,"orgblindPreservingInternalPilotSuccess":0}
      state.schema = schema;
      state.defaultValues = defaultValues;
      state.isLoading = false;
    },
    fetchFailure: (state: INTIAL_STATE, action) => {
      state.isLoading = false;
      state.error = action?.payload;
    },
    fetchSavedSessionRequest: (state: INTIAL_STATE, action) => {
      state.isLoading = true;
    },
    fetchSavedSessionSuccess: (state: INTIAL_STATE, action: PayloadAction<any>) => {
      const {intialValues, fieldInfo,ssr={}, schema, sessionName,sessionId,projSessionId,defaultValues, enableLock = false, coefficientLock = false} = action.payload;
      state.sessionName = sessionName;
      state.formFields = fieldInfo;
      state.formInitialValues = intialValues;
      state.defaultValues = defaultValues;
      state.formInitialValues['trialParameters'] = {...state.formInitialValues['trialParameters'], 
      enableLock: enableLock,coefficientLock:coefficientLock}
      state.ssrUpdateFields = [pick(ssr,["orgInterimSamplSize","orgInterimSmpldev","orgblindPreservingInternalPilotPropotion","orgblindPreservingInternalPilotSampleSize","orgblindPreservingInternalPilotSuccess","blindPreservingTtlSubOrgDsg","blindPreservingOrgStdDev","combinedExpectedProportion"])];
      state.formInitialValues['ssr'] = {...state.formInitialValues.ssr,...pick(ssr,["orgInterimSamplSize","orgInterimSmpldev","orgblindPreservingInternalPilotPropotion","orgblindPreservingInternalPilotSampleSize","orgblindPreservingInternalPilotSuccess","blindPreservingTtlSubOrgDsg","blindPreservingOrgStdDev","combinedExpectedProportion"])}
      state.formInitialValues['sessionName'] = sessionName
      state.schema = schema;
      state.isLoading = false;
      state.isSavedSession = Boolean(sessionId)
      state.projSessionId = projSessionId;
    },
    fetchSavedSessionFailure: (state: INTIAL_STATE, action) => {
      state.isLoading = false;
      state.error = action?.payload;
    },
    updateCompute: (state: INTIAL_STATE, action) => {
      state.isLoading = true;
    },
    updateComputeSuccess: (state: INTIAL_STATE, action: PayloadAction<any>) => {
      const {
        data: {sessionId, sessionName, intialValues,projSessionId},
        status,
        IsNewSession
      } = action.payload;
      state.sessionId = sessionId;
      if(status === 'Draft'){
        state.formInitialValues = intialValues;
        state.sessionName = sessionName;
        state.showModal = true;
        state.IsNewSession = IsNewSession;
        state.projSessionId = projSessionId
      } else {
        state.isComputed = true;
      }
      state.isSavedSession = Boolean(sessionId);
      state.isLoading = false;
      state.error = '';
    },
    fetchSSRUpdateSuccess:(state: INTIAL_STATE, action: PayloadAction<any>) => {
      const {
        data
      } = action.payload;
      state.isLoading = false;
      state.ssrUpdateFields = data
    },
    fetchSSRUpdateRequest: (state: INTIAL_STATE, action) => {
      state.isLoading = true;
    },
    fetchSSRUpdateFailure: (state: INTIAL_STATE, action) => {
      state.isLoading = false;
    },
    updateComputeFailure: (state, action: PayloadAction<any>) => {
      state.isLoading = false;
      state.error = action?.payload;
    },
    closeModal: (state) => {
      state.showModal = false;
    },
    resetSession: (state) => {
      state.error = '';
      state.formFields = {};
      state.formInitialValues = {};
      state.sessionId = null;
      state.sessionName = '';
      state.isSavedSession = false;
      state.sessionId = null;
      state.IsNewSession = false;
      state.isComputed = false;
      state.projSessionId = '';
      state.defaultValues = {};
      state.schema = [];
      state.ssrUpdateFields = []
    },
    autoSaveGSSession: (state: INTIAL_STATE, action) => {
      state.isLoading = true;
    },
  },
});

export const {
  fetchRequest,
  fetchSuccess,
  fetchFailure,
  updateCompute,
  updateComputeSuccess,
  updateComputeFailure,
  resetSession,
  fetchSavedSessionRequest,
  fetchSavedSessionSuccess,
  fetchSavedSessionFailure,
  closeModal,
  autoSaveGSSession,
  fetchSSRUpdateRequest,
  fetchSSRUpdateSuccess,
  fetchSSRUpdateFailure
} = sessionPageSlice.actions;
export default sessionPageSlice.reducer;
