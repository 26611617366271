import { put, takeEvery, call } from 'redux-saga/effects';
import { ENDPOINTS } from '../../../services/endpoint';
import { callApi } from '../../../services/api';
import { fetchFlowChartSuccess,fetchFlowChart,handleError } from '../slices/FlowChartDecisionTableSlice';
import { PayloadAction } from '@reduxjs/toolkit';
import { fetchEfficacyDecisionTable,fetchEfficacyDecisionTableFailure,fetchEfficacyDecisionTableSuccess } from '../slices/EfficacyDecisionTableSlice';


export function* watchFlowChartSaga() {
    yield takeEvery(fetchFlowChart, getFlowChartDecisionTableSaga);
    yield takeEvery(fetchEfficacyDecisionTable,generateEfficacyDecisionTableSaga);
}

export function* getFlowChartDecisionTableSaga(action: PayloadAction<{target:number,ncohort:number,cohortsize:number,earlystop:number,saf:number,tox:number,cutoff : number}>) {
    try {
        const { target,ncohort,cohortsize,cutoff,saf,tox,earlystop } = action.payload;
        const body = {
            target,
            ncohort,
            cohortsize,
            cutoff,
            saf,
            tox,
            earlystop
        }
        const { data } = yield call(callApi,ENDPOINTS.GET_FLOW_CHART_DECISION_TABLE_RESULTS,{
            method:'POST',
            body: JSON.stringify(body)
        })
        if(data) {
            yield put(fetchFlowChartSuccess(data))
        } else {
            yield put(fetchFlowChartSuccess({}))
        }
    } catch (error:any) {
       yield put(handleError(error?.error));
    }
}

export function* generateEfficacyDecisionTableSaga(action: PayloadAction<any>) {
    try {
        const {resultId,payload,action:userAction} =action.payload;
        const url = `${ENDPOINTS.GET_FLOW_CHART_DECISION_TABLE_RESULTS}?resultid=${resultId}&action=${userAction}`
        const body = {
           ...payload
        }
        const { data } = yield call(callApi,url,{
            method:'POST',
            body: JSON.stringify(body)
        })
        if(data) {
            yield put(fetchEfficacyDecisionTableSuccess(data))
        } else {
            yield put(fetchEfficacyDecisionTableSuccess({input:{}}))
        }
    } catch (error:any) {
       yield put(fetchEfficacyDecisionTableFailure(error?.error));
    }
}