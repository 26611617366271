import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import { User, AddedUser } from '../../projects/types/ProjectMemberTypes'

const projectDetailSlice = createSlice({
  name: 'projectDetail',
  initialState: {
    data: {
      name: "",
      owner:"",
      sponsors: [],
      engagementTypes: [],
      compounds: [],
      therapeuticAreas: [],
      protocols: [],
    },
    loading: false,
    isTerminationProgress: false,
    allUsers:[] as User,
    projectMembers:[] as AddedUser,
    error:'',
    projectResults:{} as any,
    manageProjectMemberLoading:false,
    projectResultsLoading:false,
    isLeftNavigationBladeOpened: false,
    reloadProjectResults: false,
    shareMembersList: [],
    isMemberLoading: false,
  },
  reducers: {
    getProjectDetail: (state: { loading: boolean; },  action: PayloadAction<any>) => {
      state.loading = true;
    },
    updateProjectDetail: (state: { data: any; loading: boolean; }, action: { payload: any; }) => {
      state.data = action.payload;
      state.loading = false;
    },
    resetProjectOwnerDetails: (state: { data: { name: string; owner: string; }; }) => {
      state.data.name ="";
      state.data.owner=""
    },
    getAllUsers: (state: { manageProjectMemberLoading: boolean; },  action: PayloadAction<any>) => {
      state.manageProjectMemberLoading = true;
    },
    updateAllUsers: (state: { allUsers: any; manageProjectMemberLoading: boolean; },action: { payload: any; }) => {
      state.allUsers = action.payload;
      state.manageProjectMemberLoading = false;
    },
    addProjectMember:(state: { manageProjectMemberLoading: boolean; },action: PayloadAction<any>) => {
      state.manageProjectMemberLoading = true;
    },
    addProjectMemberSuccess:(state: { manageProjectMemberLoading: boolean; error: any; },action: { payload: any; }) => {
      state.manageProjectMemberLoading = false;
      state.error = action.payload;
    },
    addProjectMemberFailure:(state: { manageProjectMemberLoading: boolean; error: any; },action: { payload: any; }) => {
      state.manageProjectMemberLoading = false;
      state.error = action.payload;
    },
    getProjectMembers:(state: { manageProjectMemberLoading: boolean; },action: PayloadAction<any>) => {
      state.manageProjectMemberLoading = true;
    },
    updateProjectMembers:(state: { manageProjectMemberLoading: boolean; projectMembers: any; },action: { payload: any; }) => {
      state.manageProjectMemberLoading = false;
      state.projectMembers = action.payload;
    },
    deleteProjectMember:(state: { manageProjectMemberLoading: boolean; },action: PayloadAction<any>) => {
      state.manageProjectMemberLoading = true;
    },
    deleteProjectMemberSuccess:(state: { manageProjectMemberLoading: boolean; error: any; },action: { payload: any; }) => {
      state.manageProjectMemberLoading = false;
      state.error = action.payload;
    },
    deleteProjectMemberError:(state: { manageProjectMemberLoading: boolean; error: any; },action: { payload: any; }) => {
      state.manageProjectMemberLoading = false;
      state.error = action.payload;
    },
    getProjectResults:(state: { projectResultsLoading: boolean; },action: PayloadAction<any>) => {
      state.projectResultsLoading = true;
    },
    getProjectResultsSuccess:(state: { projectResultsLoading: boolean; projectResults: { projectSessions: any; }; },action: { payload: any; }) => {
      state.projectResultsLoading = false;
      state.projectResults = action.payload;
      let pSessions=state.projectResults.projectSessions;
     let array: any [] = [];
      if(pSessions){
      pSessions.map((session:any) => {
        let sDetails=session.sessionDetails;
        if(sDetails){
        array=[];
        sDetails.map((detail: any)=> {
        if(detail.runBy===session.createdBy && detail.projSessionId===session.projSessionId){
        array.push(detail);
        }
        return session.sessionDetails=array;
        });
        }
      return pSessions=array;
      })
     }
    },
    getProjectResultsFailure:(state: { projectResultsLoading: boolean; error: any; },action: { payload: any; }) => {
      state.projectResultsLoading = false;
      state.error = action.payload;
    },
    toggleLoading:(state: { loading: any; },action: { payload: any; }) => {
      state.loading = action.payload;
    },
    resetProjectResults:(state: { projectResults: {}; }) => {
      state.projectResults = {};
    },
    terminateSimulationRequest: (state: { loading: boolean; isTerminationProgress: boolean; }, action: PayloadAction<any>) => {
      state.loading = true;
      state.isTerminationProgress = true;
    },
    terminateSimulationSuccess: (state: { loading: boolean; isTerminationProgress: boolean; },  action: PayloadAction<any>) => {
      state.loading = false;
      state.isTerminationProgress = false;
    },
    terminateSimulationFailure: (state: { error: any; loading: boolean; isTerminationProgress: boolean; }, action: { payload: any; }) => {
      state.error = action.payload;
      state.loading = false;
      state.isTerminationProgress = false;
    },
    resetError:(state) => {
      state.error = '';
    },
    deleteProjectSession:(state: { projectResultsLoading: boolean; }, action: PayloadAction<any>) => {
      state.projectResultsLoading = true;
    },
    deleteProjectSessionSuccess:(state: { projectResultsLoading: boolean; }) => {
      state.projectResultsLoading = false;
    },
    deleteProjectSessionFailure:(state: { projectResultsLoading: boolean; }) => {
      state.projectResultsLoading = false;
    },
    publishProjectSession:(state: { projectResultsLoading: boolean; }, action: PayloadAction<any>) => {
      state.projectResultsLoading = true;
    },
    publishProjectSessionSuccess:(state: { projectResultsLoading: boolean; }) => {
      state.projectResultsLoading = false;
    },
    publishProjectSessionFailure:(state: { projectResultsLoading: boolean; }) => {
      state.projectResultsLoading = false;
    },
    toggleLeftNavigationBlade:(state: { isLeftNavigationBladeOpened: boolean; }) => {
      state.isLeftNavigationBladeOpened = !state.isLeftNavigationBladeOpened;
    },
    setReloadProjectResults: (state: { reloadProjectResults: any; }, action: { payload: any; }) => {
      state.reloadProjectResults = action.payload;
    },
    fetchSessionMembers: (state: { isMemberLoading: boolean; },  action: PayloadAction<any>) => {
      state.isMemberLoading = true;
    },
    shareSessionListSuccess: (
      state: { shareMembersList: any; isMemberLoading: boolean; },
      action: { payload: any; }
    ) => {
      const { payload } = action;
      
      state.shareMembersList = payload;
      state.isMemberLoading = false;
    },
    shareSessionListFailure: (state: { isMemberLoading: boolean; }) => {
      state.isMemberLoading = false;
    },
    selectedShareMembers: (state: { shareMembersList: any; }, action: { payload: { sharedMembers: any; }; }) =>{
      const { payload: { sharedMembers } } = action;
      state.shareMembersList = sharedMembers;
    },
    fetchApplySelection: (state: { isMemberLoading: boolean; },  action: PayloadAction<any>) =>{
      state.isMemberLoading = true;
    },
    fetchApplySuccessful: (state: { isMemberLoading: boolean; },  action: PayloadAction<any>)=>{
      state.isMemberLoading = false;
    },
    resetSessionMembers: (state: { shareMembersList: any[]; })=>{
      state.shareMembersList=[]
    },
    fetchOption:(state: { projectResultsLoading: boolean; }, action: PayloadAction<any>) => {
      state.projectResultsLoading = true;
    },
    fetchOptionSuccess:(state: { projectResults: { projectSessions: any[]; }; projectResultsLoading: boolean; },action: PayloadAction<any>) => {
      const {data} = action.payload;
      state.projectResults.projectSessions = [...data]
      state.projectResultsLoading = false
    },
    fetchOptionFailure:(state: { projectResultsLoading: boolean; }, action: PayloadAction<any>) => {
      state.projectResultsLoading = false
    }
  }
})

export const { 
  getProjectDetail, 
  updateProjectDetail, 
  updateAllUsers, 
  getAllUsers, 
  addProjectMember, 
  addProjectMemberSuccess, 
  addProjectMemberFailure,
  getProjectMembers,
  updateProjectMembers,
  deleteProjectMember,
  deleteProjectMemberSuccess,
  deleteProjectMemberError,
  getProjectResults,
  getProjectResultsSuccess,
  getProjectResultsFailure,
  toggleLoading,
  resetProjectResults,
  terminateSimulationRequest,
  terminateSimulationSuccess,
  terminateSimulationFailure,
  resetError,
  deleteProjectSession,
  deleteProjectSessionSuccess,
  deleteProjectSessionFailure,
  publishProjectSession,
  publishProjectSessionFailure,
  publishProjectSessionSuccess,
  toggleLeftNavigationBlade,
  setReloadProjectResults,
  resetProjectOwnerDetails,
  fetchSessionMembers,
  shareSessionListSuccess,
  shareSessionListFailure,
  selectedShareMembers,
  fetchApplySelection,
  fetchApplySuccessful,
  resetSessionMembers,
  fetchOption,
  fetchOptionSuccess,
  fetchOptionFailure
} = projectDetailSlice.actions;

export default projectDetailSlice.reducer;
