import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { omit, pick } from 'lodash';
import { DOSE_ESC_CONSTANTS } from '../../../common/constants/doseEscalation';

interface ISaveSession {
  originalSession: any;
  editedSession: any;
  loading: boolean;
  error: string;
  data:any;
}

export const initialState: any = {
  originalSession: {},
  editedSession: {},
  loading: false,
  error: '',
  data:{}
};

const saveSession = createSlice({
  name: 'saveSession',
  initialState: initialState as any,
  reducers: {
    sessionRequest: (state: ISaveSession, action) => {
      state.loading = true;
    },
    originalSession: (state: ISaveSession, action: PayloadAction<any>) => {
      const data = action.payload;
      const endPoint = data?.trialData[0].trialParams.find((tp: any) => tp?.name === 'endpoint')?.value;
      let sessionData = omit(data, [
        'sessionStatus',
        'isTestRun',
      ]);
      sessionData = {
        trialParams: sessionData.trialData[0].trialParams,
        trialDurationParams: sessionData.trialData[0].trialDurationParams,
        designParams: sessionData.designData,
        curveParams:endPoint !== DOSE_ESC_CONSTANTS.ENDPOINTS.TOXEFFICACY ? sessionData.curveData : sessionData.curveData?.flat(),
        sessionName:sessionData.sessionName
      };
      state.originalSession = sessionData;
      state.data = pick(data, ['projSessionId','sessionName', 'sessionStatus', 'isTestRun']);
      state.loading = false;
    },
    editedSession: (state: ISaveSession, action: PayloadAction<any>) => {
      state.editedSession = getParseData(action.payload);
    },
    updatelatestSession: (state: ISaveSession, action: PayloadAction<any>) => {
      state.editedSession = action.payload;
      state.originalSession = action.payload;
      state.loading = false;
    },
    resetDraftSession: (state) => {
      state.data = {};
      state.originalSession = {};
      state.editedSession = {};
      state.error = '';
    },
  },
});

export const {
  sessionRequest,
  originalSession,
  editedSession,
  resetDraftSession,
  updatelatestSession,
} = saveSession.actions;

export default saveSession.reducer;


export const getParseData = (data: any) => {
  const { endpoint } = data;
  let newData: any = {};
  let curveParams = endpoint !== DOSE_ESC_CONSTANTS.ENDPOINTS.TOXEFFICACY ?
  data.curveParams : data.curveParams?.flat()
  newData['sessionName'] = data?.sessionName;

  newData['curveParams'] = curveParams.map((el: any) => {
    const delflg = el?.is_del_flg ? { is_del_flg: el?.is_del_flg } : {};

    return {
      curveEquationParams: el.eq_params,
      curveId: el.txcty_type_id,
      curveName: el.txcty_crv_nm,
      curveParams: el.crv_params,
      isProjectLevelCurve: el.is_carry_fwd_flg ? true : false,
      simulationToxicityCurveId: el.smltn_txcty_id,
      curveType: el.curveType,
      meta:el?.meta,
      ...delflg
    }
  });

  newData['designParams'] = data.designParams.map((el: any) => {
    const delflg = el?.is_del_flg ? { is_del_flg: el?.is_del_flg } : {};
    return {
      designId: el.dsgn_id,
      designName: el.ref_dsgn_name,
      designOption: el.design_name,
      designParams: el.dsgn_params,
      simulationDesignSessionId: el.smltn_dsgn_ssn_id,
      meta: el.meta,
      ...delflg
  }});

  newData['trialParams'] = data.trialParams;
  newData['trialDurationParams'] = data.trialDurationParams;

  return newData;

}