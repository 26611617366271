import { createSelector } from "@reduxjs/toolkit";

export const createProjectSelector = (state: any) => {
    return state.createProjectSlice;
}

export const getSponsorsSelector = createSelector(createProjectSelector, (items) => {
    return items.sponsors;
})

export const getEngagementsSelector = createSelector(createProjectSelector, (items) => items.engagementTypes);

export const getProtocolsSelector = createSelector(createProjectSelector, (items) => {
    return items.protocols;
})

export const getTherapeuticAreasSelector = createSelector(createProjectSelector, (items) => {
    return items.therapeuticAreas;
})

export const getOtherTherapeuticAreasSelector = createSelector(createProjectSelector, (items) => {
    return items.otherTherapeuticAreas.filter((item: any)=>item.name);
})

export const getObjectivesSelector = createSelector(createProjectSelector, (items) => {
    return items.objectives;
})

export const getPhasesSelector = createSelector(createProjectSelector, (items) => {
    return items.phases;
})

export const getNewProjectIdSelector = createSelector(createProjectSelector, (items) => {
    return items.newProjectId;
})

export const getDuplicateValueExists = createSelector(createProjectSelector,(items)=>{
    return items.duplicateName; 
})