import { createSlice } from '@reduxjs/toolkit';

export const initialState  = {
    defaultTenantSelectSuccess: '',
    loading: false,
    error: '',
}
export const defaultTenantSelectSlice = createSlice({
    name: 'defaultTenantSelect',
    initialState: initialState,
    reducers: {
        defaultTenantSelect: (state, action) => {
            state.loading = true;
        },
        defaultTenantSelectSuccess: (state, action) => {
            state.loading = false;
            state.defaultTenantSelectSuccess = action.payload?.message; 
        },
        defaultTenantSelectError: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },

    },
});

export const {
    defaultTenantSelect,
    defaultTenantSelectSuccess,
    defaultTenantSelectError,
} = defaultTenantSelectSlice.actions;

export default defaultTenantSelectSlice.reducer;
