import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../../app/store';

export const getuserDetails = (state: RootState) => {
  return state.user?.details;
};
export const selectBanner = (state: RootState) => state.addUser?.showBanner;
export const getTenantAdminRole = createSelector(getuserDetails, (data) => {
  return data?.activeTenant?.role?.type === 'Tenant Admin';
});

export const getActiveTenantDetails = createSelector(getuserDetails, (data) => {
  return {
    tenantId: data?.activeTenant?.id,
    tenantName: data?.activeTenant?.tenantNames,
    isInternalTenant: Boolean(data?.activeTenant?.isInternalTenant)
  };
});

export const getActiveUserRole = createSelector(getuserDetails, (data) => {
  if(data.isSystemAdmin === "1") {
    return "System Admin";
  } else {
    return data?.activeTenant?.role?.type;
  }
});