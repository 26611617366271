export function incrementLetter(value:string) {
  let carry = 1;
  let result = '';

  for (let i = value.length - 1; i >= 0; i--) {
    let char = value.toUpperCase().charCodeAt(i);

    char += carry;

    if (char > 90) {
      char = 65;
      carry = 1;
    } else {
      carry = 0;
    }

    result = String.fromCharCode(char) + result;

    if (!carry) {
      result = value.substring(0, i) + result;
      break;
    }
  }

  if (carry) {
    result = 'A' + result;
  }

  return result;
}
