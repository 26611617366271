import React, {useState, useCallback, useEffect} from 'react';
import Button from 'apollo-react/components/Button';
import Modal from 'apollo-react/components/Modal';
import {CONSTANTS} from '../../../../common/constants';
import CreateProjectForm from './CreateProjectForm';
import {createProject, resetProjectId} from '../../slices/CreateProjectSlice';
import {useDispatch, useSelector} from 'react-redux';
import {FormValues} from '../../types/CreateProjectFormTypes';
import {formatDate} from '../../utils/formatDate';
import {getNewProjectIdSelector} from '../../selectors/CreateProjectSelectors';
import {useHistory} from 'react-router-dom';
import {getActiveuser} from '../../selectors/userDetailsSelector';

export default function CreateProject() {
  const [open, setOpen] = useState(false);
  const [showConfimation, setShowConfimation] = useState(false);
  const [formDirty, setFormDirty] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const newProjectId = useSelector(getNewProjectIdSelector);
  const {tenantid, userid} = useSelector(getActiveuser);

  useEffect(() => {
    if (newProjectId) {
      history.push(`/projects/detail/${newProjectId}`);
      dispatch(resetProjectId()); // resets id in the state
    }
  }, [newProjectId, history, dispatch]);

  const openModal = () => {
    setOpen(true);
  };
  const closeModal = () => {
    if (formDirty) {
      setShowConfimation(true);
    } else {
      setOpen(false);
    }
  };
  const closeConfimation = () => {
    setShowConfimation(false);
  };

  const handleConfirmClick = () => {
    closeConfimation();
    setOpen(false);
  };

  const getItemIds = (items: any[]) => {
    return items.filter((item: any) => !item.isNew).map((item: any) => item.id);
  };
  const getItemNames = (items: any[]) => {
    return items.filter((item: any) => item.isNew).map((item: any) => item.name);
  };

  const handleFormSubmit = (values: FormValues) => {
    const {
      name,
      objective,
      sponsors,
      engagementType,
      projectCode,
      dueDate,
      phases,
      compounds,
      therapeuticAreas,
      otherTherapeuticAreas,
      protocols,
      opportunityNumber
    } = values;

    const payload = {
      name,
      objective,
      engagementType,
      projectCode,
      sponsors: getItemIds(sponsors),
      otherSponsors: getItemNames(sponsors),
      owner: userid,
      tenantId: tenantid,
      dueDate: formatDate(dueDate),
      phases,
      compounds,
      therapeuticAreas: therapeuticAreas.map((item: any) => item.id),
      otherTherapeuticAreas: otherTherapeuticAreas.map((item: any) => item.name),
      protocols: getItemIds(protocols),
      otherProtocols: getItemNames(protocols),
      opportunityNumber,
    };

    dispatch(createProject(payload));
  };

  const handleFormDirty = useCallback((dirty: boolean) => {
    setFormDirty(dirty);
  }, []);

  return (
    <>
      <Button variant="primary" data-testid="createProject" onClick={openModal}>
        Create Project
      </Button>
      {open && (
        <Modal
          className="createProjectModal"
          open={open}
          onClose={closeModal}
          title={CONSTANTS.LABELS.CREATE_PROJECT}
        >
          <CreateProjectForm
            handleFormSubmit={handleFormSubmit}
            closeModal={closeModal}
            handleFormDirty={handleFormDirty}
          />
        </Modal>
      )}
      {showConfimation && (
        <Modal
          className="confirmationModal"
          open={showConfimation}
          onClose={closeConfimation}
          title={CONSTANTS.LABELS.CANCEL}
          message={CONSTANTS.INFORMATIONAL_MESSAGES.PROJECT_CANCEL}
          buttonProps={[
            {label: CONSTANTS.LABELS.GO_BACK},
            {label: CONSTANTS.LABELS.CONFIRM, onClick: handleConfirmClick},
          ]}
        ></Modal>
      )}
    </>
  );
}
