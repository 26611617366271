export const seq = (
  from: number,
  to: number,
  n: number = 100
): Array<number> => {
  const incrementer: number = (to - from) / (n - 1);
  const sequence: Array<number> = new Array(100)
    .fill(from)
    .reduce(
      (
        acc: Array<number>,
        val: number,
        index: number,
        array: Array<number>
      ) => {
        if (index - 1 === -1) {
          return [...acc, +val];
        } else {
          return [...acc, +(acc[index - 1] + incrementer)];
        }
      },
      []
    );
  return sequence;
};

export const logFn = (
  x: number,
  E0tox: number,
  Emax: number,
  offset: number,
  rate: number
): number => {
  return E0tox + Emax * Math.pow(1 + Math.exp(-rate * (x - offset)), -1);
};

const betaFn = (deltas: Array<number>) => {
  const deltaSum: number = deltas.reduce(
    (acc: number = 0, val: number) => acc + val
  );
  return (
    Math.pow(deltaSum, deltaSum) /
    (Math.pow(deltas[0], deltas[0]) * Math.pow(deltas[1], deltas[1]))
  );
};

export const betaMod = (
  x: number,
  E0: number,
  Emax: number,
  delta2: number,
  delta1: number,
  scal: number
) => {
  const betaFnValue = betaFn([delta2, delta1]);

  const dividedValue = x / scal;
  return +(
    E0 +
    Emax *
      betaFnValue *
      Math.pow(x / scal, delta1) *
      Math.pow(1 - dividedValue, delta2)
  ).toFixed(6);
};

export const getReferenceAreaCoordincates = (
  dlts: Array<number>,
  PKProb: Array<number>,
  doseSequence: Array<number>,
  pTe2: number,
  pE: number
): { x1: number; x2: number } => {
  const vecc1 = dlts.map((v: number) => v <= pTe2);
  const vecc2 = PKProb.map((v: number) => v >= pE);
  const trueValueIndexes: Array<number|any> = vecc1
    .map((v: boolean, index: number) => v && vecc2[index])
    .map((truthyValue: boolean, i: number) => {
      if (truthyValue) {
        return i;
      } else {
        return undefined;
      }
    })
    .filter((finalValue:any) => finalValue !== undefined);
  const doseSequenceValues: Array<number> = trueValueIndexes.map(
    (truthyIndex: number) => doseSequence[truthyIndex]
  );
  return {
    x1: Math.min(...doseSequenceValues),
    x2: Math.max(...doseSequenceValues)
  };
};

export const betaScaleMultipliers = {
  minScale:1.01,
  maxScale:3,
  defaultScale:1.5
}