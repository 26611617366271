import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import {store} from './app/store';
import {Router} from 'react-router-dom';
import {Provider} from 'react-redux';
import * as serviceWorker from './serviceWorker';
import history from './common/utils/history';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import {initializeGoogleTagManager} from './services/googleAnalytics';
import {Theme,} from '@mui/material/styles';
import ApolloThemeProvider from 'apollo-react/utils/ApolloThemeProvider';

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

initializeGoogleTagManager();


ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
        <Router history={history}>
          <ApolloThemeProvider>
            <App />
          </ApolloThemeProvider>
        </Router>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
