import { CONSTANTS } from '.';

export const COLUMNS = {
  PROJECT_NAME: {
    label: CONSTANTS.LABELS.PROJECT_NAME,
    accessor: CONSTANTS.ACCESSOR.PROJECT_NAME,
  },
  PROJECT_ID: {
    label: CONSTANTS.LABELS.PROJECT_ID,
    accessor: CONSTANTS.ACCESSOR.PROJECT_ID,
  },
  ENGAGEMENT_TYPE: {
    label: CONSTANTS.LABELS.ENGAGEMENT_TYPE,
    accessor: CONSTANTS.ACCESSOR.ENGAGEMENT_TYPE,
  },
  SPONSOR: {
    label: CONSTANTS.LABELS.SPONSORS,
    accessor: CONSTANTS.ACCESSOR.SPONSORS,
  },
  OWNER: {
    label: CONSTANTS.LABELS.OWNER,
    accessor: CONSTANTS.ACCESSOR.OWNER,
  },
  OBJECTIVE: {
    label: CONSTANTS.LABELS.OBJECTIVE,
    accessor: CONSTANTS.ACCESSOR.OBJECTIVE,
  },
  PROJECT_OBJECTIVE: {
    label: CONSTANTS.LABELS.PROJECT_OBJECTIVE,
    accessor: CONSTANTS.ACCESSOR.OBJECTIVE,
  },
  THERAPEUTIC_AREA: {
    label: CONSTANTS.LABELS.THERAPEUTIC_AREAS,
    accessor: CONSTANTS.ACCESSOR.THERAPEUTIC_AREAS,
  },
  PROJECT_MEMBERS: {
    label: CONSTANTS.LABELS.PROJECT_MEMBERS,
    accessor: CONSTANTS.ACCESSOR.PROJECT_MEMBERS,
  },
  DATE_CREATED: {
    label: CONSTANTS.LABELS.DATE_CREATED,
    accessor: CONSTANTS.ACCESSOR.DATE_CREATED,
  },
  SETTINGS: {
    label: CONSTANTS.LABELS.SETTINGS,
    accessor: CONSTANTS.ACCESSOR.SETTINGS,
  },
  NAME: {
    label: CONSTANTS.LABELS.NAME,
    accessor: CONSTANTS.ACCESSOR.NAME,
  },
  EMAIL: {
    label: CONSTANTS.LABELS.EMAIL,
    accessor: CONSTANTS.ACCESSOR.EMAIL,
  },
  ADDED_ON: {
    label: CONSTANTS.LABELS.ADDED_ON,
    accessor: CONSTANTS.ACCESSOR.ADDED_ON,
  },
  ROLE: {
    label: CONSTANTS.LABELS.ROLE,
    accessor: CONSTANTS.ACCESSOR.ROLE,
  },
  STATUS: {
    label: CONSTANTS.LABELS.STATUS,
    accessor: CONSTANTS.ACCESSOR.STATUS,
  },
  PROJECTS: {
    label: CONSTANTS.LABELS.PROJECTS,
    accessor: CONSTANTS.ACCESSOR.PROJECTS,
  },
  TENANT: {
    label: CONSTANTS.LABELS.TENANT,
    accessor: CONSTANTS.ACCESSOR.TENANT,
  },
  ORGANIZATION: {
    label: CONSTANTS.LABELS.ORGANIZATION,
    accessor: CONSTANTS.ACCESSOR.ORGANIZATION,
  },
  ADMIN_NAME: {
    label: CONSTANTS.LABELS.ADMIN_NAME,
    accessor: CONSTANTS.ACCESSOR.ADMIN_NAME,
  },
  ADMIN_EMAIL: {
    label: CONSTANTS.LABELS.ADMIN_EMAIL,
    accessor: CONSTANTS.ACCESSOR.ADMIN_EMAIL,
  },
  TENANT_ADDED_ON: {
    label: CONSTANTS.LABELS.TENANT_ADDED_ON,
    accessor: CONSTANTS.ACCESSOR.TENANT_ADDED_ON,
  },
  APPLICATION_NAME: {
    label: CONSTANTS.LABELS.APPLICATION_NAME,
    accessor: CONSTANTS.ACCESSOR.APPLICATION_NAME,
  },
  WORKSPACE: {
    label: CONSTANTS.LABELS.WORKSPACE,
    accessor: CONSTANTS.ACCESSOR.WORKSPACE,
  },
  REQ_EMAIL: {
    label: CONSTANTS.LABELS.REQ_EMAIL,
    accessor: CONSTANTS.ACCESSOR.REQ_EMAIL,
  },
  START_DATE: {
    label: CONSTANTS.LABELS.START_DATE,
    accessor: CONSTANTS.ACCESSOR.START_DATE,
  },
  END_DATE: {
    label: CONSTANTS.LABELS.END_DATE,
    accessor: CONSTANTS.ACCESSOR.END_DATE,
  }
};
