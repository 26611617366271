import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import map from 'lodash/map';
import keys from 'lodash/keys';
import {GraphSliceType, DefaultFieldsTypes} from '../../types/CustomGraphTypes';

const getDefaultIntialFields: DefaultFieldsTypes = {
  graphName: 'Graph 1',
  xAxis: '',
  yAxis: '',
  graphType: '',
  disabled: false,
};

export const initialState: GraphSliceType = {
  isLoading: false,
  error: '',
  generatedGraphs: [],
  results: [],
  graphData: [],
  defaultVariables: [],
  defaultType: [],
  enableAddGraphField: true,
  isTestRun: false,
  isProjectOwner: false,
  sessionName: '',
  sessionId: '',
  showConfirmationModal: {
    active: false,
    message: '',
    props: {},
  },
};

export const generateGraphsSlice = createSlice({
  name: 'generateGraphs',
  initialState: initialState as GraphSliceType,
  reducers: {
    fetchRequest: (state: GraphSliceType, action) => {
      state.isLoading = true;
    },
    fetchSuccess: (state: GraphSliceType, action: PayloadAction<any>) => {
      const {data: payloadData, isTestRun, isProjOwner, sessionName, projSessionId} = action.payload;
      const {data, type, generatedGraphData} = payloadData;
      const generatedGraphDataFields = !generatedGraphData.length
        ? [{graphId: 'new', graphData: getDefaultIntialFields}]
        : modifedGeneratedGraph(generatedGraphData);
      state.isTestRun = isTestRun;
      state.isProjectOwner = isProjOwner;
      state.sessionName = sessionName;
      state.sessionId = projSessionId;
      state.graphData = data || [];
      const varaibles = data.length ? map(data, keys) : [];
      state.generatedGraphs = generatedGraphDataFields;
      state.results = generateGraphs(generatedGraphData, data);
      state.defaultType = type;
      state.defaultVariables = varaibles[0] || [];
      state.isLoading = false;
    },
    postGraphsRequest: (state: GraphSliceType, action) => {
      state.isLoading = true;
    },
    postGraphsSuccess: (state: GraphSliceType, action: PayloadAction<any>) => {
      const generatedGraphData = action.payload.data;
      const modifedGeneratedGraphData = modifedGeneratedGraph(generatedGraphData);
      state.generatedGraphs = modifedGeneratedGraphData;
      state.results = generateGraphs(generatedGraphData, state.graphData);
      state.isLoading = false;
    },
    postGraphsFailure: (state: GraphSliceType, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    fetchFailure: (state: GraphSliceType, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    addNewGraph: (state: GraphSliceType) => {
      const graphName = getUniqueGraphName(state.generatedGraphs);
      const newGraph = {
        graphName,
        xAxis: '',
        yAxis: '',
        graphType: '',
        disabled: false,
      };

      state.generatedGraphs = [...state.generatedGraphs, {graphId: 'new', graphData: newGraph}];
    },
    deleteGraph: (state: GraphSliceType, action) => {
      const graphId = action.payload;
      if (graphId === 'new') {
        const updatedData = state.generatedGraphs.filter((v: any) => v.graphId !== graphId);
        state.generatedGraphs = updatedData;
        return;
      } else {
        const removeGraphData = state.generatedGraphs.filter((v: any) => v.graphId === graphId);
        state.showConfirmationModal = {
          active: true,
          message: `Are you Sure you want to remove ${removeGraphData[0].graphData.graphName} from Generated Graphs`,
          props: {graphId},
        };
      }
    },
    closeModal: (state: GraphSliceType) => {
      state.showConfirmationModal = {active: false, message: '', props: {}};
    },
    deleteGraphRequest: (state: GraphSliceType, action) => {
      state.isLoading = true;
      state.showConfirmationModal = {active: false, message: '', props: {}};
    },
    deleteGraphSuccess: (state: GraphSliceType, action) => {
      const generatedGraphData = action.payload.data;
      const modifedGeneratedGraphData = modifedGeneratedGraph(generatedGraphData);
      state.generatedGraphs = modifedGeneratedGraphData;
      state.results = generateGraphs(generatedGraphData, state.graphData);
      state.isLoading = false;
    },
    deleteGraphFailure: (state: GraphSliceType, action) => {
      state.isLoading = false;
    },
    resetGraphs: (state: GraphSliceType) => {
      state.isTestRun = false;
      state.isProjectOwner = false;
      state.sessionName = '';
      state.sessionId = '';
      state.results = [];
      state.generatedGraphs = [];
      state.graphData = [];
      state.defaultVariables = [];
      state.defaultType = [];
    },
  },
});

export const {
  fetchRequest,
  fetchSuccess,
  fetchFailure,
  postGraphsRequest,
  postGraphsSuccess,
  postGraphsFailure,
  addNewGraph,
  deleteGraph,
  closeModal,
  deleteGraphRequest,
  deleteGraphSuccess,
  deleteGraphFailure,
  resetGraphs,
} = generateGraphsSlice.actions;
export default generateGraphsSlice.reducer;

export const modifedGeneratedGraph = (data: any) => {
  if (!data.length) {
    return [];
  }
  return data.map((v: any) => {
    const graphData = JSON.parse(v.graphData);
    return {...v, graphData: {...graphData}};
  });
};

const getGraphName = (fields: any) => fields.map((field: any) => field.graphData['graphName'].toUpperCase());
const getUniqueGraphName = (fields: any): string => {
  const graphText = 'Graph';
  const increment = fields.length === 0 ? 1 : fields.length + 1;
  const exsitingGraphName = getGraphName(fields);
  const findUniqueText = findUniqueGraphText(exsitingGraphName, increment, graphText);
  return findUniqueText;
};

const findUniqueGraphText = (graphNames: any, increment: any, graphText: any): string => {
  const text = `${graphText} ${increment}`.toUpperCase();
  if (!graphNames.includes(text)) {
    return `${graphText} ${increment}`;
  } else {
    const newIncrement = increment + 1;
    return findUniqueGraphText(graphNames, newIncrement, graphText);
  }
};

const getLineChartData = (xAxis: string, yAxis: string, rawData: Array<any>): any => {
  const variables = {x: map(rawData, `${xAxis}`), y: map(rawData, `${yAxis}`)};
  const formatData = variables.x.map((v: number, i: number) => ({x: v || 0, y: variables.y[i] || 0}));
  const data = {
    xLabel: xAxis,
    yLabel: yAxis,
    data: formatData,
  };
  return data;
};

const getBarChartData = (xAxis: string, yAxis: string, rawData: Array<any>): any => {
  const variables = {x: map(rawData, `${xAxis}`), y: map(rawData, `${yAxis}`)};
  const formatData = variables.x.map((v: number, i: number) => ({x: v || 0, y: variables.y[i] || 0}));
  const data = {
    xLabel: xAxis,
    yLabel: yAxis,
    data: formatData,
  };
  return data;
};

const getScatterChartData = (xAxis: string, yAxis: string, rawData: Array<any>): any => {
  const variables = {x: map(rawData, `${xAxis}`), y: map(rawData, `${yAxis}`)};
  const formatData = variables.x.map((v: number, i: number) => ({x: v || 0, y: variables.y[i] || 0}));
  const data = {
    xLabel: xAxis,
    yLabel: yAxis,
    data: formatData,
  };
  return data;
};

export const generateGraphs = (generateGraphs: any, rawData: any) => {
  if (!generateGraphs.length || !rawData.length) {
    return [];
  }
  return generateGraphs.map((graph: any) => {
    const {xAxis, yAxis, graphType, ...rest} = JSON.parse(graph.graphData) || {};
    let data: Array<any> = [];
    if (graphType === 'Line Graph') {
      data = getLineChartData(xAxis, yAxis, rawData);
    } else if (graphType === 'Bar Graph') {
      data = getBarChartData(xAxis, yAxis, rawData);
    } else if (graphType === 'Scatter Graph') {
      data = getScatterChartData(xAxis, yAxis, rawData);
    }
    return {data, graphType, xAxis, yAxis, ...rest};
  });
};
