import React, { useState } from 'react';
import Modal from 'apollo-react/components/Modal';
import { CONSTANTS } from '../../constants';
import './styles.scss';
import { useSelector } from 'react-redux';
import { trainingVideoSelector } from '../../../features/projects/selectors/TrainingVideoSelector';
import Loader from 'apollo-react/components/Loader';
import Tab from 'apollo-react/components/Tab';
import Tabs from 'apollo-react/components/Tabs';
import Accordion from 'apollo-react/components/Accordion';
import AccordionDetails from 'apollo-react/components/AccordionDetails';
import AccordionSummary from 'apollo-react/components/AccordionSummary';
import Typography from 'apollo-react/components/Typography';

type TrainingVideoProps = {
  open: boolean;
  onClose: Function;
};

type WorkflowType = {
  preSignedURL: string;
  title: string;
};

function TrainingVideo({ open, onClose }: TrainingVideoProps) {
  const [tab, setTab] = useState(0);
  const [workflowModal, setWorkFlowModal] = useState(false);
  const [workflowImg, setWorkflowImg] = useState<WorkflowType | null>(null);
  const trainingDocuments = useSelector(trainingVideoSelector);
  const { data, isLoading } = trainingDocuments;

  const formatByCategory = (data: any) => {
    let list: any = {};
    data?.length > 0 && data.forEach((content: any) => {
      let key = content.category
      if (list[key]) {
        list[key].push(content);
      } else {
        list[key] = [content];
      }
    });
    return list;
  };

  const videos = formatByCategory(data.video);
  const workflows = formatByCategory(data.workflow);

  const closeModal = () => {
    onClose();
  };

  const handleChangeTab = (event: any, value: number) => {
    setTab(value);
  };

  return (
    <Modal
      className="training-video"
      open={open}
      onClose={closeModal}
      title={CONSTANTS.LABELS.TRAINING_VIDEO_LABEL}
      hideButtons={true}
    >
      <div datatest-id="training-video-test" style={{ width: '500px', height: 'auto', minHeight: '200px' }}>
        {
          isLoading ?
            <Loader isInner />
            :
            (<>
              <Tabs value={tab} variant="fullWidth" onChange={handleChangeTab} truncate>
                <Tab label="Videos" />
                <Tab label="Workflows" />
              </Tabs>
              {
                tab === 0 &&
                (<div style={{ overflow: 'auto', marginTop: '30px' }}>
                  {
                    Object.keys(videos)?.length > 0 ? Object.keys(videos).map((category: string, key: number) => (
                      <React.Fragment key={key}>
                        <Accordion defaultExpanded={key === 0 ? true : false}>
                          <AccordionSummary>
                            <Typography>{category}</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <div style={{ display: "flex", flexDirection: "column" }}>
                              {
                                videos[category].map((video: any, key: number) => (
                                  <div key={`category-${video['title']}`} style={{ display: "flex", flexDirection: "column" }}>
                                    <Typography variant="h6" gutterBottom>
                                      {video['title']}
                                    </Typography>
                                    <Typography variant="caption">{video['description']}</Typography>
                                    <video style={{
                                      paddingBottom: '15px', width: '100%'
                                    }} height="240"
                                      controls controlsList="nodownload">
                                      <source src={video['preSignedURL']} type="video/mp4" />
                                    </video>
                                    {(key < videos[category].length - 1) &&
                                      <hr style={{ width: '100%', backgroundColor: 'lightgrey' }} />
                                    }
                                  </div>
                                ))
                              }
                            </div>
                          </AccordionDetails>
                        </Accordion>
                      </React.Fragment>
                    ))
                      :
                      <Typography>No Video to display</Typography>
                  }
                </div>
                )
              }
              {
                tab === 1 &&
                (<div style={{ overflow: 'auto', marginTop: '30px' }}>
                  {
                    Object.keys(workflows)?.length > 0 ? Object.keys(workflows).map((category: string, key: number) => (
                      <React.Fragment key={key}>
                        <Accordion defaultExpanded={key === 0 ? true : false}>
                          <AccordionSummary>
                            <Typography>{category}</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <div style={{ display: "flex", flexDirection: "column" }}>
                              {
                                workflows[category].map((workflow: any, key: number) => (
                                  <div key={`workflow-${key}`} style={{ display: "flex", flexDirection: "column" }}>
                                    <Typography variant="h6" gutterBottom>
                                      {workflow['title']}
                                    </Typography>
                                    <img
                                      alt="workflow"
                                      style={{
                                        paddingBottom: '15px', width: '100%', cursor: 'pointer'
                                      }}
                                      height="240"
                                      src={workflow['preSignedURL']}
                                      onClick={() => {
                                        setWorkFlowModal(true);
                                        setWorkflowImg(workflow);
                                      }}
                                    />
                                    {(key < workflows[category].length - 1) &&
                                      <hr style={{ width: '100%', backgroundColor: 'lightgrey' }} />
                                    }
                                  </div>
                                ))
                              }
                            </div>
                          </AccordionDetails>
                        </Accordion>
                        {
                          workflowImg && (
                            <Modal
                              open={workflowModal}
                              fullWidth={true}
                              maxWidth="lg"
                              title={workflowImg['title']}
                              onClose={() => setWorkFlowModal(false)}
                              id="workflow-modal"
                              hideButtons={true}
                            >
                              <img src={workflowImg['preSignedURL']} alt={workflowImg['title']} />
                            </Modal>  
                          )
                        }
                      </React.Fragment>
                    ))
                      :
                      <Typography>No Workflow to display</Typography>
                  }
                </div>)
              }
            </>)
        }
      </div>
    </Modal >
  );
}

export default TrainingVideo;
