import { put, takeLatest, call } from 'redux-saga/effects';
import {
    fetchUserFailure,
    loadUserDetails,
    fetchUserRequest,
} from '../slices/userDetailsSlice';
import { ENDPOINTS } from '../../../services/endpoint';
import { callApi } from '../../../services/api';
import get from 'lodash/get';
import { addSessionStorage, getSessionStorage } from '../../../common/utils/useSessionStorage'

export default function* setUserDetailsSaga() {
    yield takeLatest(fetchUserRequest, getUserDetails);
}

export function* getUserDetails({ payload: userid }: any) {
    try {
        const url = `${ENDPOINTS.USER_PROFILE}?userid=${userid}`;
        const result = yield call(callApi, url);
        const isErrorCode = get(result,'errorCode', null);
        if(isErrorCode){
            yield put(fetchUserFailure({ code: isErrorCode }));
        } else {
          // Do not set tenantid for systemAdmin  
          (result.data.isSystemAdmin !== '1') && setTenantInSessonStorage(result.data)
          yield put(loadUserDetails(result));
          result?.data?.userID && addSessionStorage('userid', JSON.stringify(result.data.userID));
        }
    } catch (error : any) {
        console.warn(error);
        yield put(fetchUserFailure({ message: error?.error }));
    }
}

function setTenantInSessonStorage(userData: any)  {
  const tenantId = getSessionStorage('tenantid',true);
  if(!tenantId) {
    if(userData.defaultTenantId) {
      sessionStorage.setItem('tenantid', userData.defaultTenantId);
    } else if (userData?.tenantIDs?.length === 1) {
      sessionStorage.setItem('tenantid', userData.tenantIDs[0]);
    }
  } 
}

