import React, { useMemo, useState } from 'react';
import NavigationBar from 'apollo-react/components/NavigationBar';
import styles from './navigation.module.css';
import Button from 'apollo-react/components/Button';
import { withRouter } from 'react-router';
import { RouteComponentProps } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setActiveTenant } from '../../../features/projects/slices/userDetailsSlice';
import { CONSTANTS, S3_DOCS } from '../../constants';
import TenantDropdown from '../TenantDropdown';
import Typography from 'apollo-react/components/Typography';
import Help from 'apollo-react-icons/Help';
import Question from 'apollo-react-icons/Question'
import Email from 'apollo-react-icons/Email';
import Cog from 'apollo-react-icons/Cog';
import Tooltip from 'apollo-react/components/Tooltip';
import { getSessionStorage, removeSessionStorage } from '../../utils/useSessionStorage';
import FilePdf from 'apollo-react-icons/FilePdf';
import { callApi } from '../../../services/api';
import { ENDPOINTS } from '../../../services/endpoint';
import SubmitFeedback from '../SubmitFeedback';
import TrainingVideo from '../TrainingVideo';
import { fetchRequest } from '../../../features/projects/slices/TrainingVideoSlice';
import { getActiveUserRole } from '../../../features/projects/selectors/userDetailsSelector';
import { reportAnIssue } from '../../utils';
import { getAccountSettingsUrl } from '../../../services/endpoint';
import { EFFICACY_DT_SESSION_STORAGE_KEY } from '../../constants/twoParamDecisionTableConstants';

interface NavigationProps extends RouteComponentProps {
  user: any;
}

const Navigation = ({
  history,
  location: { pathname },
  user,
}: NavigationProps) => {
  const { userName, emailID, tenants, qID } = user;
  const [showFeedbackForm, setShowFeedbackForm] = useState(false);
  const [showTrainingVideos, setShowTrainingVideos] = useState(false);
  const tenantId = getSessionStorage('tenantid', true);
  const name = tenants?.find((tenant: any) => (tenant?.id === tenantId))?.tenantNames;
  const dispatch = useDispatch();
  const activeUserRole = useSelector(getActiveUserRole);
  const canShowFeedbackOption = useMemo(() => ['Designer', 'Reviewer'].includes(activeUserRole), [activeUserRole]);

  const handler = (id: number) => {
    if (pathname.startsWith('/projects/session') || pathname.startsWith('/projects/accrual')) {
      history.push(`/?workspace=${id}`);
    } else {
      dispatch(setActiveTenant(id));
      pathname !== '/' && history.push('/');
    }
    //Clear Efficacy Decision Table Info
    removeSessionStorage(EFFICACY_DT_SESSION_STORAGE_KEY);
    removeSessionStorage('efficacyDT');
  };

  const logoProps = {
    text: CONSTANTS.LABELS.IQVIA_TRIAL_DESIGNER,
    alt: 'Apollo logo alt text',
    className: `${styles.textLogo1}`,
    onClick: () => {
      history.push('/')
      //Clear Efficacy Decision Table Info
      removeSessionStorage(EFFICACY_DT_SESSION_STORAGE_KEY);
      removeSessionStorage('efficacyDT');
    },
  };

  const isSystemAdmin = user.isSystemAdmin === '1';
  const isTenantAdmin = user?.activeTenant?.role?.type === 'Tenant Admin';
  let menuItems;
  if (isSystemAdmin) {
    menuItems = [
      {
        text: 'Workspace Management',
        pathname: '/tenantManagement',
        active: pathname === '/' || pathname.startsWith('/tenantManagement'),
      },
      {
        text: 'Integration Management',
        pathname: '/integrationManagement',
        active: pathname === '/' || pathname.startsWith('/integrationManagement'),
      }
    ];
  } else if (isTenantAdmin) {
    menuItems = [
      {
        text: 'User Management',
        pathname: '/userManagement',
        active: pathname === '/' || pathname.startsWith('/userManagement'),
      },
      {
        text: 'Projects',
        pathname: '/projects',
        active: (!isTenantAdmin && pathname === '/') || pathname.startsWith('/projects'),
      }
    ];
  } else {
    menuItems = [
      {
        text: 'Projects',
        pathname: '/projects',
        active: (!isTenantAdmin && pathname === '/') || pathname.startsWith('/projects'),
      }
    ];
  }

  const onLogout = () => history.push('/logout');

  const openFeedbackModal = (show: boolean) => setShowFeedbackForm(show);
  const openTrainingVideoModal = () => {
    setShowTrainingVideos(true);
    dispatch(fetchRequest({ tenantId }));
  }

  const profileMenuProps = {
    name: userName?.length > 17 ? (<Tooltip variant="light" subtitle={userName} placement="bottom">
      <Typography darkMode >{`${userName.substring(0, 14)}...`}</Typography>
    </Tooltip>) : userName,
    title: userName,
    email: emailID,
    logoutButtonProps: { onClick: onLogout, text: 'Log Out' },
    menuItems: [ // Report an issue option is applicable for internal user only
      ...(!!qID ? [{
        text: CONSTANTS.LABELS.REPORT_AN_ISSUE,
        onClick: reportAnIssue,
        icon: Help,
      }] : [{
        text: 'Account Settings',
        onClick: () => window.open(getAccountSettingsUrl(), '_blank'),
        icon: Cog,
      }]),
      ...(canShowFeedbackOption ? [{ // Submit Feedback option is applicable only for Designer or Reviewer
        text: 'Submit Feedback',
        onClick: () => openFeedbackModal(true),
        icon: Email,
      }] : []),
      { // Maunal pdf
        text: 'User Manual',
        onClick: () => { fetchUserManualUrl() },
        icon: FilePdf,
      },
      {
        text: 'Release Notes',
        onClick: () => { fetchOpenS3Doc(`${ENDPOINTS.GET_TEMPLATE}?filename=${S3_DOCS.RELEASE_NOTES}`) },
        icon: FilePdf,
      },
    ],
  };

  const navClick = ({ pathname }: any = {}) => {
    pathname && history.push(pathname);
    //Clear Efficacy Decision Table Info
    removeSessionStorage(EFFICACY_DT_SESSION_STORAGE_KEY);
    removeSessionStorage('efficacyDT');
  };

  const selectCallBack = (tenantIdClicked: any) => {
    // Adding tenantid to sessionStorage & shall be sent in every API request
    handler(tenantIdClicked)
  };

  const fetchUserManualUrl = () => {
    const tenantid: any = parseInt(sessionStorage.getItem('tenantid') as string);
    let url = `${ENDPOINTS.GET_TEMPLATE}?filename=${S3_DOCS.HELP_MANUAL}`;
    if (tenantid) {
      url += `&tenantid=${tenantid}`
    }
    fetchOpenS3Doc(url);
  };

  const fetchOpenS3Doc = async (url: string) => {
    try {
      const data = await callApi(url);
      window.open(data?.preSignedURL || '', '_blank');
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      {canShowFeedbackOption && <SubmitFeedback open={showFeedbackForm} onOpenClose={openFeedbackModal} />}
      <TrainingVideo open={showTrainingVideos} onClose={() => setShowTrainingVideos(false)} />
      <NavigationBar
        id='navigation'
        logoProps={logoProps}
        menuItems={menuItems}
        profileMenuProps={profileMenuProps}
        waves
        onClick={navClick}
      />
      <Button
        icon={<Question />}
        onClick={() => openTrainingVideoModal()}
        className={styles.learnMore}>
        Learn More
      </Button>
      {!isSystemAdmin && <TenantDropdown options={tenants} selected={name} callBackFromParent={selectCallBack} />}
    </>
  );
};

export default withRouter(Navigation);
