export const getRandomNumber = (): number => {
  const getdigit = () => {
    return Math.floor(Math.random() * 9) + 1;
  };
  const rNum = Math.floor((Math.random() * 9999999999 + 1) / Math.pow(10, getdigit()));
  return rNum ? rNum : getRandomNumber();
};

export const resetSessionStorage = () => {
  sessionStorage.removeItem('projectDetail');
};

export const mergeParams = (params1: Array<string> = [], params2: Array<string> = []) => {
  let finalParams = removeDuplicates(params1.concat(params2));

  return finalParams.sort();
};

export const extractParams = (code: string, prefix: string) => {
  /*matches every word with prefix '<prefix>$' in <code>, 
  eg: if <prefix> = 'data', match will be 'data$xyz' if 'data$xyz' present in <code>*/
  const regEx = new RegExp(`${'\\b' + prefix + '\\$\\w+'}`, 'gi');

  /*matches only prefix '<prefix>$' in a <string>,
  eg: if <prefix> = 'data', match will be 'data$' if <string> = 'data$xyz'*/
  const replaceRegEx = new RegExp(`${'\\b' + prefix + '\\$'}`, 'gi');

  let params = removeDuplicates(code.match(regEx) || []);

  let finalParams = params.map((element) => element.replace(replaceRegEx, ''));

  finalParams.sort();

  return finalParams || [];
};

const removeDuplicates = (array: Array<string>) => {
  let set = new Set(array);
  let finalArray = Array.from(set);
  return finalArray || [];
};

export const replaceParamPrefix = (code: string, prefixToReplace: string, prefixReplacement: string) => {
  /*matches only prefix '<prefixToReplace>$' in a <code>,
  eg: if <prefix> = 'data', match will be 'data$' if <code> = 'data$xyz  abc'*/
  const replaceRegEx = new RegExp(`${'\\b' + prefixToReplace + '\\$'}`, 'gi');

  const finalCode = code.replace(replaceRegEx, `${prefixReplacement}$`);

  return finalCode;
};

export const convertToMathLog2 = (value: number) => {
  return +(Math.log(2) / value).toPrecision(4);
};

export const lambdaEBoin = (targetProbToxicity: string | number, phi1: string | number) => {
  const phi = Number(targetProbToxicity);
  phi1 = Number(phi1);
  const part1 = (1 - phi1) / (1 - phi);
  const logPart1 = isNaN(part1) ? 0 : Math.log(part1);
  const part2 = (phi * (1 - phi1)) / (phi1 * (1 - phi));
  const logPart2 = isNaN(part2) ? 0 : Math.log(part2);
  const lamdae = logPart1/logPart2;
  const sanitisedValue = isNaN(lamdae) ? '' : roundNumber(lamdae, 4);
  
  return sanitisedValue;
};

export const lambdaDBoin = (targetProbToxicity: string | number, phi2: string | number) => {
  const phi = Number(targetProbToxicity);
  phi2 = Number(phi2);
  const part1 = (1 - phi) / (1 - phi2);
  const logPart1 = isNaN(part1) ? 0 : Math.log(part1);
  const part2 = (phi2 * (1 - phi)) / (phi * (1 - phi2));
  const logPart2 = isNaN(part2) ? 0 : Math.log(part2);
  const lamdad = logPart1 / logPart2;
  const sanitisedValue = isNaN(lamdad) ? '' : Math.floor(lamdad * 10000) / 10000;

  return sanitisedValue;
};

export const calculateBoinToxicityProbDependents = (targetProbToxicity: string | number) => {
  const targetToxicity = Number(targetProbToxicity);
  const phi1 = Number((0.6 * targetToxicity).toFixed(2));
  const phi2 = Number((1.4 * targetToxicity).toFixed(2));
  return {
    'ɸ1boin': phi1,
    'ɸ2boin': phi2,
    'λeboin': lambdaEBoin(targetToxicity, phi1),
    'λdboin': lambdaDBoin(targetToxicity, phi2),
  };
};

export const calculateI3plus3ToxicityProbDependents = (targetProbToxicity: string | number) => {
  const targetToxicity = Number(targetProbToxicity);
  const e1 = Number((0.8 * targetToxicity).toFixed(2));
  const e2 = Number((1.2 * targetToxicity).toFixed(2));
  return {
    'e1i3plus3': e1,
    'e2i3plus3': e2
  };
};

export const calcLowerLimitGradPrintE = (target2ndProb: string | number, minAcceptableProb: string | number) => {
  // LowerProbGradPrintE default is mean (Target <Second parameter> Prob., Min. Acceptable Prob.)
  target2ndProb  = Number(target2ndProb) || 0;
  minAcceptableProb  = Number(minAcceptableProb) || 0;
  return (target2ndProb + minAcceptableProb) / 2;
};

export const disableFlowChart = (formType: string) => {
  const disabledDesigns = ["i3+3", "BOIN", "mTPI-2"];
  return disabledDesigns.includes(formType);
}

// if decimal length is greater than 4
export const parseToPrecision = (value : any) : any => {

  // special case for value format is Ex : "2.9053+/-0.5848"
  if(typeof value === 'string' && value.includes('+/-')){
    return value.split('+/-').map((el: string) => parseToPrecision(el)).join('+/-');
  }

  const newValue = Number(Number(value).toPrecision(4));

  return /^(\d*\.\d{3,})/.test(value) ? !isNaN(newValue) ? newValue : value : value;
}

export const parseToSignificantFigure = (data: any): any => {
  try {
    // if el is typeof object
    if (!Array.isArray(data) && data instanceof Object) {
      return Object.fromEntries(
        Object.entries(data).map(([key, val]: any) => {
          // special case for val format is Ex : "2.9053+/-0.5848"
          if (typeof val === 'string' && val.includes('+/-')) {
            const tempValue = val
              .split('+/-')
              .map((el: string) => parseToPrecision(el))
              .join('+/-');
            return [key, tempValue];
          }

          if (typeof val === 'string' || typeof val === 'number') {
            return [key, parseToPrecision(val)];
          }

          if (Array.isArray(val) || (!Array.isArray(val) && data instanceof Object)) {
            return [key, parseToSignificantFigure(val)];
          }

          return [key, val];
        }),
      );
    }

    if (Array.isArray(data)) {
      return data.map((el) => parseToSignificantFigure(el));
    }

    if (typeof data === 'string' || typeof data === 'number') {
      return parseToPrecision(data);
    }

    return data;
  } catch (error) {
    console.log(error);
    return data;
  }
};


export const isNullUndefined = (value: any) => {
  if(typeof value === 'undefined' || value === null ){
    return true;
  }
  return false;
}

export const reportAnIssue = () => window.open('https://quintiles.service-now.com/via?id=sc_cat_item&sys_id=9402a6ea1b0364908d84a683604bcbc1', '_blank')

export const roundNumber = (val: number, precision: number) => {
  try {
    const precisionBase = Math.pow(10,precision);
    return Math.round((val * precisionBase)) / precisionBase;
  } catch (e) {
    console.error("Number rounding error:", e);
    return val; // return same value in case of failure.
  }
};


export const sortByWord = (array: any[], word: string) => {
  let array1: any[] = [];
  let array2: any[] = [];

  if(!Array.isArray(array)) return [];

  array.forEach((a) => {
    if (a?.value?.includes(word)) {
      array1.push(a);
    } else {
      array2.push(a);
    }
  });

  return [...array2, ...array1];
}