export function limitDecimal(value: any): any {
  if (Array.isArray(value)) {
    const newArray = [];
    for (let i = 0; i < value.length; i++) {
      if (typeof value[i] === 'number') {
        newArray.push(Math.round(value[i] * 100) / 100);
      } else {
        newArray.push(value[i]);
      }
    }
    return newArray;
  } else if (typeof value === 'number') {
    return Math.round(value * 100) / 100;
  } else if (value === '') {
    return ' ';
  } else {
    return value;
  }
}

