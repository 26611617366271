import {getCumulativeSpent} from '../components/Objectives/GroupSequential/SessionPage/Forms/TrialDesignParameters/SpendingFuncHelpers';
// Currenlty we have limitation to get some defaults for trialDuration Params
export const DEFAULTS = {
  specifyExpectedDuration: {
    isSspecifyExpectedDuration: 'Yes',
    trialDuration: 48,
  },
  pieceWiseRelativeAccrualRate: {
    usePieceWiseRelativeAccrualRate: 'No',
    accrualRatePeriods: 2,
    accrualRate: '',
  },
  pieceWiseAccrualRate: {
    usePieceWiseAccrualRate: '',
    accrualRatePeriods: 2,
    accrualRate: '',
    accrualPeriod: '',
  },
};

const mapDesginType = (type: any): string => {
  if (type === 'Trial Design Parameters') {
    return 'trialParameters';
  } else if (type === 'Trial Duration/Accrual Parameters') {
    return 'trialDurationParameters';
  } else if (type === 'SSR') {
    return 'ssr';
  }
  return '';
};

export const mapDefaultValues = (form: any[]) => {
  const defaults = form.reduce((acc, el) => {
    const dsgnType = mapDesginType(el.dsgn_type);
    if (!acc[dsgnType]) {
      const data = getFormObject(el?.details);
      return {...acc, [dsgnType]: data};
    }
    return {};
  }, {});

  if (defaults.trialDurationParameters?.specifyExpectedDuration === '') {
    defaults.trialDurationParameters.specifyExpectedDuration = {...DEFAULTS.specifyExpectedDuration};
  }
  if (defaults?.trialDurationParameters?.pieceWiseRelativeAccrualRate === '') {
    defaults.trialDurationParameters.pieceWiseRelativeAccrualRate = {
      ...DEFAULTS.pieceWiseRelativeAccrualRate,
    };
  }

  if (defaults.trialDurationParameters?.pieceWiseAccrualRate === '') {
    defaults.trialDurationParameters.pieceWiseAccrualRate = {...DEFAULTS.pieceWiseAccrualRate};
  }

  return defaults;
};

const getFormObject = (desgnFields: any[]) => {
  return Array.isArray(desgnFields)
    ? desgnFields.reduce((acc, el) => {
        return {
          ...acc,
          [el.name]: el?.value,
        };
      }, {})
    : {};
};

const parseIntValueByNumberType = (data: any) => {
  return data.map((item1: any) => {
    item1.details = item1.details.map((item2: any) => {
      if (item2.type === 'number') {
        item2.value = item2.value && typeof item2.value === 'string' ? Number(item2.value) : item2.value;
        return item2;
      }

      return item2;
    });

    return item1;
  });
};

export function* extractFields(data: any, formValues?: any): any {
  const parseData = parseIntValueByNumberType(data);
  let newFormat = yield parseData.reduce(reduceFields, {});
  // check if value type is string, convert to array
  if (
    newFormat?.intialValues?.trialParameters?.timingofInterimAnalyses &&
    !Array.isArray(newFormat?.intialValues?.trialParameters?.timingofInterimAnalyses)
  ) {
    newFormat.intialValues.trialParameters.timingofInterimAnalyses = [
      newFormat.intialValues.trialParameters.timingofInterimAnalyses,
    ];
  }
  if (!newFormat?.intialValues?.trialParameters?.cumulativeProportionsSpentUpper) {
    newFormat.intialValues.trialParameters.cumulativeProportionsSpentUpper =
      newFormat.intialValues.trialParameters.timingofInterimAnalyses;
    newFormat.intialValues.trialParameters.cumulativeAlphaSpentUpper = getCumulativeSpent(
      newFormat?.intialValues?.trialParameters?.timingofInterimAnalyses,
      newFormat?.intialValues?.trialParameters?.type1Error,
    );
  }

  // Input Parameters for Haybittle Peto Cumulative Alpha for IAs
  if (!newFormat?.intialValues?.trialParameters?.cumulativeAlphaSpentUpperHaybittle || !Array.isArray(newFormat?.intialValues?.trialParameters?.cumulativeAlphaSpentUpperHaybittle)) {
    newFormat.intialValues.trialParameters.cumulativeAlphaSpentUpperHaybittle = [newFormat.intialValues.trialParameters.cumulativeAlphaSpentUpperHaybittle];
  }
  
  if (!newFormat?.intialValues?.trialParameters?.cumulativeProportionsSpentLower) {
    newFormat.intialValues.trialParameters.cumulativeProportionsSpentLower =
      newFormat.intialValues.trialParameters.timingofInterimAnalyses;
    newFormat.intialValues.trialParameters.cumulativeBetaSpentLower = getCumulativeSpent(
      newFormat?.intialValues?.trialParameters?.timingofInterimAnalyses,
      newFormat?.intialValues?.trialParameters?.type2Error,
    );
  }

  if (typeof newFormat?.intialValues?.trialParameters?.hazardRateTablePeriods === 'string') {
    newFormat.intialValues.trialParameters.hazardRateTablePeriods = [];
  }

  if (newFormat?.intialValues?.trialParameters?.calculatePowerFixedDesign === 'Unchecked') {
    newFormat.intialValues.trialParameters.calculatePowerFixedDesign = false;
  }

  if (newFormat?.intialValues?.trialParameters?.sampleSizeReestimation === 'Unchecked') {
    newFormat.intialValues.trialParameters.sampleSizeReestimation = false;
  }
  
  if (newFormat?.intialValues?.trialParameters?.equivalenceLogarithmicTransformation === 'false') {
    newFormat.intialValues.trialParameters.equivalenceLogarithmicTransformation = false;
  }
  if (newFormat?.intialValues?.trialParameters?.blindBreaking === 'Unchecked') {
    newFormat.intialValues.trialParameters.blindBreaking = false;
  }

  if (newFormat?.intialValues?.trialParameters?.calculatePowerFixedDesign) {
    newFormat.intialValues.trialParameters.type2Error = 'To be computed';
    newFormat.intialValues.trialParameters.power = 'To be computed';
  }

  if (newFormat?.intialValues?.trialParameters?.piecewiseHazardRates) {
    newFormat.intialValues.trialParameters.piecewiseHazardRates =
      +newFormat?.intialValues?.trialParameters?.piecewiseHazardRates;
  }

  if (newFormat?.intialValues?.trialDurationParameters?.isMinimumFollowUp === '') {
    newFormat.intialValues.trialDurationParameters.isMinimumFollowUp = 'Yes';
    newFormat.intialValues.trialDurationParameters.minimumFollowUpValue = 30;
  }

  // initialize table
  if (!newFormat.intialValues.trialDurationParameters.accrualRateTablePeriods) {
    newFormat.intialValues.trialDurationParameters.accrualRateTablePeriods = [];
    newFormat.fieldInfo.trialDurationParameters.accrualRateTablePeriods = [];
  }

  if (
    Array.isArray(formValues?.trialDurationParameters?.accrualRateTablePeriods) &&
    formValues?.trialDurationParameters?.accrualRateTablePeriods.length > 0
  ) {
    newFormat.intialValues.trialDurationParameters.accrualRateTablePeriods =
      formValues.trialDurationParameters.accrualRateTablePeriods || [];
  }

  if (newFormat.intialValues?.trialDurationParameters?.specifyExpectedDuration === '') {
    newFormat.intialValues.trialDurationParameters.specifyExpectedDuration = {
      ...DEFAULTS.specifyExpectedDuration,
    };
  }
  if (newFormat.intialValues?.trialDurationParameters?.pieceWiseRelativeAccrualRate === '') {
    newFormat.intialValues.trialDurationParameters.pieceWiseRelativeAccrualRate = {
      ...DEFAULTS.pieceWiseRelativeAccrualRate,
    };
  }

  if (newFormat.intialValues?.trialDurationParameters?.pieceWiseAccrualRate === '') {
    newFormat.intialValues.trialDurationParameters.pieceWiseAccrualRate = {...DEFAULTS.pieceWiseAccrualRate};
  }

  if (!newFormat?.intialValues?.trialParameters?.StandardDeviationForControlDIFFMEANS) {
    newFormat.intialValues.trialParameters.StandardDeviationForControlDIFFMEANS = '';
  }
  if (!newFormat?.intialValues?.trialParameters?.StandardDeviationForTreatmentDIFFMEANS) {
    newFormat.intialValues.trialParameters.StandardDeviationForTreatmentDIFFMEANS = '';
  }
  if (!newFormat?.intialValues?.trialParameters?.['totalSubjects']) {
    newFormat.intialValues.trialParameters['totalSubjects'] = '';
  }
  if (!newFormat?.intialValues?.trialParameters?.SubjectsInTreatmentGroup) {
    newFormat.intialValues.trialParameters.SubjectsInTreatmentGroup = '';
  }
  if (!newFormat?.intialValues?.trialParameters?.SubjectsInControlGroup) {
    newFormat.intialValues.trialParameters.SubjectsInControlGroup = '';
  }

  return newFormat;
}

export const desginType: any = {
  dropoutParameters: 'Dropout/Censoring Rate for Control Group',
  trialParameters: 'Trial Design Parameters',
  trialDurationParameters: 'Trial Duration/Accrual Parameters',
  ssr: 'SSR',
};

const extractInfoByFieldType = (fields: any, params?: string) =>
  fields.reduce(
    // eslint-disable-next-line no-sequences
    (nField: any, field: any) => ((nField[field.name] = params ? field[params] : {...field}), nField),
    {},
  );

const reduceFields = (newFormat: any, fields: any) => {
  const {dsgn_type, details} = fields;
  const sortedDetails = details.sort((a: any, b: any) => a.order - b.order);
  const type = mapDesginType(dsgn_type);
  const initialValue = extractInfoByFieldType(sortedDetails, 'value');
  const fieldInfo = extractInfoByFieldType(sortedDetails);
  newFormat.intialValues = {...{[type]: initialValue}, ...newFormat.intialValues};
  newFormat.fieldInfo = {...{[type]: fieldInfo}, ...newFormat.fieldInfo};

  return newFormat;
};

const sanitizeNullUndefined = (value: any) => {
  if (typeof value === 'undefined' || value === null) {
    value = '';
  }
  return value;
};

export const parseToString = (value: any) => {
  if (typeof value === 'undefined' || value === null) {
    value = '';
  }
  return String(value);
};

export const mapValues = (schemaDetails: any, values: any) => {
  return schemaDetails.map((schema: any) => {
    return schema.details && schema.details.length
      ? {...schema, details: mapValues(schema.details, values[schema.name])}
      : {...schema, value: sanitizeNullUndefined((values && values[schema.name]) ?? schema.value)};
  });
};

export function* mergeSchemaWithFormValues(schema: any, formValues: any): any {
  return yield schema.reduce((newSchema: any, schema: any) => {
    const type = mapDesginType(schema.dsgn_type);
    const details = mapValues(schema.details, formValues[type]);
    newSchema = [{dsgn_type: desginType[type], details}, ...newSchema];
    return newSchema;
  }, []);
}
