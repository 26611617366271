import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
    addSuccess: '',
    loading: false,
    error: '',
    addedFirstName:'',
    addedLastName:'',
    showBanner: {
        isActive: false,
    },
}

const addUserSlice = createSlice({
    name: 'addUser',
    initialState: initialState,
    reducers: {
        addUser: (state, action) => { 
            state.loading = true;
         },
        addUserSuccess: (state, action) => {
            state.loading = false;
            state.addSuccess = action.payload;
            state.showBanner.isActive = true;
        },
        addUserError: (state, action) =>{
            state.loading = false;
            state.error = action.payload;
        },
        resetAddUser: (state) => {
            state.addSuccess = '';
            state.error = '';
            state.loading = false;
          },
        addedUserDetails: (state, action) => {
            state.addedFirstName = action.payload.firstName;
            state.addedLastName = action.payload.lastName;
        },
        closeBanner: (state) => {
            state.showBanner.isActive = false;
        },
    },
});

export const {
    addUser,
    addUserSuccess,
    addUserError,
    resetAddUser,
    addedUserDetails,
    closeBanner
} = addUserSlice.actions;

export default addUserSlice.reducer;
