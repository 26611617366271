import {map, filter, isEqual, isEmpty} from 'lodash';

export const getDropDownParams = (configureGroupsSites: Array<any>) =>
  map(
    filter(configureGroupsSites, (groups: any) => groups.group[0] && groups.group[0].isNew),
    (groups: any) => groups.group[0].inputValue,
  );

export const formatConfigureGroupsDropdown = (configureGroupsSites: Array<any>) =>
  map(configureGroupsSites, ({group, ...rest}: any) => ({
    group: [{id: group[0]?.inputValue || group[0].id, name: group[0].name}],
    ...rest,
  }));

export const generateConfigureGroupsModalData = (data: any): any => {
  const {configureGroupsSites, ...rest} = data;
  let dropDownParams = [];
  let normalizeConfigureGroups = [];
  if (configureGroupsSites && configureGroupsSites.length) {
    dropDownParams = getDropDownParams(configureGroupsSites);
    normalizeConfigureGroups = formatConfigureGroupsDropdown(configureGroupsSites);
  }

  return {formValues: {...rest, configureGroupsSites: normalizeConfigureGroups}, dropDownParams};
};

export const mapDesginType = (type: any): string => {
  if (type === 'create new model') {
    return 'createNewModel';
  } else if (type === 'median recruitment rates') {
    return 'medianRecruitmentRates';
  } else if (type === 'average site duration') {
    return 'averageSiteDuration';
  } else if (type === 'calculateStudyDuration') {
    return 'calculateStudyDuration';
  } else if(type === 'median recruitment rates for TTE') {
    return  'medianRecruitmentRatesTTE'
  } else if(type === 'calculateStudyDuration for TTE') {
    return  'calculateStudyDurationTTE'
  } else if(type === 'event hazard rates for TTE') {
    return  'eventHazardRatesTTE'
  } 
  else if(type === 'event hazard rates for TTE piecewise') {
    return 'eventHazardRatesTTEPiecewise'
  }
  return '';
};

export const mapDefaultValues = (form: any[]) => {
  const defaults = form.reduce((acc, el) => {
    const dsgnType = mapDesginType(el.dsgn_type);
    if (!acc[dsgnType]) {
      const data = getFormObject(el?.details);
      return {...acc, [dsgnType]: data};
    }
    return {};
  }, {});

  return defaults;
};

export const normalizeFields = (fields: Array<any>): any => {
  return fields.reduce((acc, el) => {
    return {
      ...acc,
      [el.name]: el.details && el.details.length ? normalizeFields(el.details) : el?.value,
    };
  }, {});
};

export const getFormObject = (desgnFields: any[]) => {
  return Array.isArray(desgnFields) ? normalizeFields(desgnFields) : {};
};

export function* extractFields(data: any, formValues?: any): any {
  const parseData = parseIntValueByNumberType(data);
  let newFormat = yield parseData.reduce(reduceFields, {});
  return newFormat;
}

export const desginType: any = {
  createNewModel: 'create new model',
  medianRecruitmentRates: 'median recruitment rates',
  averageSiteDuration: 'average site duration',
  calculateStudyDuration: 'calculateStudyDuration',
  medianRecruitmentRatesTTE:'median recruitment rates for TTE',
  calculateStudyDurationTTE:'calculateStudyDuration for TTE',
  eventHazardRatesTTE: 'event hazard rates for TTE',
  eventHazardRatesTTEPiecewise: 'event hazard rates for TTE piecewise'

};

export const extractInfoByFieldType = (fields: any, params?: string) =>
  fields?.reduce(
    // eslint-disable-next-line no-sequences
    (nField: any, field: any) => {
      return (
        (nField[field.name === 'Configure Groups' ? 'ConfigureGroups' : field.name] = params
          ? field.details && field.details.length
            ? extractInfoByFieldType(field.details, params)
            : field[params]
          : field.details && field.details.length
          ? extractInfoByFieldType(field.details)
          : {...field}),
        nField
      );
    },
    {},
  );

export const reduceFields = (newFormat: any, fields: any) => {
  const {dsgn_type, details} = fields;
  const sortedDetails = details?.sort((a: any, b: any) => a.order - b.order);
  const type = mapDesginType(dsgn_type);
  const initialValue = extractInfoByFieldType(sortedDetails, 'value');
  const fieldInfo = extractInfoByFieldType(sortedDetails);
  newFormat.intialValues = {...{[type]: initialValue}, ...newFormat.intialValues};
  newFormat.fieldInfo = {...{[type]: fieldInfo}, ...newFormat.fieldInfo};

  return newFormat;
};

export const sanitizeNullUndefined = (value: any) => {
  if (typeof value === 'undefined' || value === null) {
    value = '';
  }
  return value;
};

export const parseToString = (value: any) => {
  if (typeof value === 'undefined' || value === null) {
    value = '';
  }
  return String(value);
};

// To extract Form values from nesed Formik Schema OR fallback to Default values.
export const extractFieldValue = (formValue: any, schemaDefaultVal: any) => {
  return (typeof formValue === 'undefined' || formValue === null) ? schemaDefaultVal : formValue;
};

export const mapValues = (schemaDetails: any, values: any) => {
  return schemaDetails.map((schema: any) => {
    return schema.details && schema.details.length
      ? {...schema, details: mapValues(schema.details, values[schema.name])}
      // : {...schema, value: sanitizeNullUndefined((values && values[schema.name]) || schema.value)}; // TODO: Remove this line.
      : {...schema, value: sanitizeNullUndefined(extractFieldValue(values[schema.name], schema.value))};
  });
};

export function* mergeSchemaWithFormValues(schema: any, formValues: any): any {
  try {
    return yield schema.reduce((newSchema: any, schema: any) => {
      const type = mapDesginType(schema.dsgn_type);
      if (type) {
        const details = mapValues(schema.details, formValues[type] || schema.details);
        newSchema = [{dsgn_type: desginType[type], details}, ...newSchema];
      }
      return newSchema;
    }, []);
  } catch (e) {
    console.log('e', e);
  }
}

const parseIntValueByNumberType = (data: any) => {
  return data.map((item1: any) => {
    item1.details = item1.details?.map((item2: any) => {
      if (item2.type === 'number') {
        item2.value = item2.value && typeof item2.value === 'string' ? Number(item2.value) : item2.value;
        return item2;
      }

      return item2;
    });

    return item1;
  });
};

export const hasCalculatedResults = (output: Array<any>) => output && output.length && output[0].output;

const mapVariable: any = {
  patientAccrualData: {title: 'Subjects Accrual and Endpoints Observed'},
  sitesOpen: {title: 'Expected Sites to Open'},
};

const processTableData = (data: any) => {
  const columns = Object.keys(data[0]);
  return {columns, rows: data};
};

export const processOutput = (output: Array<any>, mergedOutput: Array<any>) => {
  try {
   const mergedPatientEventdata= getChartDataHandler(output, mergedOutput)
   const modifedPayload = filter(output, (data) => Object.keys(data)[0] !== 'eventsData'&& Object.keys(data)[0] !== 'interimTimesData' && Object.keys(data)[0] !== 'simulationsData' );
    return modifedPayload?.map((data: any) => {
      const variableKey: any = Object.keys(data)[0];
      data = variableKey==="patientAccrualData" ? mergedPatientEventdata.patientData :data
      const tableData = processTableData(variableKey==="patientAccrualData"? data:data[variableKey]);
      return {
        key: variableKey,
        chartData: { title: mapVariable[variableKey].title, data: [...(variableKey==="patientAccrualData" ? mergedPatientEventdata[variableKey as 'patientAccrualData' ] : data[variableKey])],interimTimesData:output[3]["interimTimesData"]},        tableData,
        interimTableData:(output[3]["interimTimesData"])
      };
    });
  } catch (e) {
    console.log('e', e);
  }
};
const getChartDataHandler = (output: any,mergedOutput?: any) => {
  if(mergedOutput && Object.keys(mergedOutput).length){
    const simulationsData= mergedOutput?.simulations 
    const mergedEventPatient  = mergedOutput?.recruitmentAndEvents


  //Merge simulation data into Patient Accrual and Events data
  const simulationLinesData= simulationsData && mergedEventPatient?.length ? mergedEventPatient.map((o:any, i:any) => {
    const simulate = {} as {[key:string]:any}
    simulationsData.forEach((ob:any, index:any) => {
      simulate[`eventsPoints${index}` ] = ob.events.yEvents[i];
      simulate[`recruitmentPoints${index}`] = ob.recruitment.y[i];
      return simulate;
    });
    return { ...o, ...simulate };
  })
  : []
  return {patientAccrualData: simulationsData ? simulationLinesData : mergedEventPatient,patientData:mergedEventPatient}
  }
  else{
    const simulationsData=output && output[4]?.simulationsData;
    let mergedEventPatient = output&& output[1]?.eventsData.map(({x, upperci:upperEventci,lowerci:lowerEventci,y:yEvents}: {
      x:any,
      y: any;
      upperci: any;
      lowerci: any;
    
      }, i:any)=>({x,...(output[0]?.patientAccrualData[i] || {y:null,upperci:null,lowerci:null}),yEvents,upperEventci,lowerEventci,})
    ).filter((elem:any)=>elem["x"])

    //Merge simulation data into Patient Accrual and Events data
    let simulationLinesData= simulationsData && mergedEventPatient.map((elem:any)=>{
      return simulationsData.map((sim:any, i:number)=>{
          return {[`eventsPoints${i}`]:sim.eventsPoints[elem.x]? sim.eventsPoints[elem.x].y : null,
          [`recruitmentPoints${i}`]:sim.recruitmentPoints[elem.x]? sim.recruitmentPoints[elem.x].y : null}
      })
    }).map((ele:any)=>(ele.reduce((acc:any, elemnt:any,)=>{
      return {...acc, ...elemnt} 
    },{})));

    const mergedSimulationData = simulationLinesData && mergedEventPatient.map((elem:any,i:number)=>{
      return {...elem, ...simulationLinesData[i]}
    })
    return {patientAccrualData: simulationsData ? mergedSimulationData : mergedEventPatient,patientData:mergedEventPatient}
  }

}
/**
 * Function which compares the existing input 
 * calculate study duration object with the lates 
 * calculate study duration object formed from the results
 * iterate over each property to convert the fields into 
 * number for equality check
 * @param input 
 * @param existingCalculateStudyDuration 
 * @returns 
 */
export const checkIfResultsChanged = (input:any,existingCalculateStudyDuration:any) => {
  let inputCalculateStudyDuration:any = {};
  let calculateStudyDurationDefault:any ={};
  const {calculateStudyDuration} = input;
  for(let key in calculateStudyDuration) {
    inputCalculateStudyDuration[key] =  Array.isArray(calculateStudyDuration[key]) ? calculateStudyDuration[key] : +calculateStudyDuration[key]
  }
  for(let key in existingCalculateStudyDuration) {
    calculateStudyDurationDefault[key] =  Array.isArray(existingCalculateStudyDuration[key]) ? existingCalculateStudyDuration[key] : +existingCalculateStudyDuration[key]
  }
  return !isEqual(inputCalculateStudyDuration,calculateStudyDurationDefault)
}

export const updateCalculateDurationFormValues = (updatedCalculateDurationValues:any,values:any) => {
  return {...values,calculateStudyDuration:{
    ...(Object.keys(updatedCalculateDurationValues).length === 0 ? values.calculateStudyDuration : updatedCalculateDurationValues)
  }}
}

/**
 * Function which check if the GS simulation has RCode error. There are two type of error
 * Type1: Error inside Rcode execution
 * Type2: Error at ECS level like timoeout etc. 
 * For now, we know the following types of outputerrors: messageFromGS, messageFromFixed, messageFromUnknown
 * @param error 
 * @param output 
 * @returns 
 */
export const checkResultRcodeError = (error = '', output = {} as any) => {
  const {
    messageFromGS = [],
    messageFromFixed = [],
    messageFromUnknown = []
  } = output;

  return (!isEmpty(error) || !isEmpty(messageFromGS) || !isEmpty(messageFromFixed) || !isEmpty(messageFromUnknown));
};