import * as Yup from 'yup';
import { CONSTANTS } from '../../../common/constants';

function schema() {
    return Yup.object().shape({
        name: Yup.string()
            .max(199, CONSTANTS.VALIDATION_MESSAGES.TOO_LONG)
            .required(CONSTANTS.VALIDATION_MESSAGES.REQUIRED),
        engagementType: Yup.string()
            .required(CONSTANTS.VALIDATION_MESSAGES.REQUIRED),
        projectCode: Yup.string()
            .when('engagementType', {
                is: '1',
                then: Yup.string()
                    .required(CONSTANTS.VALIDATION_MESSAGES.REQUIRED)
                    .max(200, CONSTANTS.VALIDATION_MESSAGES.TOO_LONG),
            })
            .when('engagementType', {
                is: '2',
                then: Yup.string()
                    .optional()
                    .max(200, CONSTANTS.VALIDATION_MESSAGES.TOO_LONG),
            })
            .nullable(),
        objective: Yup.string()
            .required(CONSTANTS.VALIDATION_MESSAGES.REQUIRED),
        sponsors: Yup.array()
            .required(CONSTANTS.VALIDATION_MESSAGES.REQUIRED),
        compounds: Yup.string()
            .max(199, CONSTANTS.VALIDATION_MESSAGES.TOO_LONG),
            opportunityNumber: Yup.string()
            .max(119, CONSTANTS.VALIDATION_MESSAGES.OPPORTUNITY_NUMBER_TOO_LONG)
    })
}

export { schema }