import {stringify} from 'qs';
import moment from 'moment';

export const transformFilters = (filters: any): string => {
  filters = JSON.parse(
    JSON.stringify(filters, (key, val) => {
      if (Array.isArray(val)) {
        return val.filter((entry) => !!entry).length !== 0 ? extractArrayValues(val) : undefined;
      } else if (val) {
        return typeof val === 'string' ? val.trim() : val;
      } else return undefined;
    }),
  );
  return stringify(filters);
};


// This filter is specific to Datepicker ranges. Value is a moment object, convert it to mm/dd/yyyy format.
// if the Range is between 03-Nov to 11-Nov then query should search from '11/03/2021 00:00:00' to '11/11/2021 23:59:59'
// TODO: Add a check to see if values are actually Date values.
export const extractArrayValues = (data: Array<any>, format = 'MM/DD/YYYY HH:mm:ss') => {
  return data.map((ele: any, index: number) => {
    if(moment.isMoment(ele)) {
      // refers to the endDate of the range.
      return index === 1 ? ele.endOf('day').format(format) : ele.format(format);
    } else {
      return ele;
    }
  });
};