import { call, put, takeEvery,select } from 'redux-saga/effects';
import { callApi } from '../../../services/api';
import { ENDPOINTS } from '../../../services/endpoint';
import { PayloadAction } from '@reduxjs/toolkit';

import {
  updateProjectDetail,
  getProjectDetail as getProjectDetailAction,
  updateAllUsers,
  getAllUsers as getAllUsersAction,
  addProjectMember as addProjectMemberAction,
  addProjectMemberFailure,
  getProjectMembers,
  updateProjectMembers,
  deleteProjectMember,
  deleteProjectMemberError,
  getProjectResults,
  getProjectResultsSuccess,
  getProjectResultsFailure,
  terminateSimulationRequest,
  terminateSimulationSuccess,
  terminateSimulationFailure,
  deleteProjectSession,
  deleteProjectSessionFailure,
  publishProjectSession,
  publishProjectSessionFailure,
  fetchSessionMembers,
  shareSessionListSuccess,
  shareSessionListFailure,
  fetchApplySelection,
  fetchOption,
  fetchOptionSuccess,
  fetchOptionFailure
} from '../slices/projectDetailSlice';
import { setSessionResultLoading } from '../slices/SessionResultSlice';
import {openBanner} from '../slices/BannerSlice';
import history from '../../../common/utils/history';
import { CONSTANTS } from '../../../common/constants/index';
import { RootState } from '../../../app/store';

export function* watchGetProjectDetail() {
  yield takeEvery(getProjectDetailAction, getProjectDetail);
}

export function* getProjectDetail({ payload }: any) {
  const url = `${ENDPOINTS.GET_PROJECT_DETAIL}?id=${payload}`;
  try {
    const { data } = yield call(callApi, url);
    if (data) {
      yield put(updateProjectDetail(data));
      yield sessionStorage.setItem(
        'projectDetail',
        JSON.stringify({
          projectId: data.id,
          projectName: data.name,
          objId: data.objID,
          ownerId: data.ownerID
        })
      );
    }
  } catch (e) {
    yield put(updateProjectDetail({}));
  }
}

export function* watchGetAllUsers() {
  yield takeEvery(getAllUsersAction, getAllUsers);
}
export function* getAllUsers({ payload }: any) {
  const url = `${ENDPOINTS.GET_ALL_USERS}?tenantId=${payload}`;
  try {
    const { data } = yield call(callApi, url);
    if (data) {
      yield put(updateAllUsers(data));
    }
  } catch (e) {
    yield put(updateAllUsers([]));
  }
}

export function* watchAddProjectMember() {
  yield takeEvery(addProjectMemberAction, addProjectMemberSaga);
}

export function* addProjectMemberSaga({ payload }: any) {
  try {
    const url = `${ENDPOINTS.ADD_PROJECT_USER}?tenantId=${payload.tenantId}`;
    const body = {
      projectId: payload.projectId,
      ownerId: payload.ownerId,
      userIds: payload.userIds,
    };
    const { data } = yield call(callApi, url, {
      method: 'POST',
      mode: 'cors',
      body: JSON.stringify(body),
    });
    if (data) {
      yield put(updateProjectMembers(data));
    }
  } catch (e : any) {
    yield put(addProjectMemberFailure(e?.error));
  }
}

export function* watchGetAllProjectMembers() {
  yield takeEvery(getProjectMembers, getProjectMembersSaga);
}

export function* getProjectMembersSaga(
  action: PayloadAction<{ tenantId: string; projectId: string }>
) {
  const { tenantId, projectId } = action.payload;
  const url = `${ENDPOINTS.GET_ALL_PROJECT_USERS}?tenantId=${tenantId}&projectId=${projectId}`;
  try {
    const { data } = yield call(callApi, url);
    if (data) {
      yield put(updateProjectMembers(data));
    } else {
      yield put(updateProjectMembers([]));
    }
  } catch (e) {
    yield put(updateProjectMembers([]));
  }
}

export function* watchDeleteProjectMember() {
  yield takeEvery(deleteProjectMember, deleteProjectMemberSaga);
}

export function* deleteProjectMemberSaga(
  action: PayloadAction<{ tenantId: string; projectId: string; userId: string }>
) {
  try {
    const { tenantId, projectId, userId } = action.payload;
    const url = `${ENDPOINTS.DELETE_PROJECT_USER}?tenantId=${tenantId}&projectId=${projectId}&userId=${userId}`;
    const { data } = yield call(callApi, url, {
      method: 'DELETE',
      mode: 'cors',
    });
    if (data) {
      yield put(updateProjectMembers(data));
    }
  } catch (e : any) {
    yield put(deleteProjectMemberError(e?.error));
  }
}

export function* applyMembersSelection(
  action:any
) {
  try {
    const {tenantId, sessionId, value} = action.payload;
    let param = {
      sessionid:sessionId,
      userids:value
    }
    const url = `${ENDPOINTS.GET_SHARED_SESSION}?tenantId=${tenantId}`;
    const {message} = yield call(callApi, url,{
      method: 'POST',
      mode: 'cors',
      body: JSON.stringify(param),
    });
    if (message==="Successful") {
      yield put(
        openBanner({
          message: `Session has been successfully shared.`,
          bannerType: 'success',
          autoClose:true
        }),
      );
    } else {
      yield put(
        openBanner({
          message: `Sorry!! something went wrong while sharing`,
          bannerType: 'error',
          autoClose:true
        }),
      );
    }
  } catch (error : any) {
    yield put(
        openBanner({
          message: `Sorry!! something went wrong while sharing. ${error?.error}`,
          bannerType: 'error',
          autoClose:true
        }),
      );
  }
}
export function* watchGetProjectResults() {
  yield takeEvery(terminateSimulationRequest, terminateSimulationSaga);
  yield takeEvery(getProjectResults, getProjectResultsSaga);
  yield takeEvery(deleteProjectSession,deleteSessionSaga);
  yield takeEvery(publishProjectSession,publishSessionSaga);
  yield takeEvery(fetchSessionMembers, shareSessionSaga);
  yield takeEvery(fetchApplySelection, applyMembersSelection);
  yield takeEvery(fetchOption,getSessionOptionSaga)
}

export function* getProjectResultsSaga(
  action: PayloadAction<{
    tenantId: string;
    projectId: string;
    userId: string;
    page: number;
    limit: number;
    sortedColumn: string;
    sortOrder: string;
    filters: string;
    tab?:string;
  }>,
) {
  try {
    const {tenantId, projectId, userId} = action.payload;
    const {
      page = 1,
      limit = 10,
      sortedColumn = 'lastModifiedDate',
      sortOrder = 'desc',
      filters = '',
      tab = 'sessions'
    } = action.payload;

    let url = '';
    const objID:any =sessionStorage.getItem('objID')
    if((+objID === 1 || +objID === 2) && tab === 'sessions') {
      url = `${ENDPOINTS.GET_PROJECT_RESULTS_NEW}?tenantid=${tenantId}&projectid=${projectId}&userid=${userId}&page=${page}&limit=${limit}&sortby=${sortedColumn}&order=${sortOrder}`;
    } 
    else  {
      url = `${ENDPOINTS.GET_PROJECT_RESULTS}?tenantid=${tenantId}&projectid=${projectId}&userid=${userId}&page=${page}&limit=${limit}&sortby=${sortedColumn}&order=${sortOrder}`;
    }
    if (filters) {
      url = `${url}&${filters}`;
    }
    const {data} = yield call(callApi, url);
    if (data) {
      yield put(getProjectResultsSuccess(data));
    }
  } catch (e:any) {
    yield put(getProjectResultsFailure(e?.error))
  }
}

export function* terminateSimulationSaga(
  action: PayloadAction<{
    sessionName: string;
    tenantid: any;
    projectid: any;
    projectName: string;
    sessionid: string;
    userid: string;
  }>,
) {
  try {
    const socketIdentifier = sessionStorage.getItem('socketIdentifier') as string;
    const {sessionid, sessionName, userid, projectid, projectName, tenantid} = action.payload;
    let param = {
      sessionId: sessionid,
      tenantId: tenantid,
      socketIdentifier,
    };
    
    const url = `${ENDPOINTS.TERMINATE_SIMULATION}?tenantid=${tenantid}`;
    const data = yield call(callApi, url, {
      method: 'POST',
      mode: 'cors',
      body: JSON.stringify(param),
    });
    if (data?.success) {
      // successfully terminated
      yield put(terminateSimulationSuccess({sessionid: sessionid}));
      yield put(
        openBanner({
          message: CONSTANTS.INFORMATIONAL_MESSAGES.SIMULATION_TERMINATION_SUCCESS(sessionName, projectName),
          bannerType: 'success',
        }),
      ); 
    } else if (data?.error){
      // unable to terminate as R code already executed
      yield put(
        openBanner({
          message: data?.error ||'Sorry!! something went wrong while terminating.',
          bannerType: 'error' ,
        }),
      );
    }

    // If you are in the results page of the same session, move to project sessions page.
    if (window.location.pathname.includes(`/projects/output/${sessionid}`)) {
      yield put(setSessionResultLoading({isLoading: false}));
      yield call(forwardTo, `/projects/detail/${projectid}`);
    } else if (window.location.pathname.includes(`/projects/detail/${projectid}`)) {
      yield put(
        getProjectResults({
          tenantId: tenantid,
          projectId: projectid,
          userId: userid,
        }),
      );
    }
  } catch (e : any) {
    yield put(terminateSimulationFailure(e?.error));
    yield put(
        openBanner({
          message: 'Sorry!! something went wrong while terminating.',
          bannerType: 'error'
        }),
      );
  }
}

export function* deleteSessionSaga(
  action: PayloadAction<{
    sessionName: string;
    tenantid: string;
    projectid: string;
    sessionid: string;
    userid: string;
    projSessionId:string;
  }>,
) {
  try {
    const {tenantid, sessionid, userid, projectid, projSessionId,sessionName} = action.payload;
    const url = `${ENDPOINTS.DELETE_PROJECT_SESSION}?sessionid=${sessionid}&projectid=${projectid}&tenantid=${tenantid}`;
    const {message} = yield call(callApi, url, {
      method: 'DELETE',
      mode: 'cors',
    });
    if (message === CONSTANTS.API_MESSAGES.SUCCESS) {
      yield put(
        openBanner({
          message: CONSTANTS.INFORMATIONAL_MESSAGES.DELETE_SESSION_SUCCESS(projSessionId,sessionName),
          bannerType: 'success',
          autoClose:true
        }),
      );
        yield put(
          getProjectResults({
            tenantId: tenantid,
            projectId: projectid,
            userId: userid,
          }),
        );
    }
  } catch (error:any) {
    yield put(deleteProjectSessionFailure())
    yield put(
        openBanner({
          message: `Sorry!! something went wrong while deleting. ${error?.error}`,
          bannerType: 'error',
          autoClose:true
        }),
      );
  }
}
export function* shareSessionSaga(
  action: PayloadAction<{
    tenantId: string;
    sessionId: string;
    id:string;
  }>,
) {
  try {
    const {tenantId, sessionId, id} = action.payload;
    const url = `${ENDPOINTS.GET_ALL_PROJECT_USERS}?tenantId=${tenantId}&projectId=${id}&members=sessionusers&sessionid=${sessionId}`;
    const {data} = yield call(callApi, url);
    if (data) {
      yield put(shareSessionListSuccess(data));
    } else {
      yield put(shareSessionListSuccess([]));
    }
  } catch (error:any) {
    yield put(shareSessionListFailure())
    yield put(
        openBanner({
          message: `Sorry!! something went wrong while sharing. ${error?.error}`,
          bannerType: 'error',
          autoClose:true
        }),
      );
  }
}

export function* publishSessionSaga(
  action: PayloadAction<{
    sessionName: string;
    tenantid: string;
    projectid: string;
    sessionid: string;
    userid: string;
    projSessionId:string;
  }>,
) {
  try {
    const {tenantid, sessionid, userid, projectid, projSessionId,sessionName} = action.payload;
    const requestBody = {
      projectId: projectid,
      sessionId:sessionid
    };
    const url = `${ENDPOINTS.PUBLISH_PROJECT_SESSION}?tenantid=${tenantid}`;
    const {message} = yield call(callApi, url, {
      method: 'PUT',
      mode: 'cors',
      body: JSON.stringify(requestBody)
    });
    if (message === CONSTANTS.API_MESSAGES.SUCCESS) {
      yield put(
        openBanner({
          message: CONSTANTS.INFORMATIONAL_MESSAGES.PUBLISH_SESSION_SUCCESS(projSessionId,sessionName),
          bannerType: 'success',
          autoClose:true
        }),
      );
        yield put(
          getProjectResults({
            tenantId: tenantid,
            projectId: projectid,
            userId: userid,
          }),
        );
    }
  } catch (error:any) {
    yield put(publishProjectSessionFailure())
    yield put(
        openBanner({
          message: `Sorry!! something went wrong while publishing. ${error?.error}`,
          bannerType: 'error',
          autoClose:true
        }),
      );
  }
}

export function forwardTo(location: string) {
  history.push(location);
}


export function* getSessionOptionSaga(
  action: PayloadAction<{
    tenantId: number;
    projectId: number;
    sessionId:any;
  }>,
)
{
  try {
    const {tenantId, projectId, sessionId} = action.payload;
    let projectSessions = yield select((state:RootState)=> state.projectDetail.projectResults.projectSessions);
    let url = `${ENDPOINTS.GET_OPTION_DATA}?tenantid=${tenantId}&projectId=${projectId}&sessionId=${sessionId}`;
    const {data} = yield call(callApi, url);
    if (data) {
      let projSessionsCopy = [...projectSessions]
      let keys  = Object.keys(data);
      const matchingSessions = keys.map((key)=> {
        return projSessionsCopy.find(({sessionId}:any)=> +sessionId === +key)
      });
      const matchingSessionsIndex = keys.map((key)=> {
        return projSessionsCopy.findIndex(({sessionId}:any)=> +sessionId === +key)
      });
      const newMatchingSessions = matchingSessions.map((session:any)=> ({...session,sessionDetails:data[session?.sessionId?.toString()]}))
      for(let i of matchingSessionsIndex) {
        let sessionId = projSessionsCopy[i]?.sessionId;
        let matchingSession = newMatchingSessions.find((session:any)=> +session.sessionId === +sessionId)
        projSessionsCopy[i] = {...matchingSession}
      }
      yield put(fetchOptionSuccess({data:projSessionsCopy}));
    }
  } catch (e:any) {
    console.log(e)
    yield put(fetchOptionFailure(e?.error));
  }

}

