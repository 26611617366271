import { combineReducers } from '@reduxjs/toolkit';
import createProjectSlice from '../features/projects/slices/CreateProjectSlice';
import ProjectListReducer from '../features/projects/slices/TableSlice';
import projectDetailReducer from '../features/projects/slices/projectDetailSlice';
import designOptionsReducer from '../features/projects/slices/DesignOptionSlice';
import toxiCityCurveReducer from '../features/projects/slices/ToxicityCurveSlice';
import user from '../features/projects/slices/userDetailsSlice';
import trialParamReducer from '../features/projects/slices/TrialParamSlice';
import launchSimulationReducer from '../features/projects/slices/LaunchSimulationSlice';
import sessionResultReducer from '../features/projects/slices/SessionResultSlice';
import ProjectCommentReducer from '../features/projects/slices/projectCommentSlice';
import UserManagementReducer from '../features/userManagement/slices/UserManagementTableSlice';
import TenantManagementReducer from '../features/tenantManagement/slices/TenantManagementTableSlice';
import saveSessionReducer from '../features/projects/slices/saveSessionSlice';
import addUserReducer from '../features/userManagement/slices/AddUserSlice';
import addTenantReducer from '../features/tenantManagement/slices/AddTenantSlice';
import redirectAuthenticationReducer from '../features/loginPage/slices/RedirectPageSlice';
import customDesignRedcuers from '../features/projects/slices/customDesignSlice';
import BannerReducers from '../features/projects/slices/BannerSlice';
import projectDocumentReducer from '../features/projects/slices/projectDocumentsSlice';
import groupSequentialDesignReducers from '../features/projects/slices/groupSequentialDesignSlice';
import flowChartDecisionTableReducer from '../features/projects/slices/FlowChartDecisionTableSlice';
import defaultTenantSelectReducer from '../features/loginPage/slices/DefaultTenantSlice';
import submitFeedbackReducer from '../features/projects/slices/SubmitFeedbackSlice';
import authInfo from '../features/loginPage/slices';
import integrationManagementReducer from '../features/integrationManagement/slices/IntegrationManagementSlice';
import efficacyDecisionTable from '../features/projects/slices/EfficacyDecisionTableSlice';
import trainingVideoReducer from '../features/projects/slices/TrainingVideoSlice';

const rootReducer = combineReducers({
  createProjectSlice,
  user,
  authInfo,
  projects: ProjectListReducer,
  projectDetail: projectDetailReducer,
  designOptionsList: designOptionsReducer,
  toxiCityCurve: toxiCityCurveReducer,
  trialParam: trialParamReducer,
  launchSimulation: launchSimulationReducer,
  sessionResult: sessionResultReducer,
  projectComment: ProjectCommentReducer,
  userManagement: UserManagementReducer,
  tenantManagement: TenantManagementReducer,
  saveSession: saveSessionReducer,
  addUser: addUserReducer,
  addTenant: addTenantReducer,
  redirectAuthentication: redirectAuthenticationReducer,
  customDesign: customDesignRedcuers,
  groupSequentialDesign: groupSequentialDesignReducers,
  banner: BannerReducers,
  documents: projectDocumentReducer,
  flowChartDecisionTableReducer,
  defaultTenantSelect: defaultTenantSelectReducer,
  submitFeedback: submitFeedbackReducer,
  integrationManagement: integrationManagementReducer,
  efficacyDecisionTable,
  trainingVideo: trainingVideoReducer
});

export default rootReducer;
