import {createSlice, PayloadAction} from '@reduxjs/toolkit';

type InitialState = {
  loading: boolean;
  data: any;
  error: any;
  editedRow:any;
};

export const initialState: InitialState = {
  loading: false,
  data: {},
  error: {},
  editedRow:{}
};

export const EfficacyDecisionTableSlice = createSlice({
  name: 'EfficacyDecisionTableSlice',
  initialState: initialState,
  reducers: {
    fetchEfficacyDecisionTable: (state,action) => {
      state.loading = true;
    },
    fetchEfficacyDecisionTableSuccess: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.data = action.payload;
    },
    fetchEfficacyDecisionTableFailure: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.error = action.payload;
    },
    resetSlice:(state) => {
      state.data ={};
      state.editedRow = {};
    },
    setEditRow:(state,action) => {
      state.editedRow = action.payload;
    }
  },
});

export const {
  fetchEfficacyDecisionTable,
  fetchEfficacyDecisionTableSuccess,
  fetchEfficacyDecisionTableFailure,
  resetSlice,
  setEditRow
} = EfficacyDecisionTableSlice.actions;
export default EfficacyDecisionTableSlice.reducer;
