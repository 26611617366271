import React from 'react';
import ProjectTabs from '../Tabs';
import styles from './ProjectHome.module.scss';
import Paper from 'apollo-react/components/Paper';
import Grid from 'apollo-react/components/Grid';
import { shadowLevel1 } from 'apollo-react/shadows';
import { neutral3 } from 'apollo-react/colors';

const paperStyles = {
  boxShadow: shadowLevel1,
  border: `1px solid ${neutral3}`,
};

function ProjectsHome() {
  return (
    <Grid data-testid="projectsHome" container>
      <Paper style={paperStyles} className={styles.projectContainer}>
        <ProjectTabs />
      </Paper>
    </Grid>
  );
}

export default ProjectsHome;
