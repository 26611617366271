import {createSlice, PayloadAction} from '@reduxjs/toolkit';

type INTIAL_STATE = {
  isUserLoggedIn: boolean;
  isLoading: boolean;
  error: string;
  isLoggingOut: boolean;
  isLogoutButtonClicked: boolean;
};

export const initialState: INTIAL_STATE = {
  isUserLoggedIn: false,
  isLoading: false,
  error: '',
  isLoggingOut: false,
  isLogoutButtonClicked: false,
};

export const authenticationSlice = createSlice({
  name: 'authentication',
  initialState: initialState,
  reducers: {
    logOutRequest: (state: INTIAL_STATE, action: PayloadAction<void>) => {
      state.isLoading = true;
    },
    setIsLogginOut: (state:INTIAL_STATE, action: PayloadAction<any>) => {
      state.isLoggingOut = action.payload;
    },
    logOutSuccess: (state:INTIAL_STATE) => {
      state.isLoading = false;
      state.isUserLoggedIn = false;
      state.isLogoutButtonClicked = false;
    },
    logOutFailure: (state:INTIAL_STATE, action) => {
      state.isLoading = false;
      state.isUserLoggedIn = false;
      state.error = action.payload || 'Login Failed';
    },
    setIsLogoutButtonClicked: (state:INTIAL_STATE, action) => {
      state.isLogoutButtonClicked = action.payload;
    },
  },
});

export const {logOutRequest, logOutSuccess, logOutFailure, setIsLogginOut, setIsLogoutButtonClicked} = authenticationSlice.actions;

export default authenticationSlice.reducer;
