import {createSlice} from '@reduxjs/toolkit';

export const initialState = {
  feedbackId: '',
  loading: false,
  error: '',
};

const submitFeedbackSlice = createSlice({
  name: 'submitFeedback',
  initialState: initialState,
  reducers: {
    submitFeedback: (state, action) => {
      state.loading = true;
    },
    submitFeedbackSuccess: (state, action) => {
      state.loading = false;
      state.feedbackId = action.payload;
    },
    submitFeedbackError: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    resetSubmitFeedback: (state) => {
      state.loading = false;
      state.feedbackId = '';
      state.error= '';
    },
  },
});

export const {submitFeedback, submitFeedbackSuccess, submitFeedbackError, resetSubmitFeedback} = submitFeedbackSlice.actions;

export default submitFeedbackSlice.reducer;
