export const fields = [
  {
    name: 'maxSubjectSize',
    label: 'Max Subject Size',
    disabled: true,
    type: 'text',
  },
  {
    name: 'maxDoseLevel',
    label: 'Max Dose Levels',
    disabled: true,
    type: 'text',
  },
  {
    name: 'doseAmounts',
    label: 'Dose Amount',
    disabled: true,
    type: 'text',
  },
  {
    name: 'startingDoseLevel',
    label: 'Starting Dose Level',
    disabled: false,
    type: 'number',
  },
  {
    name: 'cohort',
    label: 'Cohort Treated',
    disabled: false,
    type: 'number',
  },
  {
    name: 'subjects',
    label: '# Subjects in Cohort',
    disabled: false,
    type: 'number',
    isDependent: false,
  },
  {
    name: 'toxicityCount',
    label: '# Subjects with Toxicity',
    disabled: false,
    type: 'number',
    isDependent: true,
  },
  {
    name: 'effectivenessCount',
    label: '# Subjects with Effectiveness',
    disabled: false,
    type: 'number',
    isDependent: true,
  },
];
export const LEGEND_LABELS={
  DECICISION:'Decision',
  DE_ESCALATE:'D = De-escalate',
  ESCALATE:'E = Escalate',
  STAY:'S = Stay at the Current Dose Level',
  SAFTEY:'Safety Violation',
  CONTINUE:'0 = Continue trial',
  STOP:'1 = Current and Higher Doses Unavailable',
  EFFECTIVENESS:'Effectiveness Violation',
  EFF_CONTINUE:'0 = Continue trial',
  EFF_STOP:'1 = Current Dose Unavailable'
}
export const EFFICACY_DT_LABELS = {
  GO_BACK:'Back to Results Comparison',
  DESIGN_OPTION:'Design Option',
  EFFICACY: 'Effectiveness/Surrogate',
  OWNER_MESSAGE:'Available only for session owners',
  GENERATE:'Generate Decision Table',
  RESTART:'Restart',
  DT:'Decision Table',
  INFO_MESSAGE:'Specify below the observed toxicity and effectiveness during an ongoing trial to generate a decision path for each cohort.',
  INTO_TITLE:'Decision Table Inputs',
  PLACEHOLDER:'Enter Value',
  WARNING:'This action will permanently remove existing decision table and graphical depiction path generated below. Confirm to restart.',
  DOSE_AMOUNTS:'Dose Amounts',
  NO_IMAGE:'No Decision Table Generated',
  DELETE:'Warning',
  EDIT:'Edit Decision Table Row',
  MAX_SUBJECT_SIZE:'Max Subject Size',
  SAVE:'Save',
  CANCEL:'Cancel',
  COHORT:'Cohort',
  DELETE_LABEL:'Delete'
}
export const EFFICACY_DT_SESSION_STORAGE_KEY = 'EfficacyDT'

export const EFFICACY_DT_KEYS = ['maxSubjectSize','starting','minAcceptableProb','ExcessToxProbCutoff','insufficientProbPrintE','e1','e2']

export const COLUMNS = (unit:string,DecisionCell:Function,ActionCell:Function) =>[
  {
    header: 'Cohort',
    accessor: 'cohort',
  },
  {
    header: '#Subjects in Cohort',
    accessor: 'patients',
  },
  {
    header: `Dose Amount (${unit})`,
    accessor: 'DoseAmount',
  },
  {
    header: '#Subjects with Toxicity',
    accessor: 'toxCount',
  },
  {
    header: '#Subjects with Effectiveness',
    accessor: 'effCount',
  },
  {
    header: 'Decision',
    accessor: 'decision',
    customCell:DecisionCell
  },
  {
    header: 'Safety Violation',
    accessor: 'safeViolation',
  },
  {
    header: 'Effectiveness Violation',
    accessor: 'effViolation',
  },
  {
    accessor: 'action',
    customCell: ActionCell,
    align: 'right',
  },
]

export const editFields = [
  {
    name: 'patients',
    label: '# Subjects in Cohort',
    disabled: false,
    type: 'number',
    isDependent: false,
  },
  {
    name: 'toxCount',
    label: '# Subjects with Toxicity',
    disabled: false,
    type: 'number',
    isDependent: true,
  },
  {
    name: 'effCount',
    label: '# Subjects with Effectiveness',
    disabled: false,
    type: 'number',
    isDependent: true,
  },
];