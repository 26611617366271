import React, {useEffect} from 'react';
import TextField from 'apollo-react/components/TextField';
import MenuItem from 'apollo-react/components/MenuItem';
import DateRangePicker from 'apollo-react/components/DateRangePicker';
import moment from 'moment';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { customIFilterComponent, IFilterComponent, IFilters, IProjectFieldType} from './types';
import Select from 'apollo-react/components/Select';
import {useSelector, useDispatch} from 'react-redux';
import {getActiveuser} from '../../../features/projects/selectors/userDetailsSelector';
import {getProjectFieldsData} from '../../../features/projects/slices/CreateProjectSlice';
import {
  getEngagementsSelector,
  getObjectivesSelector,
} from '../../../features/projects/selectors/CreateProjectSelectors';
import {selectAvailableProjectOwners} from '../../../features/projects/selectors/ProjectHomeSelectors';
import {RootState} from '../../../app/store';
import DatePicker from 'apollo-react/components/DatePicker';

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    popper: {
      zIndex: 100000, // greater than App bar
    },
    hideLabel: {
      margin: '0px !important',
      '& label': {
        display: 'none',
      },
      '& > div:first-child' : {
        margin: '0px',
        '& $div':{
          marginTop:'0px'
        }
      },
    },
  });
});

export const TextFieldFilter = ({accessor, filters, updateFilterValue}: IFilterComponent) => {
  return (
    <TextField
      value={filters[accessor]}
      data-testid="text-filter"
      name={accessor}
      onChange={updateFilterValue}
      fullWidth
      margin="none"
      size="small"
    />
  );
};

export const DateFilter = ({accessor, filters, updateFilterValue}: IFilterComponent) => {
  const [error, setError] = React.useState<boolean>(false);
  const [errorText, setErrorText] = React.useState<string>('');
  const classes = useStyles();
  const disableFuture = !(accessor === 'startDate' || accessor === 'endDate');
  const commonAttrs = {
    'data-testid': `date-range-filter-${accessor}`,
    startLabel: '',
    endLabel :'',
    placeholder: '',
    fullWidth: true,
    margin: "none",
    size: "small",
    helperText: errorText,
    error: error,
    name: accessor
  };

  const isStartGreaterthanEnd = (value: any) => moment.utc(value[0]) > moment.utc(value[1]);

  const isFutureDateRange = (value: any) => moment.utc(value[0]) > moment.utc() || moment.utc(value[1]) > moment.utc();

  const onChangeHandler = (value: any) => { 
    if (isStartGreaterthanEnd(value) || (disableFuture && isFutureDateRange(value))) {
      setError(true);
      setErrorText('Invalid date range');
    } else {
      setError(false);
      setErrorText('');
      return updateFilterValue({
        target: {name: accessor, value},
      });
    }
  };

  return (
    <div style={{minWidth: 230}}>
      <div style={{position: 'absolute', top: 0, paddingRight: 4}}>
        <DateRangePicker 
          classes={{ popper: classes.popper }}
          value={filters[accessor] || [null, null]}
          disableFuture={disableFuture}
         // maxDate={disableFuture ? new Date() : null}
          onChange={onChangeHandler}
          {...commonAttrs}
        />
      </div>
    </div>
  );
};

export const SingleDateFilter = ({accessor, filters, updateFilterValue}: IFilterComponent) => {
  const classes = useStyles();
  return (
    <div>
      <div className={classes.hideLabel}  style={{position: 'absolute', top: 0, paddingRight: 4}}>
        <DatePicker
          data-testid="date-filter"
          className={classes.hideLabel} 
          value={filters[accessor] || null}
          name={accessor}
          disableFuture
          // maxDate={new Date()}
          onChange={(value: any) => {
            return updateFilterValue({
              target: {name: accessor, value},
            });
          }}
          placeholder="MM/DD/YYYY"
          label=''
          small
          margin="none"
          size="small"
        />
      </div>
    </div>
  );
};

export const IntegerFilter = ({accessor, filters, updateFilterValue}: IFilterComponent) => {
  return (
    <TextField
      data-testid="integer-filter"
      value={filters[accessor]}
      name={accessor}
      onChange={updateFilterValue}
      type="number"
      fullWidth
      style={{display: 'flex', justifyContent: 'center'}}
      margin="none"
      size="small"
    />
  );
};

export const createStringArraySearchFilter = (accessor: string) => {
  return (row: any, filters: IFilters) =>
    !Array.isArray(filters[accessor]) ||
    (filters[accessor] as string[]).length === 0 ||
    (filters[accessor] as string[]).some((value: string) => value === row[accessor]);
};

export const CustomSelectFilterComponent = ({accessor, filters, updateFilterValue}: IFilterComponent) => {
  const dispatch = useDispatch();
  const {userid, tenantid} = useSelector(getActiveuser);
  const engagementTypes: any[] = useSelector(getEngagementsSelector);
  const projectObjectives: any[] = useSelector(getObjectivesSelector);

  // Project Detail Page - Session Results table
  const projSessionId: any[] = useSelector((state: RootState) => state.projectDetail.projectResults.projSessionIds);
  const sessionOwners: any[] = useSelector((state: RootState) => state.projectDetail.projectResults.owners);
  const statuses: any[] = useSelector((state: RootState) => state.projectDetail.projectResults.statuses);
  const endPoints: any[] = useSelector((state: RootState) => state.projectDetail.projectResults.endPoints);
  const designs: any[] = useSelector((state: RootState) => state.projectDetail.projectResults.designs);

  const availableProjectOwners: IProjectFieldType[] = useSelector(
    selectAvailableProjectOwners,
  ) as IProjectFieldType[];

  let data: IProjectFieldType[] = [];
  useEffect(() => {
    // Fetching and Updating the state with engagementType and objectives data for the resp. column filter dropdown in the table if the state does not have this data already
    if (['engagementType', 'objective'].includes(accessor)) {
      (engagementTypes?.length === 0 || projectObjectives?.length === 0) &&
        dispatch(getProjectFieldsData({userid, tenantid}));
    }
  }, [dispatch, accessor, engagementTypes, projectObjectives, userid, tenantid]);

  // Map the data for multi select dropdown component
  if (accessor === 'owner') {
    data = availableProjectOwners;
  } else if (accessor === 'engagementType') {
    data = engagementTypes;
  } else if (accessor === 'objective') {
    data = projectObjectives;
  } else if (accessor === 'projSessionId') {
    data = projSessionId;
  } else if (accessor === 'createdBy') {
    data = sessionOwners;
  } else if (accessor === 'designs') {
    data = designs;
  } else if (accessor === 'sessionStatus') {
    data = statuses;
  } else if (accessor === 'endPoint') {
    data = endPoints;
  }

  const props = {
    data, 
    accessor, 
    filters,
    updateFilterValue
  };

  return (
    <SimpleMultiSelectFilterComponent {...props} />
  );
};

const getValues = (item: any) => {
  if (typeof item === 'string' || typeof item === 'number') {
    return {
      key: item || '',
      name: item || '',
      id: item || '',
    };
  }

  if (Object.keys(item).length > 0) {
    return {
      key: item.id || '',
      name: item.name || '',
      id: item.id || '',
    };
  }

  return {
    key: item || '',
    name: item || '',
    id: item || '',
  };
};

export const SimpleMultiSelectFilterComponent = ({data, accessor, filters, updateFilterValue}: customIFilterComponent) => {
  return (
    <div style={{maxWidth: 177}}>
      <Select
        value={filters[accessor] === '' || filters[accessor] === undefined ? [] : filters[accessor]}
        name={accessor}
        onChange={updateFilterValue}
        multiple
        fullWidth
        margin="none"
        size="small"
      >
        {data?.map((el: IProjectFieldType) => {
          const item = getValues(el);
          return (
            <MenuItem key={item.key} value={item.id}>
              {item.name}
            </MenuItem>
          );
        })}
      </Select>
    </div>
  );
};
