import { call, put, takeEvery, select } from 'redux-saga/effects';
import { callApi } from '../../../services/api';
import { ENDPOINTS } from '../../../services/endpoint';
import {
  updateSponsors,
  getSponsors as getSponsorsAction,
  updateProtocols,
  getProtocols as getProtocolsAction,
  updateTherapeuticAreas,
  getTherapeuticAreas as getTherapeuticAreasAction,
  updateOtherTherapeuticAreas,
  getOtherTherapeuticAreas as getOtherTherapeuticAreasAction,
  updateObjectives,
  getObjectives as getObjectivesAction,
  updatePhases,
  getPhases as getPhasesAction,
  createProject as createProjectAction,
  createProjectSuccess,
  updateProject as updateProjectAction,
  updateProjectSuccess,
  updateProjectError,
  getProjectFieldsData as getProjectFieldsDataAction, 
  updateProjectFieldsData
} from '../slices/CreateProjectSlice';
import { getObjectivesSelector } from '../selectors/CreateProjectSelectors';
import { PayloadAction } from '@reduxjs/toolkit';
import { addGAEvent } from '../../../services/googleAnalytics';

type Payload = {
  userid: string;
  tenantid: string;
};


export function* watchGetProjectFieldsData() {
  yield takeEvery(getProjectFieldsDataAction, getProjectFields);
}

export function* getProjectFields(action: PayloadAction<Payload>) {
  const { userid, tenantid } = action.payload;
  const url = `${ENDPOINTS.GET_PROJECT_FIELDS}?&userId=${userid}&tenantId=${tenantid}`;
  const { data } = yield call(callApi, url);
  if (data) {
    yield put(updateProjectFieldsData(data));
  }
}

export function* watchGetSponsors() {
  yield takeEvery(getSponsorsAction, getSponsors);
}

export function* getSponsors(action: PayloadAction<Payload>) {
  const { userid, tenantid } = action.payload;
  const url = `${ENDPOINTS.GET_SPONSOR}?query=sponsors&userId=${userid}&tenantId=${tenantid}`;
  const { data } = yield call(callApi, url);
  if (data) {
    yield put(updateSponsors(data));
  }
}

export function* watchGetProtocols() {
  yield takeEvery(getProtocolsAction, getProtocols);
}

export function* getProtocols(action: PayloadAction<Payload>) {
  const { userid, tenantid } = action.payload;
  const url = `${ENDPOINTS.GET_PROTOCOLS}?query=protocols&userId=${userid}&tenantId=${tenantid}`;

  const { data } = yield call(callApi, url);
  if (data) {
    yield put(updateProtocols(data));
  }
}

export function* watchTherapeuticAreas() {
  yield takeEvery(getTherapeuticAreasAction, getTherapeuticAreas);
}

export function* getTherapeuticAreas(action: PayloadAction<Payload>) {
  const { userid, tenantid } = action.payload;
  const url = `${ENDPOINTS.GET_THERAPEUTIC_AREAS}?query=therapeuticAreas&userId=${userid}&tenantId=${tenantid}`;
  const { data } = yield call(callApi, url);
  if (data) {
    yield put(updateTherapeuticAreas(data));
  }
}

export function* watchOtherTherapeuticAreas() {
  yield takeEvery(getOtherTherapeuticAreasAction, getOtherTherapeuticAreas);
}

export function* getOtherTherapeuticAreas(action: PayloadAction<Payload>) {
  const { userid, tenantid } = action.payload;
  const url = `${ENDPOINTS.GET_OTHER_THERAPEUTIC_AREAS}?query=otherTherapeuticAreas&userId=${userid}&tenantId=${tenantid}`;
  const { data } = yield call(callApi, url);
  if (data) {
    yield put(updateOtherTherapeuticAreas(data));
  }
}

export function* watchObjectives() {
  yield takeEvery(getObjectivesAction, getObjectives);
}

export function* getObjectives(action: PayloadAction<Payload>) {
  const { userid, tenantid } = action.payload;
  const url = `${ENDPOINTS.GET_OBJECTIVES}?query=objectives&userId=${userid}&tenantId=${tenantid}`;
  const { data } = yield call(callApi, url);
  if (data) {
    yield put(updateObjectives(data));
  }
}

export function* watchPhases() {
  yield takeEvery(getPhasesAction, getPhases);
}

export function* getPhases(action: PayloadAction<Payload>) {
  const { userid, tenantid } = action.payload;
  const url = `${ENDPOINTS.GET_PHASES}?query=phases&userId=${userid}&tenantId=${tenantid}`;
  const { data } = yield call(callApi, url);
  if (data) {
    yield put(updatePhases(data));
  }
}

export function* watchCreateProject() {
  yield takeEvery(createProjectAction, createProject);
}

export function* createProject({ payload }: any) {
  const { data } = yield call(callApi, ENDPOINTS.CREATE_PROJECT, {
    method: 'POST',
    mode: 'cors',
    body: JSON.stringify(payload),
  });
  if (data) {
    // Extract ObjectiveName to send to GA.
    const objectives = yield select(getObjectivesSelector);
    interface ObjectiveType {[x:string]: any}
    const obejctiveName = objectives.find((objective: ObjectiveType) => objective.id === payload.objective).name;
  
    addGAEvent({
      category: 'Project',
      event: 'CREATE_PROJECT',
      action: 'CREATE_PROJECT',
      label: obejctiveName,
    });
    yield put(createProjectSuccess(data));
  }
}

export function* watchUpdateProject() {
  yield takeEvery(updateProjectAction, updateProject);
}

export function* updateProject({ payload }: any) {
  try {

    const { projectId, projectData } = payload;
    const url = `${ENDPOINTS.UPDATE_PROJECT}?projectid=${projectId}`;

    const { data } = yield call(callApi, url, {
      method: 'PUT',
      mode: 'cors',
      body: JSON.stringify(projectData),
    });
    if (data) {
      yield put(updateProjectSuccess(data));
    }
  } catch (error : any) {
    yield put(updateProjectError(error?.error));
  }
}