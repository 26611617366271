import {createSlice, PayloadAction } from '@reduxjs/toolkit';
import {addSessionStorage, getSessionStorage} from '../../../common/utils/useSessionStorage';

export const initialState: any = {
    isLoading: false,
    details: {},
    error: {
        message: '',
        type: '',
    },
};

type tenants = {
    tenantNames: [string],
    tenantIDs: [number],
    tenantLevelRoles: [string],
    tenantRolesID: [number],
    isDefaultTenantSelected: any,
    isInternalTenant: [number]
}

const getTenants = ({ tenantNames, tenantIDs, tenantLevelRoles, tenantRolesID, isDefaultTenantSelected, isInternalTenant }: tenants): any => {
    const tenantsList = mapIDs(tenantIDs, tenantNames, 'tenantNames');
    const roles = mapIDs(tenantRolesID, tenantLevelRoles, 'type');
    if (!tenantsList.length) {
        return [];
    }   
    const tenants = tenantsList.map((tenantItem: any, index: number) => ({ ...tenantItem, isInternalTenant: isInternalTenant[index], isDefaultTenant: tenantItem.id === isDefaultTenantSelected, ...(roles.length && { role: roles[index] }) }));
    return tenants;
}

const mapIDs = (idsList: [number], list: [string], propAttr: string = 'name') => {
    if (!idsList.length || !list.length) {
        return [];
    }
    return list.map((item, index) => ({ id: idsList[index], [propAttr]: item }));
}

export const userDetailsSlice = createSlice({
  name: 'userDetails',
  initialState: initialState as any,
  reducers: {
    fetchUserRequest: (state: any, action: any) => {
      state.isLoading = true;
    },
    loadUserDetails: (state: any, action: any) => {
      const tenantId = getSessionStorage('tenantid', true);
      const {
        payload: {data},
      } = action;
      const {
        tenantNames,
        tenantIDs,
        tenantLevelRoles,
        tenantRolesID,
        defaultTenantId,
        isInternalTenant,
        ...details
      } = data;
      const isDefaultTenantSelected = defaultTenantId || (tenantIDs.length === 1 && tenantIDs[0]);
      const tenants = getTenants({
        tenantNames,
        tenantIDs,
        tenantLevelRoles,
        tenantRolesID,
        isDefaultTenantSelected,
        isInternalTenant,
      });
      const activeTenant = tenants.length ? tenants.filter((i: any) => i.id === tenantId)[0] : {};
      state.details = {...details, tenants, defaultTenantId: isDefaultTenantSelected, activeTenant};
      state.isLoading = false;
    },
    setActiveTenant: (state: any, action: PayloadAction<any>) => {
      const {payload: id} = action;
      addSessionStorage('tenantid', id);
      const tenants = state.details.tenants;
      const activeTenant = tenants?.find((i: any) => i.id === id);
      state.details.activeTenant = activeTenant;
    },
    fetchUserFailure: (state: any, action) => {
      const {code = '', message} = action.payload;
      const getErrors = getErrorMessage(code, message);
      state.error = getErrors;
      state.isLoading = false;
    },
    resetUserError: (state: any) => {
      state.error = {
        message: '',
        type: '',
      };
      state.isLoading = false;
    },
    setSessionDefaultTenant: (state: any, action) => {
      const {tenantId} = action.payload;
      const tenants = state.details.tenants;
      const tenantListWithActive = tenants.map((tenant: any) => {
        return {...tenant, isDefaultTenant: tenant.id === tenantId};
      });
      state.details = {...state.details, tenants: tenantListWithActive, defaultTenantId: tenantId};
    },
  },
});

const getErrorMessage = (errorCode: string | number, message?: string) => {
    switch (errorCode) {
        case 101:
          return {
            type: "Warning",
            message: 'Access restricted. Please reach out to your tenant administrator for more information'
          };
        default:
          return {
            type: "Error",
            message
          };
      }
}

// type User = {
//     userID: number,
//     qID: number,
//     isSystemAdmin: string,
//     userName: string,
//     emailID: string,
//     tenantNames?: string[],
//     tenantIDs?: number[],
//     tenantLevelRoles?: string[],
//     tenantRolesID?: number[]
// }

// TODO - need to remove when login works
export const DEFAULT_USER = {
    "data": {
        "userID": 1,
        "qID": "Q744650",
        "userName": "Kamala M",
        "emailID": "kamala.devi@quintiles.com",
        "tenantNames": [
            "QUINTILES",
            "IMS"
        ]
    }
}


export const {
    fetchUserRequest,
    loadUserDetails,
    fetchUserFailure,
    setActiveTenant,
    resetUserError,
    setSessionDefaultTenant,
} = userDetailsSlice.actions;
export default userDetailsSlice.reducer;
