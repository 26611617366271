import { put, takeEvery, call } from 'redux-saga/effects';
import {
  fetchSuccess,
  fetchFailure,
  fetchRequest,
  cleanUp
} from '../slices/TrainingVideoSlice';
import { ENDPOINTS } from '../../../services/endpoint';
import { callApi } from '../../../services/api';
import { PayloadAction } from '@reduxjs/toolkit';

export function* watchTrainingVideos() {
  yield takeEvery(fetchRequest, getTrainingVideos);
}

export function* getTrainingVideos(
  action: PayloadAction<{
    tenantId: string,
    category: string
  }>
) {
  try {
    yield put(cleanUp([]));
    const { payload: {tenantId, category} } = action;
    let url = `${ENDPOINTS.GET_TRAINING_VIDEOS}?tenantid=${tenantId}`;
    if(category) {
      url = url + `&filter=${category}`;
    }  
    
    const result: any = yield call(callApi, url);
    yield put(fetchSuccess(result));
  } catch (error : any) {
    console.warn(error?.error);
    yield put(fetchFailure(error?.error));
  }
}
