import nanoid from 'nanoid';
import {IStage, StepTypes} from '../../types/CustomDesignTypes';

export const getStageTemplate = (
  number: number = 0,
  first: boolean = false,
  steps: Array<StepTypes>,
): IStage => {
  if (first) {
    steps[0].active = true;
    steps[0].status = 'inProgress';
  } else {
    steps[0].active = false;
    steps[0].status = 'notStarted';
  }
  const id = nanoid();
  const newSteps = steps.map((s:StepTypes)=>({...s,stageID:id}))
  const template: IStage = {
    stageId: id,
    name: `Stage ${number + 1}`,
    steps:newSteps,
    localId: number + 1,
    active: first,
    isStageComplete: false,
    paramsList:[],
    templateName:'',
    templateID:-1
  };
  return template;
};
