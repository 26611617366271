
export const getPropertyName = (name: any) => {
  if (name === 'm+n') return 'mplusn';
  return name
}

export const checkValidBOINForm = ( formikProps:any, index:any ) => {
  const errors= formikProps?.errors || {};
  const isBOINError = (Array.isArray(errors?.designList) && errors?.designList[index]) || false;
  const isTrialParamError = errors?.targetProbabilityToxicity ? true : false;

 return isBOINError || isTrialParamError;
}