import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type TrainingVideoState =  {
  data: Array<any>;
  isLoading: boolean;
  error: string;
};

export const initialState: TrainingVideoState = {
  data: [],
  isLoading: false,
  error: '',
};

export const trainingVideoSlice = createSlice({
  name: 'trainingVideoSlice',
  initialState: initialState as TrainingVideoState,
  reducers: {
    fetchRequest: (state: TrainingVideoState, action) => {
      state.isLoading = true;
    },
    cleanUp: (state: TrainingVideoState, action) => {
      state.data = [];
    },
    fetchSuccess: (
      state: TrainingVideoState,
      action: PayloadAction<TrainingVideoState>
    ) => {
      const { payload: { data } } = action;
      
      state.data = data;
      state.isLoading = false;
    },
    fetchFailure: (state: TrainingVideoState, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const {
  fetchRequest,
  fetchFailure,
  fetchSuccess,
  cleanUp
} = trainingVideoSlice.actions;

export default trainingVideoSlice.reducer;
