import React, {useRef, useState} from 'react';
import {useHistory} from 'react-router-dom';
import IdleTimer from 'react-idle-timer';
import PromptModal from './AutoLogoutPrompt';
import {logOutRequest, setIsLogginOut,setIsLogoutButtonClicked} from '../../../features/loginPage/slices';
import {useDispatch} from 'react-redux';
import {getSessionStorage} from '../../../common/utils/useSessionStorage';
import useVisibilityChange from 'use-visibility-change';
import moment from 'moment';
import { getBeforeUnloadTime, setBeforeUnloadTime } from '../RouteLeavingGuard';

let countdownInterval;
let timeout;
let tabInactiveTime;

// Total 20 minutes for token expiry
const DEFAULT_EXIPIRY_VARIABLE = 15;
const DEFAULT_PROMPT_VARIABLE = 5;
const DEFAULT_EXPIRY_TIME = DEFAULT_EXIPIRY_VARIABLE * 60 * 1000; // 15 minutes
const DEFAULT_PROMPT_ALERT_TIME = 60 * DEFAULT_PROMPT_VARIABLE; // 5 minutes
const DEFAULT_TOTAL_EXPIRY_TIME = DEFAULT_EXIPIRY_VARIABLE + DEFAULT_PROMPT_VARIABLE; // 20 minutes

const AutoLogout = ({promptTimer = DEFAULT_PROMPT_ALERT_TIME, expiryTime = DEFAULT_EXPIRY_TIME}) => {
  const dispatch = useDispatch();
  const [timeoutModalOpen, setTimeoutModalOpen] = useState(false);
  const [timeoutCountdown, setTimeoutCountdown] = useState(promptTimer);
  const [countDown, setCountDown] = useState('');
  const idleTimer = useRef(null);
  const {objId} = getSessionStorage('projectDetail') || {};
  const history = useHistory();

  const onHide = () => {
    tabInactiveTime = new moment();
  };

  const onShow = () => {
    const tabActiveTime = new moment();
    const timeDiffernce = moment.duration(tabActiveTime.diff(tabInactiveTime)).asMinutes();
    
    checkTimeDiffLogout(timeDiffernce);
  };

  const checkTimeDiffLogout = (timeDiffernce) => {
    // Adding additional support for the following scenarios:
    // 1) When systen goes into sleep mode, CPU timer stops & setInterval, setTimeOut stop working.
    //    When the sure returns to the current tab after expiry time, this IF condition gets executed.
    // 2) If any issue occurs in the implementation of IdleTimer-setTimeOut-clearInterval implementation, this IF condition acts as fallback.
    if (timeDiffernce > DEFAULT_TOTAL_EXPIRY_TIME) {
      handleLogout('autologout');
    } else if (timeDiffernce > DEFAULT_EXIPIRY_VARIABLE) {
      // Show popup if the time difference exceed the default expiry time
      const time = (DEFAULT_TOTAL_EXPIRY_TIME - timeDiffernce) * 60;
      clearSessionTimeout();
      clearSessionInterval();
      onIdle(time);
    }
  };

  // TODO: Use visibilityChange event handler instead of npm module.
  useVisibilityChange({onShow, onHide});

  const clearSessionTimeout = () => {
    clearTimeout(timeout);
  };

  const clearSessionInterval = () => {
    clearInterval(countdownInterval);
  };

  const handleLogout = (isAutoLogoutTriggered = '') => {
    setTimeoutModalOpen(false);
    setTimeoutCountdown(promptTimer);
    setCountDown('');
    clearSessionInterval();
    clearSessionTimeout();
    handleLogoutUser(isAutoLogoutTriggered);
  };

  // For now, we are usig url to determine if the user is in SesisonPage.
  // Autosave is currently limited to GS & Dose Esc.
  const inSessonPage = () => {
    const isAutoSaveEnabled = [1, 2].includes(objId);
    const validRegex = /projects\/session\/\d+/;
    const path = history.location.pathname;
    return (path === '/projects/session' || path.match(validRegex)) && isAutoSaveEnabled;
  };

  const inAccrualPage = () => {
    const validRegex = /projects\/accrual\/\d+\/\d+/;
    return validRegex.test(history.location.pathname);
  };

  const handleLogoutUser = (isAutoLogoutTriggered) => {
    // If user is in Sessionpage or Accrual Page trigger form auto-save & then logout.
    // In Sessionpage, currently limited to GS & DE.
    if (inSessonPage() || inAccrualPage()) {
      dispatch(setIsLogginOut(true));
    } else {
      dispatch(logOutRequest(isAutoLogoutTriggered));
    }
  };

  const handleContinue = () => {
    setTimeoutModalOpen(false);
    setTimeoutCountdown(promptTimer);
    setCountDown('');
    clearSessionInterval();
    clearSessionTimeout();
  };

  const onActive = () => {
    if (!timeoutModalOpen) {
      clearSessionInterval();
      clearSessionTimeout();
    }
  };

  const calculateTimer = (countDown) => {
    let minutes = parseInt(`${countDown / 60}`, 10) % 60;
    let seconds = parseInt(`${countDown % 60}`, 10);

    minutes = isNaN(minutes) ? '00' : (minutes < 10 ? '0' + minutes : minutes);
    seconds = isNaN(seconds) ? '00' : (seconds < 10 ? '0' + seconds : seconds);
    return {
      minutes,
      seconds,
    };
  };

  // Check if user us returning from "leave site" popup/ Unload event.
  // If the user is returning after Expiry, logout the user.
  const checkReturnFromUnload = (countDown) => {
    if(countDown?.timeStamp && getBeforeUnloadTime()) {
      const tabActiveTime = moment(countDown.timestammp);
      const timeDiffernce = moment.duration(tabActiveTime.diff(getBeforeUnloadTime())).asMinutes();
      setBeforeUnloadTime(moment());
      checkTimeDiffLogout(timeDiffernce);
      return;
    }
  };

  const onIdle = (time = timeoutCountdown) => {
    let countDown = time;
    checkReturnFromUnload(countDown);

    let {minutes, seconds} = calculateTimer(countDown);
    setCountDown(`${minutes} : ${seconds}`);
    setTimeoutModalOpen(true);
    timeout = setTimeout(() => {
      const timer = () => {
        let {minutes, seconds} = calculateTimer(countDown - 1);
        if (countDown > 0) {
          setCountDown(`${minutes} : ${seconds}`);
          setTimeoutCountdown(--countDown);
        } else {
          handleLogout('autologout');
        }
      };
      countdownInterval = setInterval(timer, 1000);
    }, 0);
  };

  const handleButtonLogout = () => {
    dispatch(setIsLogoutButtonClicked(true));
    handleLogout('');
  }
  return (
    <>
      <IdleTimer ref={idleTimer} onActive={onActive} onIdle={onIdle} debounce={300} timeout={expiryTime} />
      <PromptModal
        countdown={countDown}
        onContinue={handleContinue}
        onLogout={handleButtonLogout}
        open={timeoutModalOpen}
      />
    </>
  );
};

export default AutoLogout;
