import {FILES} from '../../../common/constants';
import moment from 'moment';

// Function to get the Extension of a File being uploaded
export const getFileExtension = (name: string) => {
  const extension = name.substring(name.lastIndexOf('.') + 1, name.length);
  return `.${extension}`;
};

//Function to check if the extension is among the allowed doc types
export const checkFileType = (file: File): boolean => {
  const fileExtension = getFileExtension(file.name.replace(/ /g, '').toLowerCase());
  return FILES.ALLOWED_DOC_TYPES.includes(fileExtension);
};

//Function to check if the File Name exceeds 30 Characters
export const checkFileNameExceeds30 = (fileName: string) => {
  return fileName.substring(fileName.lastIndexOf('.'), 0).length > 25;
};

//Function to convert file size originally in bytes to KiloBytes
export const fileSizeToKB = (fileSize: number) => {
  return parseFloat((fileSize / 1024).toFixed(2));
};

//Function to return the first 30 characters of the file Name
export const get30Characters = (name: string) => {
  return `${name.substring(0, 24)}...`;
};

//Function to get File Name with TimeStamp
export const getNewFileName = (name: string) => {
  const newName = name.substring(name.lastIndexOf('.'), 0);
  const extension = getFileExtension(name);
  return `${newName} ${moment(new Date()).format('DD-MMM-YYYY h:mm A')}${extension}`;
};

export const getFileSizeInMB = (size: number) => size * 1024 * 1024; // Size in MB
