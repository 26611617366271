import React, { useState } from 'react';
import { FormikCustomAutocomplete } from '../../../../common/components/formik-controls/FormikComponents';
import { CONSTANTS } from '../../../../common/constants';
import { useSelector } from 'react-redux';
import {
  getTherapeuticAreasSelector,
  getOtherTherapeuticAreasSelector,
} from '../../selectors/CreateProjectSelectors';

import './style.scss';

export default function TherapeuticAreas() {
  const [showOthersField, setShowOthersField] = useState(false);
  const therapeuticAreas = useSelector(getTherapeuticAreasSelector);
  const otherTherapeuticAreas = useSelector(getOtherTherapeuticAreasSelector);

  const handleChange = (value: any) => {
    const selectedOthers = Array.isArray(value) && value.length ? value.some(
      (item: any) => item.name === 'Other'
    ) : false;
    setShowOthersField(selectedOthers);
  };

  return (
    <>
      <FormikCustomAutocomplete
        name='therapeuticAreas'
        className='spacer'
        label={CONSTANTS.LABELS.THERAPEUTIC_AREAS}
        options={therapeuticAreas}
        labelKey='name'
        helperText=''
        handleChange={handleChange}
      />
      {showOthersField && (
        <FormikCustomAutocomplete
          name='otherTherapeuticAreas'
          className='spacer'
          label={CONSTANTS.LABELS.OTHER_THERAPEUTIC_AREA}
          options={otherTherapeuticAreas}
          labelKey='name'
          helperText=''
        />
      )}
    </>
  );
}
