import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  TrialDurationParamFormFieldsType,
  TrialParamDataTypes,
  TrialParamFormField,
  TrialParamFormType,
  TrialParamFormValue,
} from '../types/SessionTypes';
import { getRandomNumber } from '../../../common/utils';

export const initialState: TrialParamFormValue = {
  isLoading: false,
  isDoseLocked: true,
  error: '',
  data: {} as any,
  prevDoseAmounts:[],
  prevMaxDoseLvl:'',
  trialDurationParams: {} as any,
  includeTrialDuration: false,
};

export const trialMapToInputs = (
  data: Array<TrialParamFormType>
): { [x in keyof TrialParamFormField]: TrialParamFormType } => {
  if (!data.length) {
    return [] as any;
  }
  return data.reduce((acc: any, cur: any) => {
      acc[cur.name] = {
        ...cur,
        name: cur.name,
        ...(cur.name === 'randomSeed' &&
          !cur.value && {
            defaultValue: getRandomNumber(),
          }),
      };
    return acc;
  }, {});
};

const checkValidations = (data : Array<any>) => {
  return data.reduce((acc:any,el:any) => {
    return {
       ...acc,
       [el.name] : el.checkValidation    
       } 
   },{})
}

export const trialForm = (
  data: Array<TrialParamFormType>,
  formName: string,
  commonTrialParams?: any
): TrialParamDataTypes => {
  if (!data.length) {
    return {} as any;
  }
  const inputfields: {
    [x in keyof TrialParamFormField | string]: TrialParamFormType;
  } = trialMapToInputs(data);
  // const checkValidations: any = Object.keys(inputfields).reduce(
  //   (p: any, c: any) => ({
  //     ...p,
  //     ...{
  //       [c]: inputfields[c].checkValidation
  //         ? inputfields[c].checkValidation
  //         : inputfields[c].checkValidation,
  //     },
  //   }),
  //   {}
  // );

  const intialValues: TrialParamFormField = Object.keys(inputfields).reduce(
    (p: any, c: any) => ({
      ...p,
      ...{
        [c]: inputfields[c].defaultValue
          ? inputfields[c].defaultValue
          : inputfields[c]?.value,
      },
    }),
    {}
  );

  const doseAmounts = intialValues.doseAmounts
    ? intialValues.doseAmounts
    : Array(Number(intialValues.maxDoseLevels)).fill('');

  /*const acceleratedTitration = intialValues?.acceleratedTitration
    ? !!intialValues?.acceleratedTitration
    : false;*/

  
  //TO DO- For backward compatibility of existing sessions 
  // fetch fields from getSessionData API once updated
  const inputEOSTMField = {
    EOSTM:{
        paramID: 454,
        label: "Effectiveness or Surrogate to Measure",
        name: "EOSTM",
        description: "",
        defaultValue: "",
        metaData: "{\"size\":5,\"type\":\"text\",\"color\":null,\"options\":[{\"id\":\"Inhibition\",\"name\":\"Inhibition\"},{\"id\":\"BloodLevel\",\"name\":\"Blood Level\"}],\"disabled\":false,\"fieldType\":\"dropdown\"}",
        checkValidation: ""
    },
    endpoint:{
      checkValidation: "",
      defaultValue: "",
      description: "",
      label: "End Point",
      metaData: "{\"size\":5,\"type\":\"text\",\"color\":null,\"options\":[{\"id\":\"TOX\",\"value\":\"Toxicity\"},{\"id\":\"TOXEFFICACY\",\"value\":\"Toxicity and Effectiveness Or Surrogate\"}],\"disabled\":false,\"fieldType\":\"dropdown\"}",
      name: "endpoint",
      paramID: 452,
    }
  };
  
  const tooltip = Object.keys(inputfields).map((item) => ({
    title: `${inputfields[item].label} : `,
    subtitle: `${inputfields[item].description}`,
  }));

  const EOSTM = intialValues.EOSTM
  ? Object.keys(intialValues.EOSTM).length > 0 ? intialValues.EOSTM : {}
  : {id:'', name:''};

  // retain description property for savedsession data from commontrial fields
  if (commonTrialParams) {
    Object.keys(inputfields).forEach((el) => {
      if (inputfields[el] && commonTrialParams && commonTrialParams[el]) {
        inputfields[el].description = commonTrialParams[el]?.description || '';
      }
    });
  }

  return {
    fields: {...inputEOSTMField, ...inputfields},
    intialValues: { ...intialValues, doseAmounts, EOSTM }, //{ ...intialValues, acceleratedTitration, doseAmounts },
    name: formName,
    tooltipInfo: { subtitle: '', labels: tooltip },
    checkValidations:checkValidations(data),
  };
};

export const formatTrialDurationParams = (
  data: Array<TrialParamFormType>,
  formName: string,
): TrialParamDataTypes => {
  if (!data.length) {
    return {} as any;
  }
  const inputfields: {
    [x in keyof TrialDurationParamFormFieldsType | string]: TrialParamFormType;
  } = trialMapToInputs(data);

  const intialValues: TrialParamFormField = Object.keys(inputfields).reduce(
    (p: any, c: any) => ({
      ...p,
      ...{
        [c]: inputfields[c].defaultValue
          ? inputfields[c].defaultValue
          : inputfields[c]?.value,
      },
    }),
    {}
  );

  const tooltip = Object.keys(inputfields).map((item) => ({
    title: `${inputfields[item].label} : `,
    subtitle: `${inputfields[item].description}`,
  }));

  return {
    fields: inputfields,
    intialValues,
    name: formName,
    tooltipInfo: { subtitle: '', labels: tooltip },
    checkValidations: checkValidations(data),
  };
};


export const trialParamSlice = createSlice({
  name: 'trialparam',
  initialState: initialState as TrialParamFormValue,
  reducers: {
    getRequest: (state: TrialParamFormValue, action) => {
      state.isLoading = true;
    },
    fetchSuccess: (state: TrialParamFormValue, action: PayloadAction<any>) => {
      const { payload } = action;
      const data = trialForm(payload.data.trialParams, 'trailparam');
      state.data = data;
      const trialDurationParams = formatTrialDurationParams(payload.data.trialDurationParams, 'trialDurationParams');
      state.trialDurationParams = trialDurationParams;
      state.isLoading = false;
    },
    fetchFailure: (state: TrialParamFormValue, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    savedTrialparams: (state: TrialParamFormValue, action) => {
      const { payload } = action;
      const data = trialForm(payload.data, 'trailparam', state.data.fields);
      // Retain checlValidation data from overriding with session draft data
      state.data = {...data, checkValidations:state.data.checkValidations};
      state.isLoading = false;
    },
    resetTrialParams: (state) => {
      state.data = {} as any;
      state.isLoading = false;
      state.isDoseLocked = true;
    },
    updateDoseLockStatus: (state: TrialParamFormValue, action) => {
      const { payload } = action;
      state.isDoseLocked = payload;
    },
    savedTrialDurationparams: (state: TrialParamFormValue, action) => {
      const { payload } = action;
      const trialDurationParams = formatTrialDurationParams(payload.data, 'trialDurationParams');
      // Retain checlValidation data from overriding with session draft data
      state.trialDurationParams = trialDurationParams;
      state.isLoading = false;
    },
    updateIncludeTrialDuration: (state: TrialParamFormValue, action) => {
      const { payload } = action;
      state.includeTrialDuration = payload;
    },
    retainPrevDoseAmounts: (state: TrialParamFormValue, action) => {
      const { payload } = action;
      state.prevDoseAmounts = payload.prevDoseAmount;
      state.prevMaxDoseLvl = payload.prevMaxDose;
    },
  },
});

export const {
  getRequest,
  fetchFailure,
  fetchSuccess,
  savedTrialparams,
  resetTrialParams,
  updateDoseLockStatus,
  savedTrialDurationparams,
  updateIncludeTrialDuration,
  retainPrevDoseAmounts
} = trialParamSlice.actions;
export default trialParamSlice.reducer;
