export const INFO_TEXT_DESC = {
  ACCORDION: 'Software calculates accrual in days and assumes closed sites do not reopen',
  GROUP: 'Based on the Total # Groups selected the corresponding groups shall be displayed',
  AVGLOTSITESTAYSOPENASD:
    'A large value (e.g., 10,000) indicates that the sites do not close during the trial. A small value indicates that the sites may close.',
  EVENT_HAZARD_RATE_YES: `The values of piecewise 'Hazard rate for control' are editable for individual groups in the 'Configure Piecewise Accrual' screen.`,
  TOOLTIP_MESSAGE: {
    EVENT_HAZARD_RATE_TG: 'Calculated using event hazard rate for control and hazard ratio under H1',
    EVENT_HAZARD_RATE_AC:
      'Pooled event hazard rate for control and treatment calculated using randomization ratio',
  },
  TOOLTIP_NAME: {
    EVENT_HAZARD_RATE_TG: 'Event hazard rate for treatment group',
    EVENT_HAZARD_RATE_AC: 'Event hazard rate for accrual calculations',
  },
  WARNING_MESSAGE:
    "The 'Event hazard rate for control group' has been changed. The accrual predictions will not match the current trial design. ",
};
export const medianRecruitmentRates = {
  formKey: 'medianRecruitmentRates',
};
export const averageSiteDuration = {
  formKey: 'averageSiteDuration',
};

export const GSDesignTypes = {
  GROUP_SEQUENTIAL: 'Group Sequential',
  FIXED_DESIGN: 'Fixed Design',
};

export const mockData = [
  {
    group: [
      {
        id: 'AcademicGroup',
        name: 'Academic Group.',
      },
    ],
    site: '3',
  },
  {
    group: [
      {
        id: 'NonAcademicGroup',
        name: 'Non-Academic Group.',
      },
    ],
    site: '5',
  },
  {
    group: [
      {
        id: 'AcademicGroup',
        name: 'Academic Group.',
      },
    ],
    site: '5',
  },
  {
    group: [
      {
        id: 'AcademicGroup',
        name: 'Academic Group.',
      },
    ],
    site: '5',
  },
  {
    group: [
      {
        id: 'AcademicGroup',
        name: 'Academic Group.',
      },
    ],
    site: '5',
  },
];
