import {put, takeEvery, call, select} from 'redux-saga/effects';
import {fetchFailure, fetchRequest, fetchSuccess} from '../slices/customDesignSlice/DependencyLibrarySlice';
import {
  fetchFailure as CodeSnippetsFetchFailure,
  fetchRequest as CodeSnippetsFetchRequest,
  fetchSuccess as CodeSnippetsFetchSuccess,
  initializeStage,
  saveTemplate,
  saveTemplateSuccess,
  saveTemplateFailure,
  updateCodes
} from '../slices/customDesignSlice/StageSlice';

import {ENDPOINTS} from '../../../services/endpoint';
import {callApi} from '../../../services/api';
import {
  fetchSuccess as saveFetchSuccess,
  fetchRequest as saveFetchRequest,
  fetchFailure as saveFetchFailure,
  fetchSuccess_getSession,
  fetchFailure_getSession,
  fetchRequest_getSession,
  updateSavedSessionCasesData,
} from '../slices/customDesignSlice/SaveCustomSessionSlice';
import {
  selectConfiguration,
  selectStageList,
  selectCaseConfiguration,
} from '../selectors/customDesignSelectors';
import {getActiveuser} from '../selectors/userDetailsSelector';
import {getSessionStorage} from '../../../common/utils/useSessionStorage';
import {RootState} from '../../../app/store';
import {
  fetchcustomParamsRequest,
  fetchcustomParamsFailure,
  fetchcustomParamsSuccess,
  updateSessionView,
} from '../slices/customDesignSlice/ConfigurationSlice';
import {GetURLQuery} from '../../../common/utils/queryParams';
import history from '../../../common/utils/history';
import {
  updateCasesData,
  requestParsedValues,
  fetchParsedValueSuccess,
  fetchParsedValueFailure,
} from '../slices/customDesignSlice/CaseConfigurationSlice';
import {OpenResultSocket} from './sessionResult';
import nanoid from 'nanoid';
import {sortBy} from 'lodash';
import {openBanner} from '../slices/BannerSlice';

export default function* watchCustomDesignSagas() {
  yield takeEvery(CodeSnippetsFetchRequest, getAllCodeSnippets);
  yield takeEvery(fetchRequest, getDependencyLibrariesSaga);
  yield takeEvery(fetchcustomParamsRequest, getCustomParams);
  yield takeEvery(saveFetchRequest, customSaveSessionSaga);
  yield takeEvery(fetchRequest_getSession, getCustomSessionSaga);
  yield takeEvery(requestParsedValues, fetchParsedREditorValues);
  yield takeEvery(saveTemplate, saveTemplateSaga);
}

export function* getDependencyLibrariesSaga() {
  try {
    const url = `${ENDPOINTS.GET_ALL_DEPENDENCY_LIBS}?type=default`;
    const result = yield call(callApi, url);
    yield put(fetchSuccess(result));
  } catch (error:any) {
    console.warn(error);
    yield put(fetchFailure(error?.error));
  }
}

export function* customSaveSessionSaga({payload}: any) {
  try {
    const {projectId} = getSessionStorage('projectDetail');
    const stages = yield select(selectStageList);
    const configuration = yield select(selectConfiguration);
    const caseConfiguration = yield select(selectCaseConfiguration);
    const {userid, tenantid} = yield select(getActiveuser);
    const {statusComplete, sessionDetail} = payload;
    const status = statusComplete ? 'Completed' : 'Draft';
    const testRunFlag = 1;
    const sid = yield select((state: RootState) => state.customDesign.savedSession?.sessionId) || '';
    let method = 'POST';
    let url = '';
    let socketIdentifier = nanoid(12) || '';
    socketIdentifier = socketIdentifier.replace(/-/gi, '');
    if (sid) {
      const sID = `&sessionid=${sid}`;
      url = `${ENDPOINTS.SAVE_EXISTING_CUSTOM_DESIGN_SIMULATION}?projid=${projectId}&userid=${userid}&testrunflg=${testRunFlag}&status=${status}&tenantid=${tenantid}`;
      url += sID;
      method = 'PUT';
    } else {
      url = `${ENDPOINTS.SAVE_NEW_CUSTOM_DESIGN_SIMULATION}?projid=${projectId}&userid=${userid}&testrunflg=${testRunFlag}&status=${status}&tenantid=${tenantid}`;
    }
    if (status === 'Completed' || status !== 'Draft') {
      url += `&socketIdentifier=${socketIdentifier}`;
    }

    const jsonBody = {
      data: {
        stages: {
          data: stages.data,
          stageCounter: stages.stageCounter,
        },
        sessionDetail: {
          data: {...sessionDetail},
          isConfigured: configuration.isConfigured,
        },
        caseConfiguration: {
          data: caseConfiguration,
        },
      },
    };

    const data = yield call(callApi, url, {
      method,
      mode: 'cors',
      body: JSON.stringify({sessionLabel: sessionDetail.sessionLabel, jsonFile: jsonBody}),
    });
    if (data) {
      data['isNewSession'] = sid ? false : true;
      data['status'] = status
      yield put(saveFetchSuccess(data));
      if (status === 'Completed') {
        yield call(
          OpenResultSocket,
          {sid: data.sessionId, projectId, userid, tenantid, socketIdentifier},
          payload.dispatch,
        );
        yield call(forwardTo, `/projects/output/${data.sessionId}`);
      }
    } else {
      yield put(saveFetchSuccess({} as any));
    }
  } catch (error:any) {
    yield put(saveFetchFailure(String(error?.error)));
  }
}

function forwardTo(location: string) {
  history.push(location);
}

export function* getCustomParams(data: any) {
  try {
    const url = `${ENDPOINTS.COMMON_TRIAL_PARAMS}?objid=${data.payload}`;
    const result = yield call(callApi, url);
    yield put(fetchcustomParamsSuccess(result));
  } catch (error:any) {
    console.warn(error);
    yield put(fetchcustomParamsFailure(error?.error));
  }
}

export function* getAllCodeSnippets({payload = ''}: any) {
  try {
    const url = `${ENDPOINTS.GET_ALL_CODE_SNIPPETS}`;
    const result = yield call(callApi, url);
    yield put(CodeSnippetsFetchSuccess(result));
    const sid = yield select((state: RootState) => state.customDesign.savedSession?.sessionId) || '';
    if (!payload || !sid) {
      if(!payload?.isClonedSession) {
        yield put(initializeStage());
      }
    }
  } catch (error:any) {
    console.warn(error);
    yield put(CodeSnippetsFetchFailure(error?.error));
  }
}

export function* saveTemplateSaga({payload = ''}: any) {
  const {userTemplateId,onClose,activeStageIndex,...rest} =payload;
  try {
    const url = `${ENDPOINTS.SAVE_TEMPLATE}`;
    let jsonBody = {
      ...rest
    };
    if(userTemplateId !== -1) {
      jsonBody= {...jsonBody,userTemplateId}
    }
    const result = yield call(callApi, url,{
      method:'POST',
      mode: 'cors',
      body: JSON.stringify(jsonBody),
    });
    if(result) {
      const url = `${ENDPOINTS.GET_ALL_CODE_SNIPPETS}`;
      const snippets = yield call(callApi, url);
      yield put(updateCodes(snippets))
      yield put(saveTemplateSuccess({data:result,activeStageIndex}));
      onClose();
      yield put(
        openBanner({
          message: `Save Template ${rest.name}`,
          bannerType: 'success',
          autoClose:true
        }),
      );
    }
  } catch (error:any) {
    onClose()
    console.warn(error);
    yield put(saveTemplateFailure({error:error?.error,activeStageIndex}));
    yield put(
      openBanner({
        message: `Sorry!! something went wrong while saving ${error?.error}`,
        bannerType: 'error',
        autoClose:true
      }),
    );
  }
}

export function* fetchParsedREditorValues({payload}: any) {
  try {
    const {tenantid} = yield select(getActiveuser);
    const {
      stageData: {data},
      prefixParams: prefix,
    } = payload;
    const {codeBlock, paramList} = yield getParamsToParseREditor(data);
    const url = `${ENDPOINTS.GET_UTILITY_FUNCTION_GRAPHS}?tenantid=${tenantid}&action=custom`;
    const jsonBody: any = {
      codeBlock,
      prefix,
      paramList,
    };
    const response = yield call(callApi, url, {
      method: 'POST',
      mode: 'cors',
      body: JSON.stringify(jsonBody),
    });
    if (response.outputArray) {
      const getCaseConfiguration: any = yield select(selectCaseConfiguration);
      const modifiedData = mergeCaseConfigurationData(getCaseConfiguration, response.outputArray);
      yield put(fetchParsedValueSuccess({data: modifiedData, typeInfo: response.outputArray}));
    } else {
      yield put(fetchParsedValueFailure(response.error || 'Error'));
    }
  } catch (error) {
    console.warn(error);
    yield put(fetchParsedValueFailure(error));
  }
}

export function* getCustomSessionSaga({payload}: any) {
  console.log('payload', payload);
  try {
    const {projectId} = getSessionStorage('projectDetail');
    const {userid, tenantid} = yield select(getActiveuser);
    let url = '';
    const view = GetURLQuery(payload.location.search, 'view') || null;
    if (payload.sid) {
      const sID = `&sessionid=${payload.sid}`;
      url = `${ENDPOINTS.GET_CUSTOM_SESSION_DATA}?projectid=${projectId}&userid=${userid}&tenantid=${tenantid}`;
      url += sID;
    }

    const data: any = yield call(callApi, url);
    if (data) {
      if (view && data?.data?.sessionDetail?.data?.view) {
        data.data.sessionDetail.data.view = view;
      }

      yield put(fetchSuccess_getSession({data:data,isClonedSession:payload?.isClonedSession || false}));
      if (data?.data?.caseConfiguration?.data.length) {
        yield put(updateCasesData({data: data?.data?.caseConfiguration.data}));
      } else {
        // This is a special case: Draft session without case Config data. This occurs when you save a new session & immediately move out of the session.
        // For this special case, we are manually generating & updating the store with case Config data.
        const stageData = yield select(selectStageList);

        // TODO: The below code has been lifted from JSX. It can be optimised at a later date.
        const rows = stageData.data
          .map((el: any) => {
            return el.paramsList.map((el1: any) => ({
              localId: el.localId,
              param: el1,
              rowId: el1,
              caseRowId: `${el.localId}_${el1}`,
            }));
          })
          .flat();

        const newCaseId = nanoid();
        let addedCaseArr = rows.map((el: any) => ({
          accessor: newCaseId,
          name: `Option 1`,
          caseRowId: el.caseRowId,
          value: '',
        }));

        // Update caseCofig data
        yield put(updateCasesData({data: [...addedCaseArr]}));

        // Also update "savedSession" data manually as savedSession contains old data.
        yield put(updateSavedSessionCasesData({data: [...addedCaseArr]}));
      }
      if(payload?.isClonedSession) {
        yield put(updateSessionView({view:'configure'}))
      }
    }
  } catch (error:any) {
    yield put(fetchFailure_getSession(String(error?.error)));
  }
}

const getParamsToParseREditor = (data: any) =>
  data.reduce((acc: any, cur: any, i: number) => {
    acc.codeBlock = i === 0 ? mergeCode(cur.steps) : `${acc.code} \t\n ${mergeCode(cur.steps)}`;
    acc.paramList = i === 0 ? cur.paramsList : [acc.paramList, ...cur.paramsList].flat();
    return acc;
  }, {});

const mergeCode = (data: any) =>
  data.reduce((acc: any, curr: any, i: number) => {
    return i === 0 ? curr.data.code : `${acc} \t\n ${curr.data.code}`;
  }, '');

const matchReg: any = (v1: any, v2: any) : boolean => {
  const regex = new RegExp(`${'^' + v2 + '$'}`);
  return regex.test(`1_${v1}`);
};

export const mergeCaseConfigurationData = (caseConfigurationData: any, rEdtiorData: any) => {
  const caseConfigurationDataSorted = sortBy(caseConfigurationData, ['caseRowId']);
  const edirEdtiorDataSorted = sortBy(rEdtiorData, ['name']);
  // const groupedData = Object.values(groupBy(edirEdtiorDataSorted,'name'))
  // console.log(groupedData,caseConfigurationDataSorted,caseConfigurationDataSorted.map((caseConfiguration: any) => {
  //   const index = groupedData.flat().findIndex((rEdtiorData: any) =>
  //     matchReg(rEdtiorData.name, caseConfiguration.caseRowId),
  //   );
  //   return {
  //     ...caseConfiguration,
  //     ...(index !== -1 && {type: groupedData.flat()[index]}),
  //   };
  // }))

  return caseConfigurationDataSorted.map((caseConfiguration: any) => {
    const index = edirEdtiorDataSorted.findIndex((rEdtiorData: any) =>
      matchReg(rEdtiorData.name, caseConfiguration.caseRowId),
    );
    return {
      ...caseConfiguration,
      ...(index !== -1 && {type: edirEdtiorDataSorted[index]}),
    };
  });
};
