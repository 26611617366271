import React from 'react';
import Modal from 'apollo-react/components/Modal';
import styles from './styles.module.scss';

const PromptModal = React.memo(({open, onLogout, onContinue, countdown}: any) => {
  return (
    <Modal
      open={open}
      variant="warning"
      title="Warning"
      style={{zIndex: 999999}}
      className={styles.autoLogoutModal}
      data-testid="autoLogoutPrompt"
      message={
        <span>
          You will be automatically logged out in <b>{countdown}</b> minutes due to inactivity. Would you like
          to stay logged in and continue working ?{' '}
        </span>
      }
      buttonProps={[
        {label: 'Log Out', onClick: onLogout},
        {label: 'Continue', onClick: onContinue},
      ]}
    />
  );
});

export default PromptModal;
