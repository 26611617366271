import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import  { IConfiguration } from '../../types/CustomDesignTypes';
import { saveCustomSessionSlice } from './SaveCustomSessionSlice';

export const initialState: IConfiguration = {
  data: {
    sessionLabel: '',
    simulations: '',
    randomSeed: '',
    prefixParams: '',
    view: 'configure',
  } as any,
  isConfigured: false,
  isLoading: false,
  isContinueTriggered: false,
  defaults:[],
  checkValidations:{
    simulations: 100000,
    randomSeed: 999999999,
  }
};

export const ConfigurationSlice = createSlice({
  name: 'configuration',
  initialState: initialState as IConfiguration,
  reducers: {
    saveConfiguration: (state: any, action) => {
      const {sessionLabel, simulations, prefixParams, randomSeed} = action.payload;
      state.data = {
        sessionLabel,
        simulations,
        randomSeed,
        prefixParams,
      };
      state.isLoading = false;
      state.isConfigured = true;
    },
    updateSessionLabel: (state: any, action) => {
      const {sessionLabel} = action.payload;
      state.data.sessionLabel = sessionLabel;
    },
    resetSessionConfiguration: (state: any) => {
      state.data = {
        sessionLabel: '',
        simulations: '',
        randomSeed: '',
        prefixParams: '',
        view: 'configure',
      };
      state.isLoading = false;
      state.isConfigured = false;
    },
    fetchcustomParamsRequest: (state: any,action: any) => {
      state.isLoading = true;
    },
    fetchcustomParamsSuccess: (state: any, action: PayloadAction<any>) => {
      const {data} = action.payload;
      const checkValidations = getCheckValidations(data);
      state.defaults = getDefaultValues(data) || [];
      state.checkValidations = {...checkValidations};
      state.isLoading = false;
    },
    fetchcustomParamsFailure: (state: any, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    updateSessionView : (state:IConfiguration, action:PayloadAction<{view:string}>) => {
      state.data.view = action.payload.view;
      state.isConfigured = true;
    },
    updateContinue : (state:IConfiguration, action:PayloadAction<boolean>) => {
      state.isContinueTriggered = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(saveCustomSessionSlice.actions.fetchSuccess_getSession, (state: IConfiguration, action: any) => {
      state.data =  {...action.payload?.data?.data?.sessionDetail.data};
      state.isLoading = false;
      state.isConfigured = action.payload?.data?.data?.sessionDetail.isConfigured
    });
  },
});

export const {
  saveConfiguration,
  updateSessionLabel,
  resetSessionConfiguration,
  fetchcustomParamsRequest,
  fetchcustomParamsSuccess,
  fetchcustomParamsFailure,
  updateSessionView,
  updateContinue
} = ConfigurationSlice.actions;
export default ConfigurationSlice.reducer;


// mapp all the default comon tril params with field name
const getCheckValidations = (data : Array<any>) => {
  return data.reduce((acc:any,el:any) => {
    return {
       ...acc,
       [el.name] : el.checkValidation    
       } 
   },{})
}
const getDefaultValues = (data : Array<any>) => {
  return data.reduce((acc:any,el:any) => {
    return {
       ...acc,
       [el.name] : el    
       } 
   },{})
}
