import { put, takeEvery, call, select } from 'redux-saga/effects';
import { setCurvesList, getCurvesList, getProjectLevelCurves, getProjectLevelCurvesSuccess, getProjectLevelCurvesFailure, updateDuplicateProjectCurveName,updateCurveDoseLevel } from '../slices/ToxicityCurveSlice';
import { ENDPOINTS } from '../../../services/endpoint';
import { callApi } from '../../../services/api';
import { PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../app/store';

export function* watchGetCurvesList() {
  yield takeEvery(getCurvesList, getCurvesListSaga);
}

export function* getCurvesListSaga() {
  try {
    const { data } = yield call(callApi, ENDPOINTS.GET_CURVES_LIST);
    yield put(setCurvesList(data));
  } catch (error) {
    console.warn(error);
    yield put(setCurvesList([]));
  }
}

export function* watchGetProjectCurvesList() {
  yield takeEvery(getProjectLevelCurves, getProjectLevelCurvesSaga);
}

export function* getProjectLevelCurvesSaga(action: PayloadAction<{ sid:string,tenantId: string; projectId: string,userId:string, curveName?:string,isToxEfficacy:boolean }>) {
  try {
    const isEdit = yield select((state:RootState)=> state.toxiCityCurve.isEdit);
    const editRow = yield select((state:RootState)=> state.toxiCityCurve.editedCurveName);
    const efficacyEditRow = yield select((state:RootState)=> state.toxiCityCurve.editRow);
    const sessionCurves = yield select((state:RootState)=>(state.toxiCityCurve.sessionCurves));
    let projLevelDoseAmounts:any = [];
    const { tenantId,projectId,userId, curveName,sid,isToxEfficacy } = action.payload;
    const url = `${ENDPOINTS.GET_PROJECT_LEVEL_CURVES}?tenantid=${tenantId}&projectid=${projectId}&userid=${userId}`;
    const { data } = yield call(callApi,url);
    data.projectLevelCurves.map((el:any)=> (
      projLevelDoseAmounts.push(el?.meta?.crv_dose_amounts?.length || 5)
    ))
    if(curveName && curveName !== '') {
      let projectLevelNames = getCurveNames(isToxEfficacy,isToxEfficacy?data?.projectLevelEfficacyCurves:data?.projectLevelCurves)
      let nonProjectLevelNames = getCurveNames(isToxEfficacy,isToxEfficacy?data?.nonProjectLevelEfficacyCurves:data?.nonProjectLevelCurves)
      let rowNames = isToxEfficacy? [...sessionCurves?.rows].map((curve:any)=> (curve[0].curveName)): [...sessionCurves?.rows].map((curve:any)=> (curve.curveName));
      let nameExists = [...projectLevelNames,...rowNames,...nonProjectLevelNames].find((curveNameCombined:any) => curveNameCombined.replace(/ /g,'').toLowerCase() === curveName.replace(/ /g,'').toLowerCase());
      if(!isEdit) {
        if(!nameExists) {
          yield put(updateDuplicateProjectCurveName(false));
        } else {
          yield put(updateDuplicateProjectCurveName(true));
        } 
      } else {
        const editRowCurveName = isToxEfficacy ?efficacyEditRow[0]?.curveName : editRow?.curveName
        if(curveName.replace(/ /g,'').toLowerCase() === editRowCurveName.replace(/ /g,'').toLowerCase()) {
          yield put(updateDuplicateProjectCurveName(false));
        } else {
          if(!nameExists) {
            yield put(updateDuplicateProjectCurveName(false));
          } else {
            yield put(updateDuplicateProjectCurveName(true));
          }
        }
      }
    } else {
      yield put(getProjectLevelCurvesSuccess({data:data,sid:sid}));
      yield put(updateCurveDoseLevel(projLevelDoseAmounts.length > 0 ? Math.max(...projLevelDoseAmounts):5))
    }
  } catch (error) {
    console.warn(error);
    yield put(getProjectLevelCurvesFailure([]));
  }
}

const getCurveNames = (isToxEfficacy: any, curves: any) => {
  return isToxEfficacy
    ? curves?.map((curve: any) => curve[0].curveName)
    : curves.map((curve: any) => curve.curveName);
};