import React, {useState} from 'react';
import Tab from 'apollo-react/components/Tab';
// TODO: Hide the filters functionality on projects table for workspace admin since it this functionality is not implemented
// import FilterIcon from 'apollo-react-icons/Filter';
// import Undo from 'apollo-react-icons/Undo';
// import Button from 'apollo-react/components/Button';
import Tabs from 'apollo-react/components/Tabs';
import { TenantAdminTable } from '../Table/TenantAdmin';
import styles from './Tabs.module.scss';
import { IFilters } from '../../../../common/components/TableFilters/types';

function TenantAdminTabs({value, tab, handleChangeTab}: any) { 
  const [showFilters, setShowFilters] = useState<boolean>(false);
  const [tableKey] = useState(0)
  const [filters, setFilters] = useState<IFilters>();

  // TODO: Hide the filters functionality on projects table for workspace admin since it this functionality is not implemented
  // const clearFilters = () => {
  //   setTableKey(tableKey => tableKey+1)
  //   setFilters(undefined);
  // }

  return (
    <>
      {
      // TODO: Hide the filters functionality on projects table for workspace admin since it this functionality is not implemented
      /* <div className={styles.buttonContainer}>
        {filters && <Button variant="secondary" icon={<Undo />} style={{marginRight: 10}} onClick={clearFilters} data-testid="clear">
          Clear
        </Button>}
        <Button variant="secondary" icon={<FilterIcon />} style={{marginRight: 10}} onClick={() => setShowFilters(!showFilters)} data-testid="filters">
          Filters
        </Button>
      </div> */}
      <div id='tabs' className={styles.tabsContainer}>
        <Tabs
          value={value}
          onChange={handleChangeTab}
          truncate
          className={styles.tabsContent}
        > 
          <Tab label='Projects' />
        </Tabs>

        <div style={{ textAlign: 'center' }}>
          {value === 0 && <TenantAdminTable tab={tab} showFilters={showFilters} setShowFilters={setShowFilters} tableKey={tableKey} filters={filters} setFilters={setFilters} />}
        </div>
      </div>
    </>
  );
}

export default TenantAdminTabs;
