import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import { mergeCaseConfigurationData } from '../../sagas/customDesignSags';

export const initialState: any = {
  isLoading: false,
  error: '',
  data: [],
  isLaunchSimulation: false,
  isCellEmpty: false,
  isAccesorMissing: false,
  updateData: false,
  typeInfo: []
};

export const caseConfigurationSlice = createSlice({
  name: 'caseConfiguration',
  initialState: initialState as any,
  reducers: {
    updateCasesData: (state: any,  action: PayloadAction<any>) => {
      if(action.payload.noColumn){
        state.data = mergeCaseConfigurationData(action.payload?.data,state.typeInfo);
      } else{
        state.data =  action.payload?.data;
      }
    },
    resetCasesData: (state: any) => {
      state.data =  [];
    },
    requestParsedValues: (state: any, action: PayloadAction<any>) => {
      state.isLoading = true;
    },
    fetchParsedValueSuccess: (state: any, action: PayloadAction<any>) => {
      state.error = '';
      state.data = action.payload.data;
      state.typeInfo = action.payload.typeInfo;
      state.updateData = true;
      state.isLoading = false;
    },
    fetchParsedValueFailure: (state: any, action: PayloadAction<any>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    casesTableRows: (state: any,  action: PayloadAction<any>) => {
      const tab =  action.payload?.tableRow;
      const colLength =  action.payload?.col?.length;
      const isEmpty= tab.some((elem:any)=> Object.values(elem).includes(''));
      const allAccessor= tab.every((elem:any)=> Object.keys(elem).length === colLength+6);
      state.isCellEmpty =  isEmpty ;
      state.isAccesorMissing= !allAccessor;
    },
    updateStateData: (state: any) => {
      state.updateData = false;
    },
  },
});

export const {
  updateCasesData,
  resetCasesData,
  casesTableRows,
  requestParsedValues,
  fetchParsedValueSuccess,
  fetchParsedValueFailure,
  updateStateData,
} = caseConfigurationSlice.actions;
export default caseConfigurationSlice.reducer;

