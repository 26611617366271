export const DOSE_ESC_CONSTANTS: any = {
  DESIGN_OPTIONS: {
    THREEPLUSTHREE: '3+3',
    I3PLUS3: 'i3+3',
    MPLUSN: 'm+n',
    CRM: 'CRM',
    BOIN: 'BOIN',
    mTPI2: 'mTPI-2',
    REDOS: 'REDOS',
    BLRM: 'BLRM',
    PRINTE: 'PRINTE'
  },
  ENDPOINTS: {
    TOX: 'TOX',
    TOXEFFICACY: 'TOXEFFICACY'
  },
  DESIGN_BY_ENDPOINT: {
    "TOX": ['3+3', 'i3+3', 'BOIN', 'mTPI-2', 'm+n'],
    "TOXEFFICACY": ['PRINTE']
  },
  TRIAL_DURATION_FIELDS: {
    "TOX": ['untilFirstCohortStarts', 'cohortRecruitmentTime', 'dltWindow', 'timeBetweenCohorts' ,'trialWrapUp'],
    "TOXEFFICACY": ['untilFirstCohortStartsToxEff', 'cohortRecruitmentTimeToxEff', 'dltWindowToxEff', 'timeBetweenCohortsToxEff', 'trialWrapUpToxEff']
  },
  DEFAULT_DOSE_LEVEL: 5,
  MIN_DOSE_LEVEL : 1,
  MAX_DOSE_LEVEL : 21,
  CANCEL_SIMULATION : "Cancel simulation and return to input page",
  DEFAULT_TARGET_2ND_PARAM_PROB: 0.6,
  DEFAULT_TARGET_INHIBITION_2ND_PARAM_PROB: 0.8,
  INHIBITION_MEASURE: 'Inhibition',
  STATIC_FIELD_INFO: {
    '3+3': {
      name: '',
      label:
        '3+3 settles within a range between 16% and 30% toxicity probability with a bias towards 16% - 20% depending upon the dose levels selected due to only allowing escalation when there are 1/6 or 0/3 DLTs for the current dose. This can vary depending on the exact shape and probabilities chosen for the toxicity curve',
    },
    'm+n':{
      name: '',
      label: 'm+n design is independent of given target toxicity probability. m+n shall have a peak chosen MTD based on chosen cohort size for m and n and associated chosen escalation rules. Further investigation will be needed to find the exact settling point for the given parameters',
    }
  },
  DEFAULT_DESIGN_OPTIONS_TOOLTIP: ['3+3', 'm+n'],
  EXPORT_DELAY_TIME: 2000,
  NO_DELAY_EXPORT: 10
};