import { RootState } from '../../../app/store';
import { ProjectUsersType } from '../types/ProjectListType';

export const selectProjectList = (state: RootState) => state.projects.data;
export const selectProjectTotalCount = (state: RootState): number =>
  state?.projects?.totalRecords?.count;
export const selectAvailableProjectOwners = (state: RootState): ReadonlyArray<ProjectUsersType> =>
  state?.projects?.availableProjectOwners;
export const selectProjectCurrentPage = (state: RootState) =>
  state?.projects.pageIndex;
  
export const selectProjectDeisgners = (state: RootState) => {
  // return state.projects.projectDesigners.map((user: any) => ({label: `${user.firstName} ${user.lastName} <${user.emailId}>`, id: `${user.userId}`}));
  return state.projects.projectDesigners.map((user: any) => ({
    label: `${user.firstname} ${user.lastname} <${user.emailid}>`,
    name: `${user.firstname} ${user.lastname}`,
    value: `${user.userId}`
  })
)};

export const userMessage = (state: RootState) => state.projects.userMessage;