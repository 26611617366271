import {put, takeEvery, call, select} from 'redux-saga/effects';
import {fetchRequest, fetchSuccess, fetchFailure, postGraphsRequest, postGraphsSuccess, postGraphsFailure, deleteGraphRequest,deleteGraphSuccess,deleteGraphFailure } from '../../slices/customDesignSlice/GenerateGraphsSlice';

import {ENDPOINTS} from '../../../../services/endpoint';
import {callApi} from '../../../../services/api';
import {getActiveuser} from '../../selectors/userDetailsSelector';
import { omit } from 'lodash';

export default function* watchCustomDesignSagas() {
  yield takeEvery(fetchRequest, getGraphsData);
  yield takeEvery(postGraphsRequest, addGraphs);
  yield takeEvery(deleteGraphRequest, deleteGraphs);
}

export function* getGraphsData({payload}: any) {
  try {
    const {tenantid, userid} = yield select(getActiveuser);
    const {sid, projectId} = payload;
    const url = `${ENDPOINTS.GET_CUSTOM_GRAPH_DATA}?sessionid=${sid}&tenantid=${tenantid}&userid=${userid}&projectid=${projectId}`;
    const result = yield call(callApi, url);
    yield put(fetchSuccess(result));
  } catch (error :any) {
    console.warn(error);
    yield put(fetchFailure(error?.error));
  }
}

export function* addGraphs({payload}: any) {
  try {
    const {tenantid, userid} = yield select(getActiveuser);
    const {sessionId: sid, projectId, data: postData} = payload;
    const { graphId, graphData } = postData;
    const isEditedData = graphId !== 'new';
    const addNewGraphUrl = `${ENDPOINTS.POST_CUSTOM_GRAPH_DATA}?sessionid=${sid}&tenantid=${tenantid}&userid=${userid}&projectid=${projectId}`;
    const updateGraphUrl = `${ENDPOINTS.UPDATE_CUSTOM_GRAPH_DATA}?sessionid=${sid}&tenantid=${tenantid}&userid=${userid}&projectid=${projectId}&graphid=${graphId}`;
    const data = yield call(callApi, (isEditedData ? updateGraphUrl : addNewGraphUrl), {
      method: isEditedData ? 'PUT' : 'POST',
      mode: 'cors',
      body: JSON.stringify({generatedGraphData : omit(graphData, 'disabled')}),
    });
    yield put(postGraphsSuccess(data));
  } catch (error:any) {
    console.warn(error);
    yield put(postGraphsFailure(error?.error));
  }
}

export function* deleteGraphs({payload}: any) {
  try {
    const {tenantid, userid} = yield select(getActiveuser);
    const {sessionId: sid, projectId, graphId} = payload;
    const url = `${ENDPOINTS.REMOVE_CUSTOM_GRAPH_DATA}?sessionid=${sid}&tenantid=${tenantid}&userid=${userid}&projectid=${projectId}&graphid=${graphId}`;
    const data = yield call(callApi, url, {
      method: 'PUT',
      mode: 'cors',
      body: JSON.stringify({ graphId }),
    });
    yield put(deleteGraphSuccess(data));
  } catch (error : any) {
    console.warn(error);
    yield put(deleteGraphFailure(error?.error));
  }
}
