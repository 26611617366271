import React, {useEffect, useMemo} from 'react';
import {useHistory} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import Typography from 'apollo-react/components/Typography';
import Loader from 'apollo-react/components/Loader';
import Button from 'apollo-react/components/Button';
import welcomeImage from '../../../features/loginPage/components/ProjectWelcome/welcome.jpg';
import styles from './styles.module.scss';
import {CONSTANTS} from '../../constants';
import {resetUserError} from '../../../features/projects/slices/userDetailsSlice';
import {logOutRequest} from '../../../features/loginPage/slices';
import {RootState} from '../../../app/store';

const AccessDenied = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const isLoading = useSelector((state: RootState) => state.authInfo.isLoading);
  const isRedirectedFromAutoLogout = useMemo(() => history.location?.state === 'autologout', [history]);
  const returnToLogin = () => {
    dispatch(resetUserError());
    history.push('/');
  };

  useEffect(() => {
    !isRedirectedFromAutoLogout && dispatch(logOutRequest());
  }, [dispatch, isRedirectedFromAutoLogout]);

  const isLogout = useMemo(() => history.location.pathname.includes('logout'), [history]);
  return isLoading ? (
    <Loader />
  ) : (
    <div className={styles.unauthorizedContainer} data-testid="access-denied-container">
      <div className={styles.imageContainer}>
        <img src={welcomeImage} alt={'Access Denied'} className={styles.image} />
      </div>
      <div className={styles.loginContainer}>
        <div className={styles.container}>
          <Typography variant="body1" className={styles.heading}>
            {CONSTANTS.LABELS.IQVIA_TRIAL_DESIGNER}
          </Typography>
          <AccessRestrictedMessage isUserLogOut={isLogout} isAutoLogout={isRedirectedFromAutoLogout} />
          <Button
            variant="text"
            size="small"
            className={styles.returnBtn}
            onClick={returnToLogin}
            data-testid="return-to-login"
          >
            {CONSTANTS.LABELS.RETURN_LOGIN}
          </Button>
          {/* <Typography variant="body1" className={styles.footer}>
            {CONSTANTS.LABELS.COPYRIGHT}
          </Typography> */}
        </div>
      </div>
    </div>
  );
};

type AccessRestrictedMessageProps = {
  isUserLogOut?: boolean;
  isAutoLogout?: boolean;
};

const AccessRestrictedMessage = ({isUserLogOut = false, isAutoLogout = false}: AccessRestrictedMessageProps) => {
  if(isAutoLogout){
    return <Typography className={styles.content}>{CONSTANTS.LABELS.ACCESS_AUTO_LOGOUT}</Typography>;
  } else if (isUserLogOut) {
    return <Typography className={styles.content}>{CONSTANTS.LABELS.ACCESS_LOGOUT}</Typography>;
  } else {
    return <Typography className={styles.content}>{CONSTANTS.LABELS.ACCESS_RESTRICTED}</Typography>;
  }
};

export default AccessDenied;
