import {createSelector} from '@reduxjs/toolkit';
import {isEqual, omit} from 'lodash';
import {RootState} from '../../../app/store';
import {IConfiguration, IStage, StageSliceType} from '../types/CustomDesignTypes';

export const selectConfiguration = (state: RootState): IConfiguration => {
  return state.customDesign.configuration;
};
export const selectStageList = (state: RootState): StageSliceType => {
  return state.customDesign.stage;
};

export const selectCaseConfiguration = (state: RootState) => {
  return state.customDesign.caseConfiguration.data;
};

export const selectDependencyList = (state: RootState): Array<{libraryId: number; libraryName: string}> => {
  return state.customDesign.dependencyLibrary.data;
};

export const selectSavedSessionData = (state: RootState): any => {
  return state.customDesign.savedSession;
};

export const selectStepStatus = (stepIndex: number | any, stageIndex: number | any) => {
  return createSelector(selectStageList, (data) => {
    if (stageIndex > -1 && stepIndex > -1) {
      return data.data[stageIndex]?.steps[stepIndex]?.status;
    }
  });
};

export const selectActiveStage = createSelector(selectStageList, (stageData) => {
  const sIndex = stageData.data.findIndex((el: IStage) => el.active);
  const stIndex = stageData.data[sIndex]?.steps.findIndex((el: any) => el.active);

  return {
    activeStageIndex: sIndex,
    activeStepIndex: stIndex,
    code: stageData?.data[sIndex]?.steps[stIndex]?.data?.code,
    paramsList: stageData?.data[sIndex]?.paramsList,
    steps: stageData?.data[sIndex]?.steps,
    defaultPrefix: stageData.defaultPrefix,
  };
});

export const selectStagesComplete = createSelector(selectStageList, (stageData) => {
  return stageData.data.every((el: IStage) => el.isStageComplete);
});

export const selectStepsAtStage = (stageIndex: number) => {
  return createSelector(selectStageList, (data) => {
    return stageIndex > -1 ? data.data[stageIndex]?.steps : [];
  });
};

export const selectStepAtStageAndStep = (stageIndex: number | any, stepIndex: number | any) => {
  return createSelector(selectStageList, (data) => {
    return data?.data[stageIndex]?.steps[stepIndex];
  });
};

export const selectResults = (state: RootState) => {
  return state.customDesign.results;
};

export const selectViewGraphs = (state: RootState) => {
  return state.customDesign.graphs;
};

export const selectIsSessionDataEqual = (values: any) =>
  createSelector(
    selectConfiguration,
    selectStageList,
    selectSavedSessionData,
    selectCaseConfiguration,
    (sessionDetail, stageData, savedSession,caseConfiguration) => {
      let sessionData = omit({...sessionDetail?.data, ...values}, ['view']);
      let savedSessionData = omit(savedSession?.data?.sessionDetail?.data, ['view']);
      let caseConfigurationData =caseConfiguration.map((el:any) => omit(el, ['dataTypeInfo']))
      const configEqual = isEqual(sessionData, savedSessionData);
      const StagesEqual = isEqual(stageData?.data, savedSession?.data?.stages?.data);
      const caseEqual = isEqual(Object.values(caseConfigurationData), savedSession.data.caseConfiguration.data);
      return configEqual && StagesEqual && caseEqual;
    },
  );

  export const selectCaseConfigurationOptions = (state: RootState): any => {
    return state.customDesign.caseConfiguration;
  };

  export const selectActiveTab = (state:RootState):any => {
    return state.customDesign.stage?.activeStageTab || 0
  }