import { pickBy, isEmpty } from 'lodash';
import { ToxicityCurve } from '../../features/projects/types/SessionTypes';
import { TOXICITYCURVE_ALLOWED_KEY_CODES,CONSTANTS } from '../constants';

export function validateDupCurves(curveName: string, rows: any) {
  let findData = null;
  const index = curveName.indexOf('(');
  curveName = index > 0 ? curveName.slice(0, index).trim() : curveName.trim();

  if (curveName && rows.length) {
    findData = rows.find((el: any) => {
      return el.curveName.toLowerCase() === curveName.toLowerCase();
    });
  }

  return isEmpty(findData);
}

export type curveListType = {
  curve_id: string;
  curve_type: string;
};
const curveList: curveListType[] = [
  { curve_id: '1', curve_type: 'Empirical' },
  { curve_id: '2', curve_type: 'Linear' },
  { curve_id: '3', curve_type: 'Quadratic' },
  { curve_id: '4', curve_type: 'Hill' },
  // { curve_id: '5', curve_type: 'Logistic' },
  // { curve_id: '6', curve_type: 'Exponential Saturation' },
];

export function filterObjByRegEx(data: any, reg: any) {
  return pickBy(data, function (value, key) {
    if (reg.test(key) && !isEmpty(value?.toString())) {
      return key;
    }
  });
}

export function getCurveList({ values }: any): curveListType[] {
  const doseAmounts: [] = values?.doseAmounts;
  const isDoseValid = doseAmounts.every((el: any) => el || !!el === true);
  return isDoseValid ? curveList : curveList.slice(0, 1);
}


export const sortSesssionCurves = (rows:ToxicityCurve[]):ToxicityCurve[] => {
 const currentSessionCurves = rows.filter((row:ToxicityCurve)=> row.projSessionId === 'Current');
 const carryForwardedCurves = rows.filter((row:ToxicityCurve)=> row.projSessionId !== 'Current');
 return [...currentSessionCurves,...carryForwardedCurves]

}

/**Function to handle the three decimal restriction on toxicity curves text boxes
 * This is being achieved through the KeyDown handler on those text boxes,
 * which gives us the key codes
 * The boolean value being returned is a combination of checking 3 conditions
 * 1)value is not empty
 * 2)Splitting on decimal if array length is 1 return false,
 * else check if the 1st index of the split array is greater than or equal to max length
 * 3)Checking the keycode conditions against the allowed key codes
 * 4)if start === end then the cursor is selecting only one character so allow to be overidden
 * 5)Allow Ctrl+A
 */
 export const handleKeyDownUtil = (value: string, start: number, end: number, keyCode: number) => {
  return (
    value !== '' &&
    (value.toString().split('.').length === 1
      ? false
      : value.toString().split('.')[1].split('').length >= TOXICITYCURVE_ALLOWED_KEY_CODES.MAX_LENGTH) &&
    keyCode !== TOXICITYCURVE_ALLOWED_KEY_CODES.BACKSPACE &&
    keyCode !== TOXICITYCURVE_ALLOWED_KEY_CODES.TAB_KEY &&
    keyCode !== TOXICITYCURVE_ALLOWED_KEY_CODES.DELETE &&
    keyCode !== TOXICITYCURVE_ALLOWED_KEY_CODES.ARROW_LEFT &&
    keyCode !== TOXICITYCURVE_ALLOWED_KEY_CODES.ARROW_RIGHT &&
    !(keyCode === TOXICITYCURVE_ALLOWED_KEY_CODES.CTRL || keyCode === TOXICITYCURVE_ALLOWED_KEY_CODES.A) &&
    !(start === TOXICITYCURVE_ALLOWED_KEY_CODES.START && end === TOXICITYCURVE_ALLOWED_KEY_CODES.END) &&
    start === end
  );
};

export function getEfficacyCurveList() {
  return [...curveList,{ curve_id: '5', curve_type: 'Beta' }]
}

export function getRoundedValue(value:number)  {
  return +value.toPrecision(4).replace(/^0+(\d)|(\d)0+$/gm, '$1$2');
}

export function checkIfToxValuesAreIncremental(toxValues:any,maxDose:number|string|any) {
  let rowsData:any = Array.from({ length:maxDose }, (v, i) => i).map((obj) => ({
    key: 'd'+(obj + 1), value:toxValues['d'+(obj + 1)] }
  ));
  let isIncremental: boolean  = rowsData.every((num:any, i:number) => i === rowsData.length - 1 || num['value'] <= rowsData[i + 1]['value']);
  let isLessThanZero: boolean  = rowsData.some((num:any, i:number) => num['value'] <= 0 || num['value'] >= 1);
  return {
    isIncremental,isLessThanZero
  }
}
export const validateYValues = (probabilityValues:any,maxDose:number|string|any) => {
  const toxProbValidity = checkIfToxValuesAreIncremental(probabilityValues[0],maxDose);
  const effProbValidity = checkIfToxValuesAreIncremental(probabilityValues[1],maxDose);
  return {
    isToxProbValid:toxProbValidity?.isIncremental && !toxProbValidity?.isLessThanZero,
    isEffProbValid:!effProbValidity?.isLessThanZero
  }
}

export const formEfficacyPreviewPayload = (curves:Array<ToxicityCurve>) => {
  const curveDoseAmounts = curves[0].meta.crv_dose_amounts;
  const curvePayload:Array<any> =  curves.map((curve: any) => {
    let doseAmounts = filterObjByRegEx(curve, /^d/);
    return {...doseAmounts}
  })
  return curveDoseAmounts.map((doseAmount:string|any,index:number)=>({
    x:doseAmount,
    y:curvePayload[0][`d${index+1}`],
    y1:curvePayload[1][`d${index+1}`]
  }))
}

export const generateEquationParamLabel = (activeTab: number) =>
  activeTab ? CONSTANTS.LABELS.EFFECTIVENESS : CONSTANTS.LABELS.TOXICITY_PROB; 