import { roundNumber } from '../../../../../../../../common/utils';

export const getCumulativeSpent = (interimAnalysis:Array<number>| undefined, typeError: number) =>{
  return(
    interimAnalysis?.length && interimAnalysis?.map((data: any)=>roundNumber(data*Number(typeError),8))
  )
}

export const getTotalSpent = (cumulativeSpent: any) => {
  return cumulativeSpent?.reduce((acc:any, current:any, index:number) =>{
    if(index === 0){
      return [current-0,...acc]
    }
    else{
      return [...acc,roundNumber(current-cumulativeSpent[index-1],8)]
    }
},[])
}

// Monotonically continously increasing order
// Slot(n) = Slot(n-1) + Slot 1
export const getCumulativeAlphasHayPeto =  (firstAlpha: any, interimAnalysesPlanned: any) => {
  const val = Number(firstAlpha);
  const cumulativeAlphaHaloPetos = Array.from({length: interimAnalysesPlanned}, (v, i) => i).map((el: any, i: number) => {
    const calculatedVal =  roundNumber((val + val*i), 8) ;
    return calculatedVal;
  });
  return cumulativeAlphaHaloPetos;
};