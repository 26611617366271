import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {DocumentState} from '../types/ProjectMemberTypes';

const initialState : DocumentState = {
    loading: false,
    documents:[],
    error:{},
}

export const projectDocumentSlice = createSlice({
    name:'projectDocuments',
    initialState: initialState as DocumentState,
    reducers:{
        uploadDocument:(state,action:PayloadAction<any>) => {
            state.loading = true;
        },
        setDocuments:(state,action:PayloadAction<any>) => {
            state.documents = action.payload;
        },
        toggleLoading:(state,action:PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
        getProjectDocuments:(state,action:PayloadAction<any>) => {
            state.loading = true;
        },
        handleError:(state,action:PayloadAction<any>) => {
            state.error = action.payload;
        },
        reset:(state) => {
            state.documents= [];
            state.error = {};
            state.loading = false;
        },
        deleteProjectDocument:(state,action:PayloadAction<any>) => {
            state.loading = true;
        },
        fetchDownloadURL:(state,action:PayloadAction<any>) => {
            state.loading = true;
        },
    }
});

export const {
    setDocuments,
    uploadDocument,
    toggleLoading,
    getProjectDocuments,
    handleError,
    reset,
    deleteProjectDocument,
    fetchDownloadURL
} = projectDocumentSlice.actions
export default projectDocumentSlice.reducer;