import { combineReducers } from "@reduxjs/toolkit";
import StageReducer from './StageSlice';
import ConfiguredRedcuer from './ConfigurationSlice';
import DependencyLibraryReducer from './DependencyLibrarySlice';
import savedSessionReducer from './SaveCustomSessionSlice';
import generateGraphsReducer from './GenerateGraphsSlice';
import resultsReducer from './ResultsSlice';
import caseConfigurationReducer from './CaseConfigurationSlice';

export default combineReducers({
    stage:StageReducer,
    configuration:ConfiguredRedcuer,
    dependencyLibrary:DependencyLibraryReducer,
    savedSession:savedSessionReducer,
    graphs: generateGraphsReducer,
    results: resultsReducer,
    caseConfiguration: caseConfigurationReducer,
  })