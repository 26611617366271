export const GS_CONSTANTS = {
  CDL_LABEL: "Chen-DeMets-Lan (CDL)",
  CDL_HELPTEXT: `Allows sample size re-estimation at final analysis based on penultimate(last second) look data and the conditional power.
  Conditional power at penultimate look must be greater than 50% to increase the sample size at final analysis.
  Mehta and Pocock (2011) modified this approach by allowing users to specify minimum and maximum(target) conditional power to define different zones.`,
  CHW_LABEL: "Cui-Hung-Wang (CHW)",
  CHW_HELPTEXT: `
  Allows modifying sample size at any interim. 
  Uses weighted statistic instead of conventional statistic to preserve Type-I error. 
  At each interim, standard deviation / treatment difference / proportions / hazard ratio can be modified to obtain new value of statistic called CHW statistic.`,
  DIFFMEANS_FIELD_TYPES : ['stdDevForCtrlDiffmeans', 'stdDevForTrtDiffmeans', 'underH1DiffMeans', 'hazardRatioTTE'],
  ESTIMATED_VAL_TYPE_CONFIG: {
    "DIFFMEANS": [{
      parentFieldName: 'StandardDeviationForControlDIFFMEANS',
      fieldName: 'stdDevForCtrlDiffmeans',
      rowName: "Standard Deviation for Control"
    },
    {
      parentFieldName: 'StandardDeviationForTreatmentDIFFMEANS',
      fieldName: 'stdDevForTrtDiffmeans',
      rowName: "Standard Deviation for Treatment"
    },
  ],
    "TTE": [{
      parentFieldName: 'hazardRatioUnderH1',
      fieldName: 'hazardRatioTTE',
      rowName: "Hazard Ratio H1"
    }],
    "DIFFBINOM": [{
      parentFieldName: 'PITGroupPT',
      fieldName: 'proportionOfTGDiffBinom',
      rowName: "Proportion of Treatment Group(Pₜ)"
    },
    {
      parentFieldName: 'PITGroupPC',
      fieldName: 'proportionOfCGDiffBinom',
      rowName: "Proportion in Control Group (Pc)"
    }]
  },
  TYPES_OF_TRIAL_FIELDS_SSR: {
    equivalence :  [{
      parentFieldName: 'nonInferiorityExpectedDifference',
      fieldName: 'underH1DiffMeans',
      rowName: "Expected Difference (δ₁)"
    }],
    noninferiority : [{
      parentFieldName: 'nonInferiorityExpectedDifference',
      fieldName: 'underH1DiffMeans',
      rowName: "Expected Difference (δ₁)"
    }],
    "non-inferiority" :[{
      parentFieldName: 'nonInferiorityExpectedDifference',
      fieldName: 'underH1DiffMeans',
      rowName: "Expected Difference (δ₁)"
    }],
    superiority : [{
      parentFieldName: 'DiffinMeansUnderH1',
      fieldName: 'underH1DiffMeans',
      rowName: "Difference between the Observed Control & Treatment"
    }]
  },

  SPENDING_FUNCTIONS: {
    HAYBITTLE_PETO_FUNC: 'Haybittle Peto functions',
    USER_DEFINED_FUNC: 'User Defined Function'
  }
};


export const SSR_TTE = {
  INFO_DESC:'System currently recalculates the number of events as a method to re-estimate subjects. Accrual and trial duration are also calculated using Accrual Rates from GS design result for the time-to-event endpoint',
  SSR_NO_GRAPH: {
    INFO:' Note that the Sample Size/Events did not re-estimate compared to original design and the promising zone graph could not be generated .This may be due to following reasons:',
    REASONS: [
      'The Trial may have stopped early, before reaching the penultimate(in CDL)/specified (in CHW) look.',
      'The calculated conditional power of the penultimate(in CDL)/specified (in CHW) look may be out of range of Min. Acceptable Conditional Power & Max. (Target) Conditional Power.',
      'Sometimes the New True Value entered at interims, not being close to the original trial design input parameters can also cause no Sample Size/Events re-estimation.'
    ]
  }
}