import moment from 'moment';
import { tz } from 'moment-timezone';

const formatIsoDate = (isoDate: string, format: string='DD-MMM-YYYY') => {
    if(!isoDate){
        return;
    }
    const date = moment(isoDate);
    return date.utc().format(format).toUpperCase();
}

const formatDate = (date: any, format: string='DD/MM/YYYY') => {
    if(!date){
        return;
    }    
    return moment(date).format(format).toUpperCase();
}

const formatDateManageProjectMembers = (date: any, format: string='DD-MMM-YYYY') => {
    if(!date){
        return;
    }    
    return moment(date).format(format);
}
const formatResultDate = (date: any, format: string='DD-MMM-YYYY, h:mm A') => {
    let sone =  tz.guess();
    let timezone = tz(sone).zoneAbbr();
    let now = moment(date).format(format);
    let res = now.split(',');
    res.splice(1,0,'at')
    res.splice(3,0,timezone)
    return res.join(' ');
}

const getDateFormat = (date: any) => {
    return moment(date).format('DD-MMM-YYYY').toUpperCase();
}

// Method to check if date is the past OR is atleast today.
// This is exclusively for Date pcikers & not Datetime objects.
export const isPastDate = (dateVal: any) => {
  const pickerDate = moment(dateVal, "DD.MM.YYYY");
  const currentDate = moment(new Date(new Date().setHours(0, 0, 0, 0)), "DD.MM.YYYY"); // Set it to 00:00:00 as Datepicker gives on Date.
  return (pickerDate < currentDate);
};

export  { formatDate, formatIsoDate, formatResultDate, formatDateManageProjectMembers, getDateFormat };
