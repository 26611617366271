export const getSessionStorage = (key: string, isToBeParse = true) => {
  const sessionStorageValue = sessionStorage.getItem(key);
  return sessionStorageValue ? isToBeParse ? JSON.parse(sessionStorageValue) : sessionStorageValue : null;
};

export const removeSessionStorage = (key?: string) => {
  return key ? sessionStorage.removeItem(key) : sessionStorage.clear();
};

export const addSessionStorage = (key: string, value: any) => {
  return sessionStorage.setItem(key, value);
};
