import React from 'react';

import Button from 'apollo-react/components/Button';
import Help from 'apollo-react-icons/Help';
import Box from '@mui/material/Box';
import {CONSTANTS} from '../../../../../common/constants';
import styles from './LoginContainer.module.scss';
import {reportAnIssue} from '../../../../../common/utils';

interface Props {
  handleLogin: Function;
  handleLoginSSO: Function;
}

const LoginContainer = ({handleLoginSSO}: Props) => {
  return (
    <>
      <div className={styles.container}>
        <div className={styles.heading}>{CONSTANTS.LABELS.IQVIA_TRIAL_DESIGNER}</div>
        <div className={styles.subHeading}>S3 cloud-based SaaS solution for clinical trial design</div>
        <p>
          The system offers a broad range of design options, statistical models, and simulations that
          statisticians can use for fast exploration and development of novel trial designs.
        </p>
        <p>
          It also includes a user-friendly interface with graphs, charts, and collaboration features that
          enable statisticians, clinicians, and other stakeholders to work together easily.
        </p>

        <Box display="flex" alignItems="flex-start" flexDirection="column" width="75%">
          <Button variant="primary" fullWidth size="small" onClick={handleLoginSSO}>
            Login
          </Button>
          <div className={styles.supportSection}>
            <Button size="small" icon={Help} onClick={reportAnIssue}>
              {`${CONSTANTS.LABELS.REPORT_AN_ISSUE} (IQVIA User)`}
            </Button>
          </div>
        </Box>
      </div>
    </>
  );
};

export default LoginContainer;
