import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type CommentType = {
  commentID: number;
  commentedOn: string;
  comments: string;
  userName: string;
};

type UsersSuggestion = {
  userID: number;
  name: string;
}

type LoadMoreComments = {
  isLoading: boolean;
  error?: any;
}

export interface ProjectCommentState {
  data: Array<CommentType>;
  isLoading: boolean;
  error?: any;
  totalRecords: number;
  currentPage: number;
  usersSuggestion: Array<UsersSuggestion>
  loadMore: LoadMoreComments;
  type?: string;
}

export const initialState: ProjectCommentState = {
  data: [],
  isLoading: false,
  error: '',
  totalRecords: 0,
  currentPage: 1,
  usersSuggestion: [],
  loadMore: {
    isLoading: false,
    error: '',
  }
};

export const projectCommentSlice = createSlice({
  name: 'projectComments',
  initialState: initialState as ProjectCommentState,
  reducers: {
    fetchRequest: (state: ProjectCommentState, action) => {
      state.isLoading = true;
    },
    loadMoreComments: (state: ProjectCommentState, action) => {
      state.loadMore.isLoading = true;
    },
    fetchSuccess: (
      state: ProjectCommentState,
      action: PayloadAction<ProjectCommentState>
    ) => {
      const {
        payload: { data, totalRecords, currentPage, type = '' },
      } = action;
      const reverseResult = [...data].reverse()
      state.data = [...reverseResult, ...state.data];
      state.totalRecords = totalRecords;
      state.currentPage = currentPage;
      type.includes('loadMoreComments') ? state.loadMore.isLoading = false : state.isLoading = false; 
    },
    fetchFailure: (state: ProjectCommentState, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    loadMoreCommentsFailure: (state: ProjectCommentState, action) => {
      state.loadMore.isLoading = false;
      state.loadMore.error = action.payload;
    },
    fetchPostRequest: (state: ProjectCommentState, action) => {
      state.isLoading = true;
      state.error = action.payload;
    },
    fetchPostSuccess: (state: ProjectCommentState, action) => {
      const data = action.payload.data[0];
      state.data = [...state.data, data];
      state.isLoading = false;
    },
    fetchPostError: (state: ProjectCommentState, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    fetchAllSuggestions: (state: ProjectCommentState, action) => {
      state.isLoading = true;
    },
    fetchAllSuggestionsSuccess: (state: ProjectCommentState, action) => {
      state.usersSuggestion = [...action.payload.data];
      state.isLoading = false;
    },
    fetchAllSuggestionsError: (state: ProjectCommentState, action) => {
      state.usersSuggestion = [];
      state.isLoading = false;
    },
    resetComments: (state: ProjectCommentState) => {
      state.data = [];
      state.isLoading = false;
      state.error = '';
    },
  },
});

export const {
  fetchRequest,
  fetchFailure,
  fetchSuccess,
  fetchPostRequest,
  fetchPostSuccess,
  fetchPostError,
  resetComments,
  fetchAllSuggestions,
  fetchAllSuggestionsSuccess,
  fetchAllSuggestionsError,
  loadMoreComments,
  loadMoreCommentsFailure,
} = projectCommentSlice.actions;
export default projectCommentSlice.reducer;
