import React from 'react';
import MenuItem from 'apollo-react/components/MenuItem';
import { FormikSelect } from '../../../../common/components/formik-controls/FormikComponents';
import { CONSTANTS } from '../../../../common/constants';
import { useSelector } from 'react-redux';
import { getObjectivesSelector } from '../../selectors/CreateProjectSelectors';

export default function Objectives() {
  const objectives = useSelector(getObjectivesSelector);

  return (
    <FormikSelect
      name='objective'
      label={CONSTANTS.LABELS.PROJECT_OBJECTIVE}
      placeholder='Select'
      fullWidth
      required
      helperText=''
    >
      {objectives.map((objective: any) => {
        return (
          <MenuItem key={objective.id} value={objective.id}>
            {objective.name}
          </MenuItem>
        );
      })}
    </FormikSelect>
  );
}
