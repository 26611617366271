/**
 * @data: Stringify Data
 */
 const parser = (data: any) => {
  try {
    const parsedData = JSON.parse(data);
    return parsedData;
  } catch (e) {
    throw e;
  }
};

export default parser;